import React, {useContext} from 'react'
import { BasePopupContext } from '../BasePopup';
import TextField from '../FormikFields/TextField';
import { Axios } from '../../api';
import { success_alert, error_alert, stringNotEmpty, hidePopup, getToken, setToken } from '../../utils';
import * as yup from "yup";
import {Formik, Form} from "formik";
import {BiLoaderAlt} from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import {FaUser} from "react-icons/fa";
import { set_sidebar } from '../../redux/slices/uiSlice';
import { login } from '../../redux/slices/userSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import { alphabet_only_tl, network_tl, required_tl } from '../../translation';
import { ALPHABETS_REGEX } from '../../constants';

const UpdateAccountName = () => {
    const { setPopup } = useContext(BasePopupContext)
    const dispatch = useDispatch();
    const user = useSelector(state => state.user);

    const handlePopup = () => {
        //popup
        const popup = {
            hideDefaultClose: true,
            component: <AccountNameForm />,
            visible: true,
            heading: <FormattedMessage id='update_account_name' defaultMessage="Update Account Name" />,
            contained: true
        }
        setPopup(popup)
        //popup
    }
    return (
        <>
            <div onClick={() =>{
                dispatch(set_sidebar(false))
                handlePopup()
            }} className="cursor-pointer transition-all hover:bg-primary group hover:text-white text-primary border-t-[2px] border-b-[2px] border-gray-300 py-4 px-4 flex items-center ">
                <div className="w-5 mr-3">
                <FaUser className='text-base group-hover:text-white  text-primary'/>
                </div>
                <div>
                <p className="text-sm font-bold">
                    <FormattedMessage id='name' defaultMessage='Name' />
                </p>
                <p className="text-sm group-hover:text-white text-gray-600">
                    {user.first_name + " " + user.last_name}
                </p>
                </div>
            </div>
        </>
    )
}

export const AccountNameForm = () => {
    const { setPopup } = useContext(BasePopupContext);
    const user = useSelector(state => state.user);
    const intl=useIntl()
    
    const dispatch = useDispatch();

    const initialValues = {
        first_name: user.first_name,
        last_name: user.last_name,
    }

    yup.addMethod(yup.string, 'stringNotEmpty', stringNotEmpty)
    const validationSchema = yup.object({
        first_name: yup.string().stringNotEmpty(),
        last_name: yup.string().stringNotEmpty(),
        // first_name: yup.string().matches(ALPHABETS_REGEX, alphabet_only_tl(intl)).required(required_tl(intl)),
        // last_name: yup.string().matches(ALPHABETS_REGEX, alphabet_only_tl(intl)).required(required_tl(intl)),
    })


    const handleSubmit = async (values, formikBag) => {
        try {
            //construct url
            const result = await Axios.put(`api/user/update-profile`, values, { requestId: "put-account-name",});
            success_alert(result.data.description);


            // user instance
            const user_payload = {
                ...user,
                ...values,
            } 

            // token to save to local storage
            const tokenObj = {
                user,
                access_token: getToken()
            }
            //saved to local storage
            setToken(tokenObj);

            // set redux state
            dispatch(login(user_payload));


            hidePopup(setPopup);
        } catch (e) {
            if (e.response) {
                error_alert(JSON.stringify(e.response.data.description))
            } else {
                error_alert(network_tl(intl))
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {
                form => (
                    <Form>
                        <>
                            <div className="grid grid-cols-1 gap-4">
                                <TextField
                                    larger
                                    field="first_name"
                                    label_text={<><FormattedMessage id='first_name' defaultMessage="First Name"/>*</>}
                                    form={form}
                                />
                                <TextField
                                    larger
                                    field="last_name"
                                    label_text={<><FormattedMessage id='last_name' defaultMessage="Last Name"/>*</>}
                                    form={form}
                                />

                                <div className="flex items-center">
                                    <button type="submit" disabled={form.isSubmitting} className='w-full btn btn-primary'>
                                        {
                                            form.isSubmitting ?
                                                <BiLoaderAlt className="animate-spin mx-auto text-3xl" />
                                                : <FormattedMessage id='submit' defaultMessage="Submit"/>
                                        }
                                    </button>
                                </div>
                            </div>
                        </>
                    </Form>
                )
            }
        </Formik>
    )
}

export default UpdateAccountName