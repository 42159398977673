import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    add_mode: false,
    data: false,
}

const profileSlice = createSlice({
    initialState,
    name: "add_links",
    reducers: {
        set_add_links: (state, action) => {
            state.data = action.payload
            return state
        },
        set_add_mode: (state, action) => {
            state.add_mode = action.payload
            return state
        },
    }
})

export default profileSlice.reducer;

export const {
    set_add_links,
    set_add_mode
} = profileSlice.actions;
