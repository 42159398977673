import React, {useContext} from 'react'
import { useSelector } from 'react-redux';
import { BasePopupContext } from '../../../BasePopup';
import Form from "./Form";
import { FormattedMessage } from 'react-intl';

const Connect = () => {
    const show = useSelector(state => state.connect.show_connect);
    const branding_detail = useSelector(state => state.branding_details)
    const user = useSelector(state => state.user);
    const connect = useSelector(state => state.connect);

    const { setPopup } = useContext(BasePopupContext)

    const handlePopup = () => {
        //popup
        const popup = {
            hideDefaultClose: true,
            component: <Form />,
            visible: true,
            heading: <FormattedMessage id='connect' defaultMessage='Connect' />,
            contained: true,
            isbranded:true,
            specificPageBranding:true
        }
        setPopup(popup)
        //popup
    }

    return (
        <>
            {
                connect.show_connect===true?
                <button
                    onClick={handlePopup}
                    className="py-2  text-sm font-medium"
                    style={{
                        color: branding_detail&&branding_detail.primary_color
                    }}>
                    <FormattedMessage id='connect' defaultMessage='Connect' />
                </button>
                :(!connect.show_connect&& connect.id) && !user? //By connect.id i'm checking data aya he ya nahi show_connect sey check krna he k connected he ya nahi, !user mean  its only for guest.
                <button
                    className="py-2  text-sm font-medium">
                    <FormattedMessage id='connected' defaultMessage='Connected' />
                </button>
                :""
            }
        </>
    )
}
export default Connect;