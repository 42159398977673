import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { set_tags_filter } from '../../../../redux/slices/tagsSlice';
import { BiLoaderAlt } from 'react-icons/bi';
import { ALL_PROFILES } from '../../../../constants';
import { FaUser } from 'react-icons/fa';
import { BasePopupContext } from '../../../BasePopup';
import { hidePopup } from '../../../../utils';

const ProfileFilter = () => {
    const {setPopup} = useContext(BasePopupContext);

    const dispatch = useDispatch();
    const selected = useSelector(state => state.tags.profile);
    const profiles = useSelector(state => state.profile_listing.data)
    const active_profiles=profiles.filter((item)=>item.is_active)
    
    const options = [
        ALL_PROFILES,
        ...active_profiles.map(item => ({
            value: item.id,
            label: item.profile_name,
            ...item
        }))
    ]

    const handleSelection = (item) => {
        dispatch(set_tags_filter({profile: item}))
        hidePopup(setPopup)
    }

    return (
        <>
        <div className=''>
                {
                    options.map((item, index) => (
                        <div key={index} className="py-3 block transform transition-all select-none cursor-pointer bg-white border-b-[1px]">
                            <div className="flex items-center">
                                {/* // mugshot */}
                                <div className="flex items-center justify-center bg-gray-300 flex-shrink-0 border-2 border-gray-100 w-[50px] h-[50px] rounded-full bg-cover bg-center" style={{ backgroundImage: `url(${item.image_file ? item.image_file : ''})` }}>
                                    {
                                        !item.image_file &&
                                        <FaUser className='text-white text-2xl' />
                                    }
                                </div>
                                {/* mugshot */}

                                <div className="flex-grow">

                                    <div className="flex items-center">
                                        <div className="px-4 flex-grow">
                                            <div className='flex space-x-2 items-center'>
                                                <h2 className="text-primary font-semibold leading-snug">
                                                    {
                                                        !item.value ?
                                                        item.label
                                                        :item.profile_name
                                                    }
                                                </h2>
                                            </div>
                                            <div className='flex space-x-1'>
                                                <p className="text-xs text-primary">
                                                    {item.email}
                                                </p>

                                            </div>
                                        </div>
                                        <div className="space-x-3 flex items-center flex-shrink-0">
                                            <input  onClick={() => handleSelection(item)} value={index} name={"profile-radio"} type="radio" className='scale-[2]' defaultChecked={selected.id === item.id ? true : false} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    ))
                }
            </div>
        </>    
    )
}

export default ProfileFilter