import React, { useState } from 'react'
import * as yup from "yup";
import { Formik, Form } from "formik";
import { error_alert, hidePopup, stringNotEmpty, success_alert } from '../../../../utils';
import TextField from "../../../FormikFields/TextField";
import TextareaField from "../../../FormikFields/TextareaField";
import { BiLoaderAlt } from "react-icons/bi";
import { Axios } from '../../../../api';
import { BasePopupContext } from '../../../BasePopup';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { set_count } from '../../../../redux/slices/profileListingSlice';
import { alphabet_only_tl, network_tl, required_tl } from '../../../../translation';
import { FormattedMessage, useIntl } from 'react-intl';
import { set_profile_count } from '../../../../redux/slices/profileSlice';
import ToogleButton from '../../../ToogleButton';
import { ALPHABETS_REGEX } from '../../../../constants';

const FormContainer = () => {
    const { setPopup } = useContext(BasePopupContext);
    const dispatch = useDispatch()
    const intl = useIntl();

    const [ToogleOn, setToogleOn] = useState(false)

    const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
        bio: "",
    }
    yup.addMethod(yup.string, 'stringNotEmpty', stringNotEmpty)
    const validationSchema = yup.object({
        first_name: yup.string().stringNotEmpty(),
        last_name: yup.string().stringNotEmpty(),
        // first_name: yup.string().matches(ALPHABETS_REGEX, alphabet_only_tl(intl)).required(required_tl(intl)),
        // last_name: yup.string().matches(ALPHABETS_REGEX, alphabet_only_tl(intl)).required(required_tl(intl)),
        email: yup.string().email().required(required_tl(intl)),
        bio: yup.string(),
    })

    const handleSubmit = async (values, formikBag) => {
        let payload = {
            ...values,
            request_info: ToogleOn
        }
        try {
            //construct url
            const result = await Axios.post(`api/customer/profile`, payload, { requestId: "connect-post" });
            success_alert(result.data.description);
            dispatch(set_profile_count());
            hidePopup(setPopup);

        } catch (e) {
            if (e.response) {
                error_alert(JSON.stringify(e.response.data.description))
            } else {
                error_alert(network_tl(intl))
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {
                form => (
                    <Form>
                        <>

                            <div className="grid grid-cols-1 gap-4">
                                <TextField
                                    larger
                                    field="first_name"
                                    label_text={<><FormattedMessage id='first_name' defaultMessage="First Name" />*</>}
                                    form={form}
                                />
                                <TextField
                                    larger
                                    field="last_name"
                                    label_text={<><FormattedMessage id='last_name' defaultMessage="Last Name" />*</>}
                                    form={form}
                                />
                                <TextField
                                    larger
                                    field="email"
                                    label_text={<><FormattedMessage id='email' defaultMessage="Email" />*</>}
                                    form={form}
                                />
                                <TextareaField
                                    larger
                                    field="bio"
                                    label_text={<><FormattedMessage id='bio' defaultMessage="Bio" /></>}
                                    form={form}
                                />

                            </div>
                            <div className='flex justify-between items-center'>
                                <p className='py-2 text-xs'>
                                    Do you want to acquire data of users who wish to access your profile ?
                                </p>
                                <ToogleButton
                                    // tooltip="Zain here OKOKO lskdjfhs sdkjfdslk"
                                    id=""
                                    state={ToogleOn}
                                    setState={setToogleOn}
                                />
                            </div>
                            <div className="flex items-center mt-4">
                                <button type="submit" disabled={form.isSubmitting} className='btn btn-primary w-full'>
                                    {
                                        form.isSubmitting ?
                                            <BiLoaderAlt className="animate-spin" />
                                            : <FormattedMessage id='create_profile' defaultMessage='Create Profile' />
                                    }
                                </button>
                            </div>
                        </>
                    </Form>
                )
            }
        </Formik>
    )
}

export default FormContainer