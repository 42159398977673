import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from "react-router";

import { LogoLoading } from "../../components/Loading";

// const Login = React.lazy(() => import("../../pages/Login"));
// const Signup = React.lazy(() => import("../../pages/Signup"));
// const ForgotPass = React.lazy(() => import("../../pages/ResetPage"));
// const ActivateAccount = React.lazy(() => import("../../pages/ActivateAccount"));
const Visitor = React.lazy(() => import('../../pages/Visitor'));
const Login = React.lazy(() => import('../../pages/Login'));
const LogoutWeb = React.lazy(() => import('../../pages/LogoutWeb'));
const Register = React.lazy(() => import('../../pages/Register'));
const ForgotPassword = React.lazy(() => import('../../pages/ForgotPassword'));
const VisitorHistory = React.lazy(() => import('../../pages/VisitorHistory'));
const SetPasswordNewAccount = React.lazy(() => import('../../pages/SetPasswordNewAccount'));


// commenting privacy and terms because we will use these with social login
// const PrivacyPolicy = React.lazy(() => import('../../pages/PrivacyPolicy'));
// const TermsOfUse = React.lazy(() => import('../../pages/TermsOfUse'));


function AuthRoute() {

    return (
        <Suspense fallback={<LogoLoading />}>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/tag/:id' element={<Visitor code={"tag"} />} />
                <Route path='/qr/:id' element={<Visitor code={"qr"} />} />
                <Route path='/profile/:id' element={<Visitor code={"profile"} />} />
                <Route path='/profile-constant/:id' element={<Visitor code={"profile-constant"} />} />
                <Route path='/register' element={<Register />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/set-password/:token' element={<SetPasswordNewAccount />} />
                <Route path='/history' element={<VisitorHistory />} />
                <Route path='/logout/:type' element={<LogoutWeb />} />
                {/* <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/terms-of-use' element={<TermsOfUse />} /> */}
                <Route path='*' element={<Navigate to="/" />} />
            </Routes>
        </Suspense>
    )
}

export default AuthRoute
