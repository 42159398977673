import React, {createContext, useContext, useEffect} from 'react'
import {AnimatePresence, motion} from "framer-motion";
import {GrClose} from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import { DARK_THEME_COLOR, POPUP_MEDIUM, POPUP_SMALL, THEME_DARK } from '../../constants';
import { useSelector } from 'react-redux';
import { hexToRgba } from '../../utils';

//global context for popup
export const BasePopupContext = createContext(null);

// initial state for popup
const initialState = {
    component: "",
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: true,
    isbranded:"",
    specificPageBranding:""
}

const BasePopup = () => {

    //extracting state from context
    const {popupState, setPopup} = useContext(BasePopupContext);
    const {component, heading, size, visible, contained, hideDefaultClose, second_component,fullPage,custom_close,isbranded,specificPageBranding} = popupState;

    const hidePopup = () => {
        setPopup({visible: false, hideDefaultClose: false});
    }

    // this is a helper component which sets the base for a modal
    const variants = {
        initial: {
            // opacity: 0,
            // scale:0.6,
            y: "120%",
        },
        enter: {
            // opacity: 1,
            // scale:1,
            y: fullPage ? "5%" : "10%",
            transition: {
                duration: 0.5,
            }
            
        },
        exit: {
            // opacity: 0,
            y:"120%",
            transition: {
                duration: 0.5,
            }
            // scale:0.6,
            
        }
    }

    const sizeClass = size === POPUP_SMALL ? "w-full max-w-[520px]" : size === POPUP_MEDIUM ? "w-full lg:w-7/12 xl:w-6/12 2xl:w-5/12 max-w-[700px]" : "w-full max-w-[520px]";
    const customClosedComponent=custom_close?custom_close:false
    return (
            <AnimatePresence>
                    {visible &&
                        <PopupContent 
                            hideDefaultClose={hideDefaultClose}
                            fullPage={fullPage}
                            variants={variants}
                            heading={heading}
                            contained={contained}
                            hidePopup={hidePopup}
                            component={component}
                            sizeClass={sizeClass}
                            setPopup={setPopup}
                            custom_close={customClosedComponent}
                            isbranded={isbranded}
                            specificPageBranding={specificPageBranding}
                        />
                    }
            </AnimatePresence>
    )
}

const PopupContent = ({variants, heading, contained, fullPage, hideDefaultClose,custom_close, second_component, hidePopup, component, sizeClass, setPopup,isbranded,specificPageBranding}) => {
    let branding_detail = useSelector(state => state.branding_details)
    branding_detail = isbranded?branding_detail:false
    specificPageBranding=isbranded&&specificPageBranding?true:false

    

    useEffect(() => {
        return () => {
            // reset popup to initial state on unmount
            setPopup(initialState)
        }
    }, [])

    return(
        <>
            <div className={`${!branding_detail&&"bg-black bg-opacity-30"}  left-0 top-0 fixed h-screen w-full flex items-start justify-center z-[1000] overflow-y-hidden`}
            style={{
                backgroundColor:branding_detail&& hexToRgba(branding_detail.primary_color,0.3) ,
            }}
            >
                <motion.div
                initial="initial"
                animate="enter"
                exit="exit"
                variants={variants}
                className={`${sizeClass} bg-white shadow-lg rounded-[50px] border-1 border-gray-200 relative mx-auto ${fullPage ? "h-full": "h-[95vh]"} flex flex-col`}
                style={{
                    backgroundColor: specificPageBranding&&branding_detail&&branding_detail.mode === THEME_DARK ? DARK_THEME_COLOR : ""
                    }}>
                        {
                            fullPage ?
                            <>
                                {/* header */}
                                    <div className="flex-shrink-0 rounded-[50px] rounded-b-none pt-10 px-10 flex items-center justify-end bg-white">
                                        <div className={`text-black text-2xl cursor-pointer `} onClick={hidePopup}>
                                            <GrClose/>
                                        </div>
                                    </div>
                                {/* header */}
                            </>
                            :
                            <>
                                {/* header */}
                                
                                    <div className="flex-shrink-0 rounded-lg rounded-b-none p-5 flex items-center justify-between border-b-[1px] border-gray-200 bg-white"
                                    style={{
                                    backgroundColor: specificPageBranding&&branding_detail&&branding_detail.mode === THEME_DARK ? DARK_THEME_COLOR : ""
                                    }}>
                                        <p className="text-xl text-black font-bold "
                                        style={{color:specificPageBranding&&branding_detail&&branding_detail.primary_color}}>
                                            {heading}
                                        </p>
                                        {
                                        custom_close?
                                        <div onClick={hidePopup}>
                                            {custom_close}
                                        </div>
                                        :
                                        hideDefaultClose&&
                                        <div className={` text-2xl cursor-pointer `} onClick={hidePopup}>
                                            <AiOutlineClose
                                               style={{
                                                    color: specificPageBranding&&branding_detail&&branding_detail.primary_color,
                                                }}
                                            />
                                        </div>

                                        }
                                    </div>
                                
                                {/* header */}
                            </>
                        }
                    <div className="overflow-y-auto flex-grow">
                        
                        <div className={contained ? "px-5 pt-8 pb-16" : ""}>
                            {component}
                        </div>
                    </div>
                </motion.div>
            </div>
            {
                second_component &&
                <div className="bg-black bg-opacity-30 left-0 top-0 fixed h-screen w-full flex items-start justify-center z-[1000] overflow-y-hidden">
                    <motion.div
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    variants={variants}
                    className={`${sizeClass} bg-white shadow-lg rounded-[50px] border-1 border-gray-200 relative mx-auto h-[95vh] flex flex-col`}>
                            {/* header */}
                            <div className="flex-shrink-0 rounded-lg rounded-b-none p-5 flex items-center justify-between border-b-[1px] border-gray-200 bg-white">
                                <p className="text-xl text-black font-bold ">
                                    {heading}
                                </p>
                                <div className={`text-black text-2xl cursor-pointer `} onClick={hidePopup}>
                                    <GrClose/>
                                </div>
                            </div>
                            {/* header */}
                        <div className="overflow-y-auto flex-grow">
                            <div className={contained ? "px-5 pt-8 pb-16" : ""}>
                                {second_component}
                            </div>
                        </div>
                    </motion.div>
                </div>
            }
            

        </>
    )
}

export default BasePopup
