import React, { useContext } from 'react';
import Menu from "../LeftContainer/Menu"
import { BasePopupContext } from '../../BasePopup';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProfileFilter from './ProfilesFilter';
import PremiumIcon from '../LeftContainer/PremiumIcon';
const Header = () => {

    const {setPopup} = useContext(BasePopupContext);

    const handlePopup = () => {
        //popup
        const popup = {
            hideDefaultClose: true,
            component: <ProfileFilter/>,
            visible: true,
            heading: <FormattedMessage id='select_filters' defaultMessage='Select Filters' />,
            contained: true
        }
        setPopup(popup)
        //popup
    }

    const profile_listing = useSelector(state => state.profile_listing)

    return (
        <>
            <div className='flex items-center'>
                <Menu />
                <Link to="/">
                    <img src={"/images/logo-horizontal.svg"} alt="pro id logo" className='w-[140px] sm:w-[160px]' />
                </Link>
            </div>

            <div className="flex items-center space-x-2">
                {/* <PremiumIcon/> */}
                <button disabled={profile_listing.loading || profile_listing.error} onClick={handlePopup}>
                    <img src="/images/icons/filter.png" alt="" className='select-none transition-all w-6 h-6 cursor-pointer transform active:scale-[90%]' draggable={false} />
                </button>
            </div>
        </>
    )
}

export default Header