import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {BsArrowLeft} from "react-icons/bs";
import { set_add_mode } from '../../../redux/slices/addLinksSlice';
import { useLocation } from 'react-router-dom';

const HideAddLinks = () => {
  const dispatch = useDispatch();
  const add_mode = useSelector(state => state.add_links.add_mode);
  const handleHide = () => {
      dispatch(set_add_mode(false))
  }

  return (
      add_mode &&
        <button 
          type="button"
          onClick={handleHide}
          className="mr-3"
        >
          <BsArrowLeft className='text-3xl text-primary'/>
        </button>
  )
}

export default HideAddLinks