import { createSlice } from "@reduxjs/toolkit";
// import { getLanguage } from '../../utils';

// const initialState = getLanguage();
const initialState = "";


const languageSlice = createSlice({
    name:"language",
    initialState,
    reducers:{
        set_language:(state,action)=>{
            state = action.payload
            return state
        },
    }
})

export const {
set_language,

} = languageSlice.actions

export default languageSlice.reducer;