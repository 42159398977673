import React, { useContext } from 'react'
import { InnerBasePopupContext } from '../../../../../../components/InnerBasePopup'
import { FormattedMessage } from 'react-intl';
import Container from './Container';
import useSubscription from '../../../../../useSubscription';
import { hidePopup } from '../../../../../../utils';
import { BasePopupContext } from '../../../../../BasePopup';
import { useNavigate } from 'react-router-dom';

const ExportCsv = ({export_type, export_id}) => {
    const navigate = useNavigate()
    const { setPopup } = useContext(InnerBasePopupContext);
    const parentPopup = useContext(BasePopupContext)

    const [disallow, subscriptionPopup] = useSubscription();

    const handlePopup = () => {
        if(disallow){
            hidePopup(parentPopup.setPopup)
            navigate('/subscription');
            return
        }

        //popup
        const popup = {
            hideDefaultClose: true,
            component: <Container export_type={export_type} export_id={export_id} />,
            visible: true,
            heading: <div className='flex items-center space-x-3'>
                <img src="/images/export/csv-file.png" className='w-[30px]' />
                <p>
                <FormattedMessage id='export_as_csv' defaultMessage='Export as CSV' />
                </p>
            </div>,
            contained: true
        }
        setPopup(popup)
        //popup
    }
    return (
        <>
            <button onClick={() => handlePopup()} className='transition-transform hover:scale-[1.01] py-7 px-4 text-left w-full flex items-center gap-5 rounded-xl export-shadow'>
                <div className='flex-grow'>
                    <h2 className='text-xl text-primary font-bold'>
                        <FormattedMessage id="export_as_csv" defaultMessage={"Export as CSV"} />
                    </h2>
                    <p className='text-gray-400 text-xs font-medium'>
                        <FormattedMessage id="export_as_csv_des" defaultMessage={"Export your connections as a CSV file and import to any CRM!"} />
                    </p>
                </div>
                <div className='flex-shrink-0'>
                    <img src="/images/export/csv-file.png" className='w-[60px]' />
                </div>

            </button>
        </>
    )
}

export default ExportCsv