import { createSlice } from "@reduxjs/toolkit";
import { ALL_PROFILES, STAT_DATE_FILTERS } from "../../constants";

const initialState = {
    type: null
}

const crmRedirectSlice = createSlice({
    initialState,
    name: "crm_type",
    reducers: {
        set_crm_type: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        reset_crm_type: (state, action) => {
            return initialState
        }
    }
})

export default crmRedirectSlice.reducer;

export const {
    set_crm_type,
    reset_crm_type
} = crmRedirectSlice.actions;