import React, { useState } from 'react'
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {DateTime} from "luxon";
import { useDetectClickOutside } from 'react-detect-click-outside';
import { DATE_FORMAT_SERVER, DATE_FORMAT_DISPLAY } from '../../constants';
import { FormattedMessage } from 'react-intl';
import {BsCalendar3} from "react-icons/bs";

const DateField = ({ left, setState, state, minDate, maxDate, label_text,isDisabled }) => {

    const [show, setShow] = useState("");
    const [local, setLocal] = useState(state ? DateTime.fromISO(state).toJSDate() : null)

    // close calender if clicked outside calender
    const ref = useDetectClickOutside({ onTriggered: () => setShow(false) });
    
    const handleChange = (value) => {
        setLocal(value)
    }
    const handleDone = () => {
        // reset end date if we are setting start date
        setState(DateTime.fromJSDate(local).toFormat(DATE_FORMAT_SERVER))
        setShow(false);
    }
    const handleReset = () => {
        setState("")
        setShow(false);
    }

    return (
        <>
            <div className='flex items-end space-x-2'>
                <BsCalendar3 className='text-primary text-4xl'/>
                <div className="relative block w-full group" ref={ref}>
                    {show && 
                        <div className={`bg-white pb-4 absolute top-5 left-0 z-10 shadow-xl ${left ? "translate-x-[-50%]" : ""}`}>
                            <Calendar
                                color="#093953"
                                // rangeColors="#093953"
                                className=""
                                onChange={handleChange}
                                date={local}
                                minDate={minDate ? DateTime.fromISO(minDate).toJSDate() : undefined}
                                maxDate={maxDate ? DateTime.fromISO(maxDate).toJSDate() : undefined}
                            />
                            <div className="flex items-center  justify-center space-x-4">
                                <button disabled={!local} onClick={handleDone} className="btn-sm btn-primary">
                                    <FormattedMessage id='done' defaultMessage='Done' />
                                </button>
                                <button onClick={handleReset} className="btn-sm btn-primary-outline">
                                    <FormattedMessage id='reset' defaultMessage='Reset' />
                                </button>
                            </div>
                        </div>
                    }
                    <label
                        htmlFor={label_text}
                        className="text-xs block text-gray-400"
                    >
                        {label_text}
                    </label>
                    <input
                        readOnly
                        type="text"
                        name={label_text}
                        id={label_text}
                        disabled={isDisabled}
                        className={`!w-[85px] input-text-borderless disabled:bg-white disabled:cursor-not-allowed ${state && "input-text-active"}`}
                        onFocus={() => setShow(true)}
                        value={state ? DateTime.fromISO(state).toFormat(DATE_FORMAT_DISPLAY) : "-"}
                    />
                    
                </div>
            </div>
        </>
    )
}

export default DateField