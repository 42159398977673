import React, { useContext, useState } from 'react'
import { FaUser } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { set_count } from '../../../../redux/slices/profileListingSlice'
import { set_profile_count } from '../../../../redux/slices/profileSlice'
import { error_alert, hidePopup, success_alert } from '../../../../utils'
import { BasePopupContext } from '../../../../components/BasePopup'
import { BiLoaderAlt } from 'react-icons/bi'
import { FormattedMessage, useIntl } from 'react-intl'
import { Axios } from '../../../../api'
import axios from 'axios'
import { network_tl } from '../../../../translation'
import useSubscription from '../../../useSubscription'

const View = () => {
    const [loading, setLoading] = useState(false);
    const { setPopup } = useContext(BasePopupContext)
    const profile = useSelector(state => state.profile.data)
    const profilesOptions = useSelector(state => state.profile_listing.data)
    
    const not_default = profilesOptions.filter(item => !item.is_default)
    const default_profile = profilesOptions.find(item => item.is_default)
    const [disallow, subscriptionPopup] = useSubscription();

    const dispatch = useDispatch()
    const intl = useIntl();

    const handleSelection = async (data) => {
        try {
            setLoading(true)
            //construct url
            const result = await Axios.get("api/customer/profile-switch/" + data.id, { requestId: "get-profile-current", });
            success_alert(result.data.description);
            dispatch(set_count())
            dispatch(set_profile_count())
            setLoading(false);
            hidePopup(setPopup);
        } catch (e) {
            if (e.response) {
                error_alert(JSON.stringify(e.response.data.description))
            } else if (axios.isCancel) {

            } else {
                error_alert(network_tl(intl))
            }
            setLoading(false);
        }
    }

    return (
        <>
            <div className=''>
                <div className="py-3 block transform transition-all select-none cursor-pointer bg-white border-b-[1px]">
                    <div className="flex items-center">
                        {/* // mugshot */}
                        <div className="flex items-center justify-center bg-gray-300 flex-shrink-0 border-2 border-gray-200 w-[50px] h-[50px] rounded-full bg-cover bg-center" style={{ backgroundImage: `url(${default_profile.image_file ? default_profile.image_file : ''})` }}>
                            {
                                !default_profile.image_file &&
                                <FaUser className='text-white text-2xl' />
                            }
                        </div>
                        {/* mugshot */}

                        <div className="flex-grow">

                            <div className="flex items-center">
                                <div className="px-4 flex-grow">
                                    <div className='flex space-x-2 items-center'>
                                        <h2 className="text-primary font-semibold leading-snug">
                                            {default_profile.profile_name}
                                        </h2>
                                    </div>
                                    <div className='flex space-x-1'>
                                        <p className="text-xs text-primary">
                                            {default_profile.email}
                                        </p>

                                    </div>
                                </div>
                                <div className="space-x-3 flex items-center flex-shrink-0">
                                    <input disabled={loading} onClick={() => handleSelection(default_profile)} name={"profile-radio"} type="radio" className='scale-[2]' defaultChecked={profile.id === default_profile.id ? true : false} />
                                    {
                                        loading &&
                                        <BiLoaderAlt
                                            className='animate-spin text-3xl text-gray-500'
                                        />
                                    }
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                {/* below are listing */}
                
                <div className='relative'>
                {
                    not_default.map((item, index) => (
                        <div key={index} className={`${disallow ? "blur-sm" : ""} py-3 block transform transition-all select-none cursor-pointer bg-white border-b-[1px]`}>
                            <div className="flex items-center">
                                {/* // mugshot */}
                                <div className="flex items-center justify-center bg-gray-300 flex-shrink-0 border-2 border-gray-200 w-[50px] h-[50px] rounded-full bg-cover bg-center" style={{ backgroundImage: `url(${item.image_file ? item.image_file : ''})` }}>
                                    {
                                        !item.image_file &&
                                        <FaUser className='text-white text-2xl' />
                                    }
                                </div>
                                {/* mugshot */}

                                <div className="flex-grow">

                                    <div className="flex items-center">
                                        <div className="px-4 flex-grow">
                                            <div className='flex space-x-2 items-center'>
                                                <h2 className={`font-semibold leading-snug ${item.is_active ? "text-primary" : "text-gray-500"}`}>
                                                    {item.profile_name}
                                                </h2>
                                            </div>
                                            <div className='flex space-x-1'>
                                                <p className={`text-xs ${item.is_active ? "text-primary" : "text-gray-500"}`}>
                                                    {item.email}
                                                </p>

                                            </div>
                                                {
                                                    !item.is_active &&
                                                    <p className={`text-xs text-red-500`}>
                                                        <FormattedMessage id="disabled" defaultMessage={"Disabled"} />
                                                    </p>
                                                }
                                        </div>
                                        <div className="space-x-3 flex items-center flex-shrink-0">
                                            <input disabled={loading || !item.is_active} onClick={() => handleSelection(item)} value={index} name={"profile-radio"} type="radio" className='scale-[2]' defaultChecked={profile.id === item.id ? true : false} />
                                            {
                                                loading &&
                                                <BiLoaderAlt 
                                                    className='animate-spin text-3xl text-gray-500'
                                                />
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    ))
                }

                {/* <h2 className='absolute w-full top-2/4 translate-y-[-50%] text-2xl font-bold text-center'>
                    <span className='text-primary-gradient'>Unlock more with Pro-ID Pro Plan</span>
                    <span>🚀</span>
                </h2> */}

                </div>

            </div>
        </>
    )
}

export default View