import React, { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import ExportCsv from './ExportCsv'
import { HUBSPOT_CONNECT_URL } from '../../../../../constants'
import ExportHubspot from './ExportHubspot'
import { hidePopup } from '../../../../../utils'
import { BasePopupContext } from '../../../../BasePopup'
import ExportZapier from './ExportZappier'
import ExportSalesforce from './ExportSalesForce'
import ExportOutlook from './ExportOutlook'

const View = ({export_type, export_id}) => {
    const {setPopup} = useContext(BasePopupContext)
  return (
    <div className=''>
        
        <div className='space-y-5 mb-8'>
            
            {/* export options here */}
            <ExportCsv export_type={export_type} export_id={export_id}/>
            <ExportHubspot export_type={export_type} export_id={export_id}/>
            <ExportZapier export_type={export_type} export_id={export_id}/>
            <ExportSalesforce export_type={export_type} export_id={export_id}/>
            <ExportOutlook export_type={export_type} export_id={export_id}/>     
            {/* export options here */}

        </div>


        <button onClick={() => hidePopup(setPopup)} className='btn btn-primary w-full'>
            <FormattedMessage id="cancel" defaultMessage={"Cancel"}/>
        </button>
    </div>
  )
}

export default View