import React from 'react';
import Home from "./Home"
import Profiles from "./Profiles"
import Generic from "./Generic"
import { useLocation } from 'react-router-dom';
import Visitor from './Visitor';
import Stats from './Stats';
import Connections from './Connections';
import Tags from './Tags';
import { useSelector } from 'react-redux';
import TrialBanner from './TrialBanner';
import InfiniteLooper from '../InfiniteLooper';
import { DARK_THEME_COLOR, THEME_DARK, TRIAL_PLAN } from '../../constants';
import BrandingPreviewHeader from '../../pages/Branding/Customize/Preview/Header';

const Header = () => {
  const location = useLocation();
  const user = useSelector(state => state.user);
  const profile = useSelector(state => state.profile.data)
  const intialPreview = useSelector(state => state.initial_preview)
  const branding_detail = useSelector(state => state.branding_details)



  


  const checks = ()=>{
    if(location.pathname === '/branding' && intialPreview.mode && intialPreview.dark_theme){
      return DARK_THEME_COLOR
    }else if((location.pathname.includes("/profile/") || location.pathname.includes("/qr/") || location.pathname.includes("/tag/") || location.pathname.includes("/profile-constant/"))&&
      branding_detail&&branding_detail.mode === THEME_DARK){
        return DARK_THEME_COLOR
    }
  }



  return (
    <>
    <header className='h-[56px] z-50 fixed max-w-[520px] w-full left-2/4 transform -translate-x-2/4 border-b-[1px] border-[#dddddd] border-r-[1px] border-l-[1px] bg-white'>
        <nav className="mx-auto flex items-center justify-between  px-5 h-full"
        style={{
            backgroundColor: checks()
        }}
        >
          {
            location.pathname === "/" && user ?
              <Home />
            : location.pathname === "/profiles" ?
            <Profiles />
            : location.pathname.includes("/profile/") || location.pathname.includes("/qr/") || location.pathname.includes("/tag/") || location.pathname.includes("/profile-constant/") ?
              <Visitor/>
            : location.pathname === "/statistics" ?
              <Stats/>
              : location.pathname === "/connections" ?
              <Connections/>
              : location.pathname === "/tags" ?
              <Tags/>
              :
              location.pathname === '/branding' && intialPreview.mode?
                <BrandingPreviewHeader/>
              :
              <Generic/>
            }
        </nav>
    </header>
    
    {
      // profile?.subscription?.type === TRIAL_PLAN && !profile?.subscription?.on_hold &&
      // profile?.subscription?.on_trial&&
        // <TrialBanner/>
    }
    </>
  )
}

export default Header