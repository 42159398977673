import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    primary_color:"",
    brand_image:"",
    mode:"",
    name:""
};


const brandingDetails = createSlice({
    name:"branding-details",
    initialState,
    reducers:{
        set_branding_details:(state,action)=>{
            state = action.payload
            return state
        },
    }
})

export const {
    set_branding_details,
} = brandingDetails.actions

export default brandingDetails.reducer;