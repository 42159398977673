import React, { useContext } from 'react'
import { BASE_URL, HUBSPOT_CONNECT_URL } from '../../../../../../constants'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { InnerBasePopupContext } from '../../../../../InnerBasePopup'
import Container from "./Container";

const ExportOutlook = ({export_type, export_id}) => {
    const profile = useSelector(state => state.profile.data)
    const { setPopup } = useContext(InnerBasePopupContext);

    const handlePopup = () => {
        //popup
        const popup = {
            hideDefaultClose: true,
            component: <Container export_type={export_type} export_id={export_id}/>,
            visible: true,
            heading: <div className='flex items-center space-x-3'>
                <img src="/images/export/outlook.png" className='w-[50px]' />
                <p>
                    <FormattedMessage id='export_outlook' defaultMessage='Export to Outlook' />
                </p>
            </div>,
            contained: true
        }
        setPopup(popup)
        //popup
    }


    return (
        <>
            {
                profile.is_outlook_connected ?
                    <button onClick={handlePopup} className='transition-transform hover:scale-[1.01] py-7 px-4 text-left w-full flex items-center gap-5 rounded-xl export-shadow'>
                        <div className='flex-grow'>
                            <h2 className='text-xl text-primary font-bold'>
                                <FormattedMessage id="connect_outlook" defaultMessage={"Connect to Outlook"} />
                            </h2>
                            <p className='text-gray-400 text-xs font-medium'>
                                <FormattedMessage id="connect_outlook_des" defaultMessage={"Export your connections to Outlook!"} />
                            </p>
                        </div>
                        <div className='flex-shrink-0'>
                            <img src="/images/export/outlook.png" className='w-[80px]' />
                        </div>

                    </button>
                    :
                    <a href={`${BASE_URL}api/main/external-resource/redirection?is_outlook=true`} target="_blank" className='transition-transform hover:scale-[1.01] py-7 px-4 text-left w-full flex items-center gap-5 rounded-xl export-shadow'>
                        <div className='flex-grow'>
                            <h2 className='text-xl text-primary font-bold'>
                                <FormattedMessage id="connect_outlook" defaultMessage={"Connect to Outlook"} />
                            </h2>
                            <p className='text-gray-400 text-xs font-medium'>
                                <FormattedMessage id="export_outlook_des" defaultMessage={"Export your connections to Outlook contacts!"} />
                            </p>
                        </div>
                        <div className='flex-shrink-0'>
                            <img src="/images/export/outlook.png" className='w-[70px]' />
                        </div>

                    </a>
            }
        </>
    )
}

export default ExportOutlook