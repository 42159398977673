import React, {createContext, useContext, useEffect} from 'react'
import {AnimatePresence, motion} from "framer-motion";
import {GrClose} from "react-icons/gr";
import { POPUP_MEDIUM, POPUP_SMALL } from '../../constants';

//global context for popup
export const InnerBasePopupContext = createContext(null);

// initial state for popup
const initialState = {
    component: "",
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: true
}

const InnerBasePopup = () => {

    //extracting state from context
    const {popupState, setPopup} = useContext(InnerBasePopupContext);
    const {component, heading, size, visible, contained, hideDefaultClose, second_component} = popupState;

    const hidePopup = () => {
        setPopup({visible: false, hideDefaultClose: false});
    }

    // this is a helper component which sets the base for a modal
    const variants = {
        initial: {
            // opacity: 0,
            // scale:0.6,
            y: "120%",
        },
        enter: {
            // opacity: 1,
            // scale:1,
            y: "10%",
            transition: {
                duration: 0.5,
            }
            
        },
        exit: {
            // opacity: 0,
            y:"120%",
            transition: {
                duration: 0.5,
            }
            // scale:0.6,
            
        }
    }

    const sizeClass = size === POPUP_SMALL ? "w-full max-w-[520px]" : size === POPUP_MEDIUM ? "w-full lg:w-7/12 xl:w-6/12 2xl:w-5/12 max-w-[700px]" : "w-full max-w-[520px]";
    return (
            <AnimatePresence>
                    {visible &&
                        <PopupContent 
                            hideDefaultClose={hideDefaultClose}
                            variants={variants}
                            heading={heading}
                            contained={contained}
                            hidePopup={hidePopup}
                            component={component}
                            sizeClass={sizeClass}
                            setPopup={setPopup}
                        />
                    }
            </AnimatePresence>
    )
}

const PopupContent = ({variants, heading, contained, hideDefaultClose, second_component, hidePopup, component, sizeClass, setPopup}) => {

    useEffect(() => {
        return () => {
            // reset popup to initial state on unmount
            setPopup(initialState)
        }
    }, [])

    return(
        <>
            <div className="bg-black bg-opacity-30 left-0 bottom-0 fixed h-screen w-full flex items-end justify-center z-[1000] overflow-y-hidden">
                <motion.div
                initial="initial"
                animate="enter"
                exit="exit"
                variants={variants}
                className={`${sizeClass} bg-white shadow-lg rounded-[50px] border-1 border-gray-200 relative mx-auto h-[80vh] flex flex-col`}>
                        {/* header */}
                        <div className="flex-shrink-0 rounded-lg rounded-b-none p-5 flex items-center justify-between border-b-[1px] border-gray-200 bg-white">
                            <p className="text-xl text-black font-bold ">
                                {heading}
                            </p>
                            <div className={`text-black text-2xl cursor-pointer `} onClick={hidePopup}>
                                    <GrClose/>
                                </div>
                        </div>
                        {/* header */}
                    <div className="overflow-y-auto flex-grow">
                        
                        <div className={contained ? "px-5 pt-5 pb-16" : ""}>
                            {component}
                        </div>
                    </div>
                </motion.div>
            </div>
            {
                second_component &&
                <div className="bg-black bg-opacity-30 left-0 top-0 fixed h-screen w-full flex items-start justify-center z-[1000] overflow-y-hidden">
                    <motion.div
                    initial="initial"
                    animate="enter"
                    exit="exit"
                    variants={variants}
                    className={`${sizeClass} bg-white shadow-lg rounded-[50px] border-1 border-gray-200 relative mx-auto h-[95vh] flex flex-col`}>
                            {/* header */}
                            <div className="flex-shrink-0 rounded-lg rounded-b-none p-5 flex items-center justify-between border-b-[1px] border-gray-200 bg-white">
                                <p className="text-xl text-black font-bold ">
                                    {heading}
                                </p>
                                <div className={`text-black text-2xl cursor-pointer `} onClick={hidePopup}>
                                    <GrClose/>
                                </div>
                            </div>
                            {/* header */}
                        <div className="overflow-y-auto flex-grow">
                            <div className={contained ? "px-5 pt-8 pb-16" : ""}>
                                {second_component}
                            </div>
                        </div>
                    </motion.div>
                </div>
            }
            

        </>
    )
}

export default InnerBasePopup
