import React, { useContext } from 'react';
import Qr from "../RightContainer/Qr"
import Menu from "../LeftContainer/Menu"
import { Link } from 'react-router-dom';
import Connect from './Connect';
import ConnectForm from './ConnectForm';
import { THEME_DARK } from '../../../constants';
import { useSelector } from 'react-redux';


const VisitorHeader = () => {
    const branding_detail = useSelector(state => state.branding_details)
    
    return (
        <>
            <div className='flex items-center'>
                <Menu branding_detail={branding_detail} />
                {
                    !branding_detail&&
                    <Link to="/">
                        {
                            branding_detail&&branding_detail.mode === THEME_DARK? 
                            <img src="/images/logo-horizontal.png" alt="pro id logo" className='w-[120px] sm:w-[140px]' />
                            :
                            <img src="/images/logo-horizontal.svg" alt="pro id logo" className='w-[140px] sm:w-[160px]' />
                        }
                    </Link> 
                }
            </div>

            <div className="flex items-center space-x-2">
                <Connect/>
                <ConnectForm/>
            </div>
        </>
    )
}

export default VisitorHeader