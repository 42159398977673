import React, { useEffect, useState } from 'react'
import { HiPencil } from 'react-icons/hi';
import { FaUser } from 'react-icons/fa';
import { useSelector } from 'react-redux'
import Form from './Form';
import QrGenerator from '../QrGenerator';
import { error_alert, generateProfileQrLink } from '../../utils';
import Scan from "./Scan";
import { BASE_URL, DATE_FORMAT_DISPLAY } from '../../constants';
import Share from './Share';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateTime } from 'luxon';

const QrShareRename = () => {
    const profile = useSelector(state => state.profile.data);
    const [show, setShow] = useState(0);
    const [scannedLink, setScannedLink] = useState(false);
    const [showScan, setShowScan] = useState(false);
    const intl=useIntl();

    const last_update  = profile.qr_updated_on ? DateTime.fromISO(profile.qr_updated_on) : null
    const today = DateTime.local();
    const difference = last_update ? Math.floor(today.diff(last_update, 'days').days) : null;

    useEffect(() => {
        if(scannedLink){
            console.log("sccaned Link", scannedLink)
            if(scannedLink.includes(window.location.href)){

            } else{
                error_alert(intl.formatMessage({id:"inavlid.link",defaultMessage:"Invalid link!"}))
                setScannedLink(false)
            }
        }
    }, [scannedLink])

    const handleSetScan = () => {
        if(scannedLink){
            window.open(scannedLink)
            setScannedLink(false)
        } else{
            setShowScan(true)
        }
    }


    return (
        <>
            {
                !showScan ?
                    <>
                        <div className="mb-3 mx-auto flex items-center justify-center bg-gray-300 flex-shrink-0 border-[4px] border-white btn-shadow w-[80px] h-[80px] rounded-full bg-cover bg-center" style={{ backgroundImage: `url(${profile.image_file ? profile.image_file : ''})` }}>
                            {
                                !profile.image_file &&
                                <FaUser className='text-white text-5xl' />
                            }
                        </div>
                        <div className='mb-8'>
                            {
                                show === 0 ?
                                    <div className='flex justify-center'>
                                        <p className="relative text-sm font-bold text-primary">
                                            {profile.qr_code}
                                            <div onClick={() => setShow(1)} className="cursor-pointer z-[1] transform translate-x-[120%] translate-y-[-25%] h-7 w-7 btn-shadow rounded-full flex items-center justify-center shadow-xl bg-white absolute top-0 right-0">
                                                <HiPencil className='text-xl' />
                                            </div>
                                        </p>
                                    </div>
                                    : show === 1 ?
                                        
                                        (difference && difference > 40) || !last_update ?
                                            <>
                                                <p className="text-sm mb-3 text-center font-semibold">
                                                    <FormattedMessage id='your_profile_link' defaultMessage='Your profile link will be updated based on your username. Please note that your previous link will be expired.' />
                                                </p>
                                                <p className="text-sm mb-3 text-center font-semibold">
                                                    NOTE: You can only change your username once in 40 days. Proceed?
                                                </p>
                                                {/* <p className="text-sm mb-3 text-center font-semibold">
                                                    <FormattedMessage id='do_you_still_want' defaultMessage='Do you still want to update profile name?' />
                                                </p> */}
                                                <div className="flex items-center justify-center space-x-4">
                                                    <button type="button" onClick={() => setShow(0)} className={`btn btn-primary-outline`}>
                                                        <FormattedMessage id='cancel' defaultMessage='Cancel' />
                                                    </button>
                                                    <button type="button" onClick={() => setShow(2)} className={`btn btn-primary`}>
                                                        <FormattedMessage id='yes' defaultMessage='Yes' />
                                                    </button>
                                                </div>
                                            </>
                                        :
                                            <>
                                                <p className="text-sm mb-3 text-center font-semibold">
                                                    You recently changed your qr name on {last_update && last_update.toFormat(DATE_FORMAT_DISPLAY)}.
                                                </p>
                                                <p className="text-sm mb-3 text-center font-semibold">
                                                    Please note that you can only change your username once in 40 days. You'll be able to change your user name after {40-difference} days.
                                                </p>
                                                <div className="flex items-center justify-center space-x-4">
                                                    <button type="button" onClick={() => setShow(0)} className={`btn btn-primary-outline`}>
                                                        <FormattedMessage id='cancel' defaultMessage='Cancel' />
                                                    </button>
                                                </div>
                                            </>
                                            
                                        : show === 2 ?
                                            <Form data={profile} setShow={setShow} />
                                            : null
                            }
                        </div>

                        {/* <img src={profile.qr_image} alt="" className="w-2/4 mx-auto" /> */}
                        <QrGenerator value={generateProfileQrLink(profile.qr_code)} />
                        <div className="flex items-center mt-5 space-x-3">
                            <button type="button" onClick={handleSetScan} className='relative w-2/4 btn-sm btn-primary'>
                                {
                                    !scannedLink ?
                                        <FormattedMessage id='scan.qr' defaultMessage='Scan QR' />
                                    :
                                        <>
                                        <FormattedMessage id='open.second' defaultMessage='Open Scanned Link'/>
                                        <span className="absolute top-0 right-0 flex h-3 w-3 transform translate-y-[-50%] translate-x-[50%]">
                                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
                                            <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                                        </span>
                                        </>
                                }
                            </button>
                            <Share value={generateProfileQrLink(profile.qr_code)}/>
                        </div>
                    </>
                    :
                    <Scan setShowScan={setShowScan} scannedLink={scannedLink} setScannedLink={setScannedLink} />
            }

        </>
    )
}

export default QrShareRename