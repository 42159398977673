import React, { useEffect, useState } from 'react'
import {Html5Qrcode} from "html5-qrcode"
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const QR = ({setShowScan, setScannedLink}) => {

    const QrId = 'qr-code-region';
    const [permissionGranted, setPermissionGranted] = useState(false);
    const [permissionError, setPermissionError] = useState(false);

    useEffect(() => {
        const qrScanner = new Html5Qrcode(QrId)
        console.log("before: ", qrScanner.getState())
        Html5Qrcode.getCameras().then(devices => {
            setPermissionGranted(true)
            /**
             * devices would be an array of objects of type:
             * { id: "id", label: "label" }
             */
            if (devices && devices.length) {
                console.log(devices)
              var cameraId = devices.length > 1 ? devices[1].id : devices[0].id;
              qrScanner.start(
                cameraId, 
                {
                  fps: 10,    // Optional, frame per seconds for qr code scanning
                  qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
                },
                (decodedText, decodedResult) => {
                  // do something when code is read
                  setScannedLink(decodedText)
                  setShowScan(false)
                  qrScanner.stop().then((ignore) => {
                    // QR Code scanning is stopped.
                    console.log("stop krdia")
                    qrScanner.clear()
                  }).catch((err) => {
                    // Stop failed, handle it.
                  });
                  
                },
                (errorMessage) => {
                  // parse error, ignore it.
                  console.log("during running: ", qrScanner.getState())
                  console.log("error aagya :(", errorMessage)
                })
              .catch((err) => {
                // Start failed, handle it.
                // console.log("error aagya :(")
                setPermissionError(false)
            });
            
              // .. use this to start scanning.
            }
            }).catch(err => {
            // handle err
            console.log("user didn't select device ;(")
            });
          

        return () => {
            if(qrScanner.getState() === 2){
              qrScanner.stop().then(() => qrScanner.clear()).catch(err => console.log(err))
            } else{
              qrScanner.clear()
            }
            // console.log(qrScanner.getState())
        }
    }, [])

  return (
    <>
    <div className='mx-4 rounded-md mt-5 mb-5'>
        {
            !permissionGranted && !permissionError ?
                <>
                    <img src="/images/nfc_gif.gif" alt="" className='w-8/12 mx-auto' />
                    <div className="text-center">
                        <h1 className="text-xl text-primary font-bold mb-2">
                            <FormattedMessage id='grant.camera' defaultMessage='Grant camera access' />
                        </h1>
                        <p className="text-xs mb-4">
                          <FormattedMessage id='please.provide.camera' defaultMessage='Please provide camera access to continue' />  
                        </p>
                        <p className="text-sm mb-4">
                            <button onClick={() => setShowScan(false)} className='text-primary underline font-bold'>
                              <FormattedMessage id='go_back' defaultMessage='Go back' />  
                            </button>
                        </p>
                    </div>
                </> 
            :!permissionGranted && permissionError ?
                <>
                    <div className="text-center">
                        <h1 className="text-xl text-primary font-bold mb-2">
                            <FormattedMessage id='camera_access_blocked' defaultMessage='Camera access was blocked.' />
                        </h1>
                        <p className="text-sm mb-4">
                            <button onClick={() => setShowScan(false)} className='font-bold text-primary underline font-bold'>
                              <FormattedMessage id='go_back' defaultMessage='Go back' />  
                            </button>
                        </p>
                    </div>
                </> 
            : <>
            <p className="text-sm mb-4">
                <button onClick={() => setShowScan(false)} className='font-bold text-primary underline font-bold'>
                <FormattedMessage id='go_back' defaultMessage='Go back' />  
                </button>
            </p>
            </>
        }
    </div>
    <div id={QrId}/>
    </>
  )
}

export default QR