import React, { useEffect, useState } from 'react'
import { AiFillCamera } from 'react-icons/ai'
import { BsPersonVcard } from 'react-icons/bs'
import { FormattedMessage } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import useSubscription from "../useSubscription";

const ScanCardIndication = () => {
    const navigate = useNavigate();

    const [ disallow, subscriptionPopup] = useSubscription()
    const handleNavigate = () => {
        navigate("/card-scan")
    }

    return (
        <div className={`fixed right-4 bottom-4 `} >
            <button onClick={() =>subscriptionPopup(handleNavigate)}>
                <div className='px-4 space-x-2 camer-button-shaking flex justify-center items-center cursor-pointer mx-auto rounded-full py-3 bg-primary'>
                    <BsPersonVcard className="text-xl text-white " />
                    <p className='text-white text-xs'><FormattedMessage id='card_scan' defaultMessage='Card Scan' /></p>
                </div>
            </button>
        </div>
    )
}

export default ScanCardIndication