import { createSlice } from "@reduxjs/toolkit";
import { getHistory } from "../../utils";

const initialState = getHistory();

const uiSlice = createSlice({
    initialState,
    name: "history",
    reducers: {
        set_complete_history: (state, action) => {
            state = action.payload
            return state
        },
        set_history: (state, action) => {
            state.push(action.payload)
            return state
        },
    }
})

export default uiSlice.reducer;

export const {
    set_history,
    set_complete_history
} = uiSlice.actions;
