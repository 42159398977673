import React from 'react'
import GenericLink from './GenericLink';
import ExternalLink from './ExternalLink';
import { AiFillQuestionCircle, AiOutlineLogin } from "react-icons/ai";
import { MdPrivacyTip, MdLanguage } from "react-icons/md";
import { BiSupport, BiLogIn } from "react-icons/bi";
import { IoLogIn } from "react-icons/io5";
import { GrLanguage } from "react-icons/gr";
import { FaUserAlt, FaHistory } from "react-icons/fa";
import { FaIdCard } from 'react-icons/fa'
import Dropdown from "./Dropdown";
import { FormattedMessage } from 'react-intl';
import { PRO_ID_ECOMMERCE_APP_URL, TERMS_OF_SERVICES_URL } from '../../constants';

const AuthLinksContainer = () => {

  return (
    <div className="my-8">
      <GenericLink
        to={"/history"}
        title={<FormattedMessage id='history' defaultMessage="History" />}
        icon={<FaHistory className='text-base font-bold' />}
      />
      <GenericLink
        to={"/"}
        title={<FormattedMessage id='login' defaultMessage="Login" />}
        icon={<AiOutlineLogin className='text-lg font-bold' />}
      />
      <GenericLink
        to={"/register"}
        title={<FormattedMessage id='register' defaultMessage="Register" />}
        icon={<FaUserAlt className='text-base font-bold' />}
      />

      <div className="font-bold text-sm text-primary py-3 px-2 bg-[#dddddd] w-full">
        <FormattedMessage id='settings' defaultMessage="Settings" />
      </div>
      <ExternalLink
        to={`${PRO_ID_ECOMMERCE_APP_URL}/terms-of-service`}
        title={<FormattedMessage id='terms_of_use' defaultMessage="Terms Of Use" />}
        icon={<AiFillQuestionCircle className='text-lg font-bold' />}
      />
      <ExternalLink
        to={`${PRO_ID_ECOMMERCE_APP_URL}/privacy-policy`}
        title={<FormattedMessage id='privacy_policy' defaultMessage="Privacy Policy" />}
        icon={<MdPrivacyTip className='text-lg font-bold' />}
      />
      <Dropdown
        title={<FormattedMessage id='faqs' defaultMessage="FAQs" />}
        icon={<BiSupport className='text-lg font-bold' />}
      />
      {/* <Dropdown
          title={<FormattedMessage id='language' defaultMessage="Language" />}
          icon={<MdLanguage className='text-lg font-bold'  />}
          language={true}
        /> */}
    </div>
  )
}

export default AuthLinksContainer