import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useDispatch, useSelector } from 'react-redux'
import { set_sidebar } from '../../redux/slices/uiSlice';

import UserInfo from './UserInfo';
import LinksContainer from './LinksContainer';
import AuthLinksContainer from './AuthLinksContainer';

const Sidebar = () => {
  const dispatch = useDispatch();
  const sidebar = useSelector(state => state.ui.sidebar);
  const user = useSelector(state => state.user);
  return (
    <AnimatePresence>
        {
          sidebar &&
            <motion.div
              key="bg-container"
              initial={{opacity:0}}
              animate={{opacity:1}}
              exit={{opacity:0}}
              onClick={() => dispatch(set_sidebar(false))}
              className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-40 z-[1000]"
            >
            </motion.div>
        }

        {
          sidebar &&
            <motion.div
            transition={{
              type: "tween"
            }}
            key="sidebar-container"
            initial={{x:-320}}
            animate={{x:0}}
            exit={{x:-320}}
            className="py-4 fixed top-0 bottom-0 w-[250px] bg-white shadow-xl z-[1001] overflow-y-auto">
                <UserInfo/>
                {
                  user ?
                    <LinksContainer/>
                  :
                    <AuthLinksContainer/>
                }
            </motion.div>
        }
    </AnimatePresence>

  )
}

export default Sidebar