import { createSlice } from "@reduxjs/toolkit";
const initialState = [

]

const reorderSlice = createSlice({
    initialState,
    name: "reorder",
    reducers: {
        set_reorder_data: (state, action) => {
            return action.payload;
        },
    }
})

export default reorderSlice.reducer;

export const {
    set_reorder_data
} = reorderSlice.actions;
