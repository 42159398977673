import { createSlice } from "@reduxjs/toolkit";
import { STAT_DATE_FILTERS } from "../../constants";

const initialState ={
    date: STAT_DATE_FILTERS[0],
    startDate: '',
    endDate: ''
}

const statSlice = createSlice({
    initialState,
    name:"stats",
    reducers: {
        set_stats_filter: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        reset_stats_filter: (state, action) => {
            return initialState
        }
    }
})

export default statSlice.reducer;

export const {
    set_stats_filter,
    reset_stats_filter
} = statSlice.actions;