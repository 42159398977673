import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    count: 0
};

const expireSubscriptoinSlice = createSlice({
    initialState,
    name: "expire_subscription",
    reducers: {
        set_expiry_count: (state, action) => {
            return {...state, count: state.count+1};
        },
    }
})

export default expireSubscriptoinSlice.reducer;

export const {
    set_expiry_count,
} = expireSubscriptoinSlice.actions;