import React, { useState } from 'react'

import Container from "./Container";

const QrShareRename = () => {
  return (
    <Container />
  )
}

export default QrShareRename