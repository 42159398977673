import React from 'react'

const Item = ({
    subtitle,
    title, 
    text,
    image,
    btn
}) => {
    return (    
        <div>
        <img src={image} className='mb-8 max-w-[260px] mx-auto'/>

            <p className='mb-2 text-slate-500 font-semibold text-xs'>  
                {subtitle}
            </p>
            <h2 className='mb-2 text-xl font-bold text-primary'>
                {title}
        </h2>
        <p className='mb-5 font-medium text-primary text-sm'>
        {
            text
        }
        </p>

        {btn}
        </div>
    )
}

export default Item