import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: true,
    error: false,
    data: false,
    count: 0, //used to recall api
}

const profileListingSlice = createSlice({
    initialState,
    name: "profile_listing",
    reducers: {
        set_error: (state, action) => {
            state.error = action.payload
            return state
        },
        set_loading: (state, action) => {
            state.loading = action.payload
            return state
        },
        set_data: (state, action) => {
            state.data = action.payload
            return state
        },
        set_count: (state, action) => {
            state.count += 1;
            return state
        },
        
    }
})

export default profileListingSlice.reducer;

export const {
    set_loading,
    set_error,
    set_data,
    set_count,
} = profileListingSlice.actions;
