import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { set_sidebar } from '../../redux/slices/uiSlice';

const LinkContainer = ({to, icon,iconWhite, title, pulse,tour_class}) => {
    const dispatch = useDispatch()
    const [hover,setHover]=useState(false)

    return (
    <>
    <Link 
    onMouseEnter={()=>setHover(true)} 
    onMouseLeave={()=>setHover(false)} 
    onClick={() => dispatch(set_sidebar(false))} to={to} className={`cursor-pointer transition-all hover:bg-primary hover:text-white text-primary odd:border-b-[2px]  border-gray-300 py-4 px-4 flex items-center ${tour_class?tour_class:""}`}>
        <div className="w-5 mr-3 relative">
            {
                hover&&iconWhite?
                iconWhite
                :
                icon
            }
            
        </div>
        <p className="text-sm font-bold">
            {title}
        </p>
        {
                pulse ?
                <span className="ml-2 flex h-3 w-3 ">
                    <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-500 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                </span>
                : null

            }
    </Link>
    </>
  )
}

export default LinkContainer