import React, { useContext } from 'react'
import { BASE_URL, HUBSPOT_CONNECT_URL } from '../../../../../../constants'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { InnerBasePopupContext } from '../../../../../../components/InnerBasePopup'
import Container from "./Container";
import useSubscription from '../../../../../useSubscription'
import { useNavigate } from 'react-router-dom'
import { hidePopup } from '../../../../../../utils'
import { BasePopupContext } from '../../../../../BasePopup'


const ExportHubspot = ({export_type, export_id}) => {
    const navigate = useNavigate()
    const profile = useSelector(state => state.profile.data)
    const { setPopup } = useContext(InnerBasePopupContext);
    const parentPopup = useContext(BasePopupContext)
    const [disallow, subscriptionPopup] = useSubscription();

    const handlePopup = () => {
        // window.open(`${BASE_URL}api/main/external-resource/redirection?is_hubspot=true`)
        //popup
        if(disallow){
            hidePopup(parentPopup.setPopup)
            navigate('/subscription');
            return
        }
        const popup = {
            hideDefaultClose: true,
            component: <Container export_type={export_type} export_id={export_id} />,
            visible: true,
            heading: <div className='flex items-center space-x-3'>
                <img src="/images/export/hubspot.png" className='w-[50px]' />
                <p>
                <FormattedMessage id='export_hubspot' defaultMessage='Export to Hubspot' />
                </p>
            </div>,
            contained: true
        }
        setPopup(popup)
        //popup
    }

    const handleRedirect = () => {
        if(disallow){
            hidePopup(parentPopup.setPopup)
            navigate('/subscription');
            return
        }else{
            window.open(`${BASE_URL}api/main/external-resource/redirection?is_hubspot=true`)
        }

    }

    return (
        <>
            {
                profile.is_hubspot_connected ?
                    <button onClick={() => handlePopup()} className='transition-transform hover:scale-[1.01] py-7 px-4 text-left w-full flex items-center gap-5 rounded-xl export-shadow'>
                        <div className='flex-grow'>
                            <h2 className='text-xl text-primary font-bold'>
                            <FormattedMessage id="export_hubspot" defaultMessage={"Export to Hubspot"}/>
                            </h2>
                            <p className='text-gray-400 text-xs font-medium'>
                                <FormattedMessage id="connect_hubspot_des" defaultMessage={"Export your connections to Hubspot!"}/>
                            </p>
                        </div>
                        <div className='flex-shrink-0'>
                            <img src="/images/export/hubspot.png" className='w-[90px]'/>
                        </div>
                    </button>
                :
                    <div onClick={() => handleRedirect()} className='transition-transform hover:scale-[1.01] py-7 px-4 text-left w-full flex items-center gap-5 rounded-xl export-shadow'>
                        <div className='flex-grow'>
                            <h2 className='text-xl text-primary font-bold'>
                            <FormattedMessage id="connect_hubspot" defaultMessage={"Connect to Hubspot"}/>
                            </h2>
                            <p className='text-gray-400 text-xs font-medium'>
                                <FormattedMessage id="connect_hubspot_des" defaultMessage={"Export your connections to Hubspot!"}/>
                            </p>
                        </div>
                        <div className='flex-shrink-0'>
                            <img src="/images/export/hubspot.png" className='w-[90px]'/>
                        </div>

                    </div>
            }
        </>
    )
}

export default ExportHubspot