import React, { useContext } from 'react';
import Qr from "../RightContainer/Qr"
import Menu from "../LeftContainer/Menu"
import NewProfile from './NewProfile';
import { Link } from 'react-router-dom';
import PremiumIcon from '../LeftContainer/PremiumIcon';
import { useSelector } from 'react-redux';

const ProfilesHeader = () => {

    return (
        <>
            <div className='flex items-center'>
                <Menu />
                <Link to="/">
                  <img src={"/images/logo-horizontal.svg"} alt="pro id logo" className='w-[140px] sm:w-[160px]' />
                </Link>
            </div>

            <div className="flex items-center space-x-2">
                {/* <PremiumIcon/> */}
                <NewProfile />
                <Qr />
            </div>
        </>
    )
}

export default ProfilesHeader