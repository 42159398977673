import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    show_connect: false,
    qr: "",
    nfc: "",
    name: "",
    connected: false,
    id: null
};

const connectSlice = createSlice({
    initialState,
    name: "connect",
    reducers: {
        set_connect_data: (state, action) => {
            return {...state, ...action.payload};
        },
        reset_connect_data: (state, action) => {
            return initialState
        }
    }
})

export default connectSlice.reducer;

export const {
    set_connect_data, reset_connect_data
} = connectSlice.actions;
