// all generic progammable translations are inside this file
// please make sure to append "_tl" to all the names of strings




// use in user registeration form
export const required_tl = (intl) => intl.formatMessage({id:"required", defaultMessage:"Required"})
export const network_tl = (intl) => intl.formatMessage({id:"network_error", defaultMessage:"A Network Error Occured! Please try again"})
export const password_short_tl = (intl) => intl.formatMessage({id:"password_short", defaultMessage:"Password too short, must be at least 8 characters long."})
export const password_smallcase_tl = (intl) => intl.formatMessage({id:"password_smallcase", defaultMessage:"Please include a smallcase letter in your password."})
export const password_special_tl = (intl) => intl.formatMessage({id:"password_special", defaultMessage:"Please include a special character in your password."})
export const password_number_tl = (intl) => intl.formatMessage({id:"password_number", defaultMessage:"Please include a number in your password."})
export const password_uppercase_tl = (intl) => intl.formatMessage({id:"password_uppercase", defaultMessage:"Please include an uppercase letter in your password."})
export const password_not_match_tl = (intl) => intl.formatMessage({id:"password_not_match", defaultMessage:"Passwords don't match."})
// use in user registeration form

export const only_integer = intl => intl.formatMessage({id:"only_number", defaultMessage:"Only Integers are allowed"})
export const valid_num_tl = (intl) => intl.formatMessage({id:"enter_valid_number", defaultMessage:"Please enter a valid phone number!."})
export const max_150_tl = (intl) => intl.formatMessage({id:"max_150", defaultMessage:"Maximum 150 characters!."})
export const valid_email_tl = (intl) => intl.formatMessage({id:"valid_email", defaultMessage:"Please enter a valid email"})
export const alphabet_only_tl = (intl) => intl.formatMessage({id:"alphabet.only", defaultMessage:"Use letters only, no spaces or special characters"})