import { configureStore } from "@reduxjs/toolkit";

//reducers below
import userReducer from "./slices/userSlice";
import connectReducer from "./slices/connectSlice";
import profileReducer from "./slices/profileSlice";
import addLinksReducer from "./slices/addLinksSlice";
import reorderReducer from "./slices/reorderSlice";
import languageReducer from "./slices/languageSlice";
import uiReducer from "./slices/uiSlice";
import profileListingSlice from "./slices/profileListingSlice";
import historyReducer from "./slices/historySlice";
import statsReducer from "./slices/statsSlice";
import connectionsReducer from "./slices/connectionsSlice";
import tagsRedcuer from "./slices/tagsSlice";
import crmRedirectRedcuer from "./slices/crmRedirectSlice";
import expireSubscriptionReducer from "./slices/expireSubscriptionSlice";
import onBoardTourSliceReducer from "./slices/onBoardTourSlice";
import brandingSliceReducer from "./slices/brandingSlice";
import previewSliceReducer from "./slices/initialpreviewBranding";
//reducers above

export default configureStore({
    reducer: {
        user: userReducer,
        connect: connectReducer,
        profile: profileReducer,
        add_links: addLinksReducer,
        reorder: reorderReducer,
        ui: uiReducer,
        language: languageReducer,
        profile_listing: profileListingSlice,
        history: historyReducer,
        stats: statsReducer,
        connections: connectionsReducer,
        tags: tagsRedcuer,
        expire_subscription: expireSubscriptionReducer,
        tour: onBoardTourSliceReducer,
        branding_details: brandingSliceReducer,
        initial_preview: previewSliceReducer,
        crm_type: crmRedirectRedcuer
    },
    devTools: process.env.NODE_ENV === "production" ? false : true
    // devTools: true
})
