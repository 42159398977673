import React, { Suspense, useEffect, useState } from 'react'

import {LogoLoading} from "../components/Loading";
import NormalRoutes from "./NormalRoutes"
import AuthRoutes from "./AuthRoutes"
import Header from '../components/Header';
import AppPromotion from '../components/AppPromotion';
import { useSelector } from 'react-redux';
import Sidebar from '../components/Sidebar';
import ScanCardIndication from '../components/ScanCardIndication';
import { useLocation } from 'react-router-dom';
//expire subscription
import SubscriptionExpire from "../components/SubscriptionExpire";
import { TRIAL_PLAN } from '../constants';
//expire subscription

const BaseRoutes = () => {
  const user = useSelector(state => state.user);
  const location = useLocation()
  const [showCardIcon,setShowCardIcon]=useState(false)
  const profile = useSelector(state => state.profile.data)

  useEffect(()=>{
    if(window.location.pathname != '/card-scan'){
        setShowCardIcon(true)
    }else{
      setShowCardIcon(false)
    }

  },[location])

  return (
      <>
      {
        // user &&
            <Sidebar/>
      }

      <div className="max-w-[520px] mx-auto h-full flex flex-col shadow-container">
        <div className={` flex-shrink-0 ${profile?.subscription?.type === TRIAL_PLAN && !profile?.subscription?.on_hold ? "mb-[76px]" : "mb-[56px]"}`}>
          <Header/>
        </div>
        {/* <AppPromotion/> */}
        <div className="flex-grow bg-[#fefefe] border-l-[1px] border-r-[1px] border-gray-200 pb-12">
          <Suspense fallback={<LogoLoading/>}>
            {
              user ?
              <>
              <NormalRoutes/>
              {showCardIcon&&<ScanCardIndication/>}
              </>
              :
              <AuthRoutes/>
            }
          </Suspense>
        </div>
        <div className="flex-shrink-0">
          {/* footer will go here */}
        </div>
      </div>

      {/* this component redirects if subscription error occurs */}
      <SubscriptionExpire/>
      </>
  )
}

export default BaseRoutes