import React, {useContext, useState} from 'react'
import { BiLoaderAlt } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import { Axios } from '../../../../api';
import { set_connect_data } from '../../../../redux/slices/connectSlice';
import { error_alert, success_alert } from '../../../../utils';
import { BasePopupContext } from '../../../BasePopup';
import { FormattedMessage, useIntl } from 'react-intl';
import { network_tl } from '../../../../translation';

const Connect = () => {
    const connect = useSelector(state => state.connect);
    const profile = useSelector(state => state.profile.data);
    const user = useSelector(state => state.user);
    const branding_detail = useSelector(state => state.branding_details)


    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const intl=useIntl()

    const handleConnect = async () => {
        try{
            setLoading(true);
            if(connect.connected){
                const result = await Axios.get(`api/customer/profile/connection/delete/${connect.id}`, {requestId: "make-connection"});
                success_alert(intl.formatMessage({id:"connection_removed", defaultMessage:"Connection removed successfully!" }))
                dispatch(set_connect_data({connected: false}))
            } else{
                const result = await Axios.get(`api/customer/profile/connect/${connect.id}`, {requestId: "make-connection"});
                success_alert(intl.formatMessage({id:"connection_established", defaultMessage:"Connection established successfully!" }))
                dispatch(set_connect_data({connected: true}))
            }

            setLoading(false)
        } catch(e){
            if(e.response){
                error_alert(JSON.stringify(e.response.data.description))
            } else{
                error_alert(network_tl(intl));
            }
            setLoading(false);
        }
    }

    return (
        <p style={{
            color: branding_detail&&branding_detail.primary_color
        }}>
            {
                connect.qr !== profile.qr_code && connect.show_connect_formless && user.id !== connect.user_id ?
                <button
                    onClick={handleConnect}
                    disabled={loading}
                    className="py-2  text-sm font-medium">
                    {
                        loading ? 
                        <BiLoaderAlt className='text-3xl animate-spin'/>
                        :
                        connect.connected ? <FormattedMessage id='disconnect' defaultMessage="Disconnect" /> : <FormattedMessage id='connect' defaultMessage='Connect' />
                    }
                </button>
                : (connect.qr === profile.qr_code && connect.show_connect_formless)  ?
                <p
                    className="py-2  text-sm font-medium">
                        <FormattedMessage id='your_profile' defaultMessage='Your Profile' />
                </p>
                : user && user.id === connect.user_id ?
                <p
                    className="py-2  text-sm font-medium">
                        <FormattedMessage id='your_profile' defaultMessage='Your Profile' />
                </p>
                :null
            }
        </p>
    )
}
export default Connect;