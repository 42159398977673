import { createSlice } from "@reduxjs/toolkit";

const initialState = false;


const onBoardTourSlice = createSlice({
    name:"tour",
    initialState,
    reducers:{
        set_onboard_tour:(state,action)=>{
            state = action.payload
            return state
        },
    }
})

export const {
set_onboard_tour,

} = onBoardTourSlice.actions

export default onBoardTourSlice.reducer;