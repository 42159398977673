import React, { useContext } from 'react';
import Qr from "../RightContainer/Qr"
import Menu from "../LeftContainer/Menu"
import ProfileChange from '../ProfileChange';
import { BasePopupContext } from '../../BasePopup';
import { FormattedMessage } from 'react-intl';
import DateFilter from "./DateFilter";

const Header = () => {
    const {setPopup} = useContext(BasePopupContext);

    const handlePopup = () => {
        //popup
        const popup = {
            hideDefaultClose: true,
            component: <DateFilter />,
            visible: true,
            heading: <FormattedMessage id='select_filters' defaultMessage='Select Filters' />,
            contained: true
        }
        setPopup(popup)
        //popup
    }

    return (
        <>
            <div className='flex items-center'>
                <Menu />
                <ProfileChange />
            </div>

            <div className="flex items-center space-x-2">
                <button onClick={handlePopup}>
                    <img src="/images/icons/filter.png" alt="" className='select-none transition-all w-6 h-6 cursor-pointer transform active:scale-[90%]' draggable={false} />
                </button>
                <Qr />
            </div>
        </>
    )
}

export default Header