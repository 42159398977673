import React, { useState } from 'react'
import Password from "./Password";
import Proceed from "./Proceed";
import Otp from "./Otp";

const DeleteAccount = () => {
  const [screen, setScreen] = useState(0);

  return (
    screen === 0 ?
      <Proceed setScreen={setScreen}/>
    : screen === 1 ?
      <Password setScreen={setScreen}/>
    : screen === 2 ?
      <Otp setScreen={setScreen}/>
    :
      <Password setScreen={setScreen}/>

  )
}

export default DeleteAccount