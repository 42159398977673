import React, { useState } from "react";
//redux imports
//redux imports

// notifications alerts
import 'react-notifications-component/dist/theme.css'
import ReactNotification from 'react-notifications-component'
import 'animate.css/animate.min.css';

// notifications alerts

//multilingual imports
import { IntlProvider } from "react-intl";
import en from "./languages/en.json";
//multilingual imports

//router
import { BrowserRouter } from "react-router-dom";
import BaseRoutes from "./routes";
//router
import {useSelector} from 'react-redux';

//global popup
import BasePopup, { BasePopupContext } from './components/BasePopup';
import InnerBasePopup, { InnerBasePopupContext } from './components/InnerBasePopup';
//global popup



const App = () => {
  const selectedLanguage = useSelector(state => state.language)

  // state for popup
  const initialPopupState = {
    component: null,
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false
  }
  const [popupState, setPopupState] = useState(initialPopupState);
  const [innerPopup, setInnerPopup] = useState(initialPopupState);

  const openPopup = (params) => setPopupState(old => ({ 
    ...old,
    fullPage: params.fullpage? true : false,
    ...params
   }));
  const openInnerPopup = (params) => setInnerPopup(old => ({ ...old, ...params }));

  const global_popup_context_data = {
    popupState,
    setPopup: openPopup
  }
  const global_inner_popup_context_data = {
    popupState: innerPopup,
    setPopup: openInnerPopup
  }
  // state for popup

  return (
    <> 
      <IntlProvider messages={en} locale={selectedLanguage.locale}  defaultLocale="en">
          <BasePopupContext.Provider value={global_popup_context_data}>
            <InnerBasePopupContext.Provider value={global_inner_popup_context_data}>
              <BrowserRouter>
                <ReactNotification/>
                <BaseRoutes />
                <BasePopup />
                <InnerBasePopup />
              </BrowserRouter>
            </InnerBasePopupContext.Provider>
          </BasePopupContext.Provider>
      </IntlProvider>
    </>
  );
}

export default App;
