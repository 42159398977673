import React, { useState } from 'react'
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import { error_alert, stringNotEmpty, success_alert } from '../../utils';
import TextField from "../../components/FormikFields/TextField";
import { BiLoaderAlt } from "react-icons/bi";
import { Axios } from "../../api";
import { useDispatch } from 'react-redux';
import { ErrorMessage } from 'formik';
import { set_profile_count, update_partial_profile } from '../../redux/slices/profileSlice';
import { network_tl } from '../../translation';
import { FormattedMessage, useIntl } from 'react-intl';


const FormContainer = ({ data, setShow }) => {
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch()
    const intl = useIntl()

    const initialValues = {
        qr_code: data.qr_code,
    }

    yup.addMethod(yup.string, 'stringNotEmpty', stringNotEmpty)
    const validationSchema = yup.object({
        qr_code: yup.string().stringNotEmpty(),
    })


    const handlePost = async (values, formikBag) => {
        try {
            //construct url
            const result = await Axios.put(`api/customer/profile`, values, {
                requestId: "qr-name-update",
            });
            dispatch(update_partial_profile({
                qr_code: result.data.payload.qr_code,
                qr_image: result.data.payload.qr_image,
            }))
            dispatch(set_profile_count())
            success_alert(result.data.description);
            setShow(0)
        } catch (e) {
            if (e.response) {
                error_alert(JSON.stringify(e.response.data.description))
            } else {
                error_alert(network_tl(intl))
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handlePost}
        >
            {
                form => (
                    <Form className='space-y-3'>
                        <>
                            <div>
                                <label htmlFor="fname" className={`text-xs}`}><FormattedMessage id='update_profile_qr' defaultMessage='Update Profile Qr' /></label>
                                <Field
                                    placeholder={intl.formatMessage({id:"profile_qr" , defaultMessage:"Profile QR" })}
                                    as={"input"}
                                    type={"text"}
                                    name={"qr_code"}
                                    value={form.values.qr_code}
                                    onChange={(e) => {
                                        let value = e.target.value
                                        if (!/[^a-zA-Z0-9-_]/.test(value)) {
                                            form.setFieldValue("qr_code", value.toLowerCase())
                                        }
                                    }}
                                    className={`input-text ${form.touched["qr_code"] && form.errors["qr_code"] ? "input-text-error" : ""} ${form.values["qr_code"] && form.values["qr_code"].toString().length > 0 && "input-text-active"}`}
                                />
                                <p className="text-xs text-red-500">
                                    <ErrorMessage name={"qr_code"} />
                                </p>
                            </div>
                            <div className="flex items-center justify-center space-x-4">

                                <button onClick={() => setShow(0)} type="button" disabled={loading || form.isSubmitting} className={`btn btn-primary-outline`}>
                                    {
                                        loading || form.isSubmitting ?
                                            <BiLoaderAlt className="animate-spin mx-auto text-3xl" />
                                            : <FormattedMessage id='cancel' defaultMessage='Cancel' />
                                    }
                                </button>
                                <button type="submit" disabled={loading || form.isSubmitting} className={`btn btn-primary`}>
                                    {
                                        loading || form.isSubmitting ?
                                            <BiLoaderAlt className="animate-spin mx-auto text-3xl" />
                                            : <FormattedMessage id='update' defaultMessage='Update' />
                                    }
                                </button>
                            </div>
                        </>
                    </Form>
                )
            }
        </Formik>
    )
}

export default FormContainer