import React, { useContext } from 'react'
import { BASE_URL, HUBSPOT_CONNECT_URL } from '../../../../../../constants'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { InnerBasePopupContext } from '../../../../../../components/InnerBasePopup'
import Container from "./Container";

const ExportZapier = () => {
  const profile = useSelector(state => state.profile.data)
  const { setPopup } = useContext(InnerBasePopupContext);
  
  const handlePopup = () => {
      //popup
      const popup = {
          hideDefaultClose: true,
          component: <Container />,
          visible: true,
          heading: <div className='flex items-center space-x-3'>
              <img src="/images/export/hubspot.png" className='w-[50px]' />
              <p>
              <FormattedMessage id='export_zappier' defaultMessage='Export to Zappier' />
              </p>
          </div>,
          contained: true
      }
      setPopup(popup)
      //popup
  }


  return (
    <>
    {
        profile.is_zapier ?
        <button className='transition-transform hover:scale-[1.01] py-7 px-4 text-left w-full flex items-center gap-5 rounded-xl export-shadow'>
        <div className='flex-grow'>
            <h2 className='text-xl text-primary font-bold'>
                <FormattedMessage id="connect_zapier" defaultMessage={"Connect to Zapier"}/>
            </h2>
            <p className='text-gray-400 text-xs font-medium'>
                <FormattedMessage id="connect_zapier_des" defaultMessage={"Connect Pro-ID to Zapier to automate any task!"}/>
            </p>
        </div>
        <div className='flex-shrink-0'>
            <img src="/images/export/zapier.svg" className='w-[80px]'/>
        </div>

        </button>
        :
            <a href={`${BASE_URL}api/main/external-resource/redirection?is_zapier=true`} target="_blank" className='transition-transform hover:scale-[1.01] py-7 px-4 text-left w-full flex items-center gap-5 rounded-xl export-shadow'>
                <div className='flex-grow'>
                    <h2 className='text-xl text-primary font-bold'>
                    <FormattedMessage id="connect_zapier" defaultMessage={"Connect to Zapier"}/>
                    </h2>
                    <p className='text-gray-400 text-xs font-medium'>
                        <FormattedMessage id="connect_zapier_des" defaultMessage={"Connect Pro-ID to Zapier to automate any task!"}/>
                    </p>
                </div>
                <div className='flex-shrink-0'>
                    <img src="/images/export/zapier.svg" className='w-[90px]'/>
                </div>

            </a>
    }
</>
  )
}

export default ExportZapier