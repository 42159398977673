import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
// import Offline from './components/Offline';
import { Provider, useSelector } from "react-redux";
import store from "./redux/store";
import { TourProvider } from '@reactour/tour'
import { TOUR_STEPS, TOUR_STYLES } from './utils';
import {ContentComponent} from './components/TourPopup';
import { set_onboard_tour } from './redux/slices/onBoardTourSlice';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  // <Offline>
  <Provider store = {store}>
    <TourProvider 
    steps={TOUR_STEPS}
    styles={TOUR_STYLES}
    showCloseButton={true} 
    disableInteraction={true} 
    disableKeyboardNavigation= {true}
    onClickMask={()=>{
      return
    }}
    scrollSmooth 
    ContentComponent={ContentComponent}>
      <App />
    </TourProvider>
  </Provider>
  // </Offline>
  // </React.StrictMode>
);


