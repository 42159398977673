import { createSlice } from "@reduxjs/toolkit";
import { ALL_PROFILES, STAT_DATE_FILTERS } from "../../constants";

const initialState ={
    profile: ALL_PROFILES
}

const tagsSlice = createSlice({
    initialState,
    name:"tags",
    reducers: {
        set_tags_filter: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        reset_tags_filter: (state, action) => {
            return initialState
        }
    }
})

export default tagsSlice.reducer;

export const {
    set_tags_filter,
    reset_tags_filter
} = tagsSlice.actions;