import React,{useEffect, useLayoutEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { set_edit } from '../../../../redux/slices/profileSlice';
import { FormattedMessage } from 'react-intl';
import {FiEdit} from "react-icons/fi";
import { BsCheckCircle } from 'react-icons/bs';
import { useTour } from '@reactour/tour'
import { set_sidebar } from '../../../../redux/slices/uiSlice';
import { set_onboard_tour } from '../../../../redux/slices/onBoardTourSlice';
import { set_add_mode } from '../../../../redux/slices/addLinksSlice';


const Edit = () => {
  const dispatch = useDispatch();
  const add_mode = useSelector(state => state.add_links.add_mode);
  const { setIsOpen, currentStep,onClickClose} = useTour()
  
  const onBoardTour = useSelector(state => state.tour);
  const edit = useSelector(state => state.profile.edit);
  const loading = useSelector(state => state.profile.loading);
  const error = useSelector(state => state.profile.error);

  const handleEdit = () => {
    if (edit) {
      dispatch(set_edit(false))
    } else {
      dispatch(set_edit(true))
    }
  }

  useEffect(()=>{
    console.log("currentStep",currentStep)
    if(onBoardTour&&(!loading && !error)){
      setIsOpen(true)
      if(currentStep==1){
        dispatch(set_edit(true))
      }else if(currentStep ===3){
        // tour_four_step
        dispatch(set_add_mode(true))
        setIsOpen(false)
      }else if(currentStep === 4){
        dispatch(set_edit(true))  
        dispatch(set_add_mode(false))
      }
    }

  },[loading,currentStep])


  return (
      !loading && !error &&
      <button
        type="button"
        onClick={handleEdit}
        className=""
      >
        {
          edit ?
            add_mode?
              null
            :
              <img src="/images/icons/check.png" alt="" className={` ${onBoardTour&&currentStep ===5? "tour_last_step":""} select-none transition-all w-6 h-6 cursor-pointer transform active:scale-[90%]`} draggable={false} />
          :
            <img src="/images/icons/edit.png" alt="" className={` ${onBoardTour? "tour_first_step":""} select-none transition-all w-6 h-6 cursor-pointer transform active:scale-[90%]`} draggable={false} />
        }
      </button>
  )
}

export default Edit