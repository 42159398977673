import React, { useContext } from 'react'
import TextField from '../FormikFields/TextField';
import { Axios } from '../../api';
import { success_alert, error_alert, stringNotEmpty, hidePopup, getToken, setToken } from '../../utils';
import {network_tl} from '../../translation'
import * as yup from "yup";
import {Formik, Form} from "formik";
import {BiLoaderAlt} from 'react-icons/bi';
import { BasePopupContext } from '../BasePopup';
import { FormattedMessage, useIntl } from 'react-intl';

const Password = ({setScreen}) => {
  const { setPopup } = useContext(BasePopupContext);
  const intl= useIntl()

    const initialValues = {
        password: "",
    }

    yup.addMethod(yup.string, 'stringNotEmpty', stringNotEmpty)
    const validationSchema = yup.object({
        password: yup.string()
        .stringNotEmpty(),

    })


    const handleSubmit = async (values, formikBag) => {
        try {
            //construct url
            const result = await Axios.post(`api/user/two-factor-authentication`, values, { requestId: "put-account-name",});
            setScreen(2);
        } catch (e) {
            if (e.response) {
                error_alert(JSON.stringify(e.response.data.description))
            } else {
                error_alert(network_tl(intl))
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {
                form => (
                    <Form>
                        <>
                        <p className="text-sm mb-4">
                            <FormattedMessage id='please_enter_your_pass' defaultMessage={`Please enter your password and we'll send you an OTP to your email.`} />
                        </p>
                            <div className="grid grid-cols-1 gap-4">
                                <TextField
                                password
                                    larger
                                    field="password"
                                    llabel_text={<><FormattedMessage id='password' defaultMessage="Password"/>*</>}
                                    form={form}
                                />

                                <div className="flex items-center justify-center space-x-5">
                                <button onClick={() => hidePopup(setPopup)} type="button" className='btn btn-primary'><FormattedMessage id='cancel' defaultMessage="Cancel"/></button>
                                    <button type="submit" disabled={form.isSubmitting} className='btn btn-primary'>
                                        {
                                            form.isSubmitting ?
                                                <BiLoaderAlt className="animate-spin mx-auto text-3xl" />
                                                :
                                                <FormattedMessage id='submit' defaultMessage="Submit"/>
                                        }
                                    </button>
                                </div>
                            </div>
                        </>
                    </Form>
                )
            }
        </Formik>
    )
}

export default Password