import React, { useContext } from 'react';
import Qr from "../RightContainer/Qr"
import Menu from "../LeftContainer/Menu"
import { Link } from 'react-router-dom';
import ProfileChange from '../ProfileChange';
import Search from './Search';
import ExportContacts from './ExportContacts';
import { useDispatch, useSelector } from 'react-redux';
import { set_connections_show_filters, set_group_detail } from '../../../redux/slices/connectionsSlice';
import { BsArrowLeft } from 'react-icons/bs';
import { AiOutlinePlus } from 'react-icons/ai';
import { BasePopupContext } from '../../BasePopup';
import AddGroupMembers from "./AddGroupMembers"
import { FormattedMessage } from 'react-intl';
import { MEMBERS } from '../../../constants';
import { warning_alert } from '../../../utils';

const ConnectionsHeader = () => {
    const dispatch = useDispatch();
    const group = useSelector(state => state.connections.group_detail.data);
    const filterCount = useSelector(state => state.connections.connections.filterCount);
    const currentTab = useSelector(state => state.connections.currentTab);

    const {setPopup} = useContext(BasePopupContext);


    const handleFilter = () => {
        if(currentTab != MEMBERS){
            return 
        }
        dispatch(set_connections_show_filters(true))
    }
    
    const handleAddGroupMember = () => {
         //popup
            const popup = {
            hideDefaultClose: true,
            component: <AddGroupMembers />,
            visible: true,
            heading: <FormattedMessage id='add_members' defaultMessage='Add Members' />,
            contained: true
        }
        setPopup(popup)
        //popup
    }



    return (
        <>
            {
                group ? 
                <>
                <div className='flex items-center'>
                    <button 
                        type="button"
                        onClick={() => dispatch(set_group_detail(null))}
                        className="mr-3"
                    >
                    <BsArrowLeft className='text-3xl text-primary'/>
                    </button>
                </div>
                <p className='flex-grow text-primary font-semibold'>
                    {
                        group.group_name
                    }
                </p>

                <div className={`flex-shrink-0 flex items-center ${filterCount > 0 ?"space-x-3" : "space-x-2"}`}>
                    <button className="relative" onClick={handleAddGroupMember}>
                        <AiOutlinePlus className='text-primary text-2xl transition-all cursor-pointer transform active:scale-[90%]'/>
                    </button>
                </div>
                </>
                :
                <>
                <div className='flex items-center'>
                    <Menu />
                    <ProfileChange smaller />
                </div>
                <Search />
                <div className={`flex-shrink-0 flex items-center ${filterCount > 0 ?"space-x-3" : "space-x-2"}`}>
                    <button className="relative" onClick={handleFilter}>
                        {
                            filterCount > 0 &&
                            <span className='bg-primary text-white rounded-full h-4 w-4 text-xs absolute top-0 right-0 translate-x-[50%] -translate-y-1/2'>
                            {filterCount}
                            </span>
                        }
                        {/* <img src={`/images/icons/${currentTab != MEMBERS? "filter-disable":"filter"}.png`} alt="" className={`select-none transition-all w-6 h-6 ${currentTab != MEMBERS? " cursor-not-allowed ":"cursor-pointer" } transform active:scale-[90%]`} draggable={false} /> */}
                        <img src={`/images/icons/${currentTab != MEMBERS? "filter_2":"filter_1"}.png`} alt="" className={`select-none transition-all w-6 h-6 ${currentTab != MEMBERS? " cursor-not-allowed ":"cursor-pointer" } transform active:scale-[90%]`} draggable={false} />
                    </button>
                    <ExportContacts/>
                    {/* <Qr /> */}
                </div>
                </>
            }
        </>
    )
}

export default ConnectionsHeader