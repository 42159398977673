import React, { useState } from 'react'
import { FaCaretDown } from 'react-icons/fa';
import {motion, AnimatePresence} from "framer-motion";
import ExternalLink from "../ExternalLink";
import { BiSupport } from 'react-icons/bi';
import { AiFillAndroid, AiFillApple } from 'react-icons/ai';
import { IoCheckmarkDoneOutline } from 'react-icons/io5';
import { FormattedMessage } from 'react-intl';
import { LANGUAGE_ARRAY } from '../../../constants';

const LinkContainer = ({icon, title,language}) => {
    const [open, setOpen] = useState(false);

    return (
    <div>
    <a  onClick={() => setOpen(!open)} className="cursor-pointer transition-all hover:bg-primary hover:text-white text-primary odd:border-b-[2px]  border-gray-300 py-4 px-4 flex items-center ">
        <div className="w-5 mr-3 relative flex-shrink-0">
            {icon}
        </div>
        <div className="flex items-center justify-between flex-grow">
            <p className="text-sm font-bold">
                {title}
            </p>
            <FaCaretDown/>
        </div>
    </a>
    <AnimatePresence>
        {
            open &&
            <motion.div
                initial={{
                    height: 0
                }}
                animate={{
                    height: "auto"
                }}
                exit={{
                    height: 0
                }}
            className="w-full overflow-y-hidden">
                <div className="pl-2">
                    {
                        language?
                        <>
                            {
                            LANGUAGE_ARRAY.map((item)=>(
                                <ExternalLink
                                    title={item.title}
                                    icon={<img src={item.flag} className="w-6 mr-2" alt="flage missing" />}
                                    nested={true}
                                    item={item}
                                />
                            ))
                            }

                        </>
                        :
                        <>
                        <ExternalLink
                            to={"https://youtube.com/shorts/WCTyxiTJyzA"}
                            title={<FormattedMessage id='en_dis_nfc_iphone' defaultMessage="Enable/Disable NFC in iPhone" />}
                            icon={<AiFillApple className='text-lg font-bold'  />}
                            nested={true}
                        />
                        <ExternalLink
                            to={"https://youtube.com/shorts/Ar8uwBmdWr8?feature=share"}
                            title={<FormattedMessage id='en_dis_nfc_android' defaultMessage="Enable/Disable NFC in Android" />}
                            icon={<AiFillAndroid className='text-lg font-bold'  />}
                            nested={true}
                        />
                        <ExternalLink
                            to={"https://pro-id.co/phone-compatibility/"}
                            title={<FormattedMessage id='nfc_compatibility' defaultMessage="Check NFC Compatible Phones" />}
                            icon={<IoCheckmarkDoneOutline className='text-lg font-bold'  />}
                            nested={true}
                        />
                        <ExternalLink
                            to={"https://api.whatsapp.com/send/?phone=923048444330&text=Hi.+I+would+like+to+know+more+about+Pro-ID.&type=phone_number&app_absent=0"}
                            title={<FormattedMessage id='contact_us' defaultMessage="Contact Us" />}
                            icon={<BiSupport className='text-lg font-bold'  />}
                            nested={true}
                        />
                    </>
                    }
                </div>
            </motion.div>
            
        }
    </AnimatePresence>
    </div>
  )
}

export default LinkContainer