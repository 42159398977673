import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import _ from "lodash";
import { Axios } from '../../../../../../api';
import { error_alert, hidePopup, success_alert } from '../../../../../../utils';
import { network_tl } from '../../../../../../translation';
import { InnerBasePopupContext } from '../../../../../InnerBasePopup'
import { BasePopupContext } from '../../../../../BasePopup'
import { EXPORT_ALL_CONNECTIONS, EXPORT_SINGLE_CONNECTION, EXPORT_SINGLE_GROUP } from '../../../../../../constants';

const Container = ({export_id, export_type}) => {
  const options = [
    {
      value: "first_name",
      label: <FormattedMessage id="first_name" defaultMessage={"First Name"} />
    },
    {
      value: "last_name",
      label: <FormattedMessage id="last_name" defaultMessage={"Last Name"} />
    },
    {
      value: "email",
      label: <FormattedMessage id="email" defaultMessage={"Email"} />
    },
    {
      value: "phone",
      label: <FormattedMessage id="phone" defaultMessage={"Phone"} />
    },
    {
      value: "extra_phone",
      label: <FormattedMessage id="extra_phone" defaultMessage={"Extra Phone"} />
    },
    {
      value: "bio",
      label: <FormattedMessage id="bio" defaultMessage={"Bio"} />
    },
    {
      value: "image_file",
      label: <FormattedMessage id="image" defaultMessage={"Image"} />
    },
    {
      value: "business_card_image",
      label: <FormattedMessage id="business_card_image" defaultMessage={"Bio"} />
    },
    {
      value: "address",
      label: <FormattedMessage id="address" defaultMessage={"Address"} />
    },
    {
      value: "company",
      label: <FormattedMessage id="organization" defaultMessage={"Organization"} />
    },
    {
      value: "job_title",
      label: <FormattedMessage id="organization_pos" defaultMessage={"Organization Position"} />
    },
    {
      value: "linkedin",
      label: <FormattedMessage id="linkedin" defaultMessage={"Linkedin"} />
    },
    {
      value: "website",
      label: <FormattedMessage id="website" defaultMessage={"Website"} />
    },
    {
      value: "country",
      label: <FormattedMessage id="country" defaultMessage={"Country"} />
    },
    {
      value: "note",
      label: <FormattedMessage id="note" defaultMessage={"Note"} />
    },
  ]

  const [selected, setSelected] = useState(options);
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const innerPopup = useContext(InnerBasePopupContext)
  const { setPopup } = useContext(BasePopupContext)

  const removeItem = (item) => {
    setSelected(old => {
      const copy = _.cloneDeep(old);
      const index = copy.findIndex(dat => dat.value === item.value);
      copy.splice(index, 1);
      return copy
    })
  }

  const appendItem = (item) => {
    setSelected(old => {
      return [...old, item]
    })
  }

  const handleExport = async () => {
    setLoading(true);
    const all_connections = `api/customer/members?is_outlook_export=true&export_columns=${selected.map(item => item.value).toString()}`;
    const single_group = `api/customer/members?group_id=${export_id}&is_outlook_export=true&export_columns=${selected.map(item => item.value).toString()}`;
    const single_connection = `api/customer/members?member-id=${export_id}&is_outlook_export=true&export_columns=${selected.map(item => item.value).toString()}`;
    try {
      const result = await Axios.get(
        export_type === EXPORT_ALL_CONNECTIONS ? 
            all_connections
        : export_type === EXPORT_SINGLE_GROUP ?
            single_group
        : export_type === EXPORT_SINGLE_CONNECTION ?
            single_connection
        :
            all_connections
        ,
        { requestId: "export-outlook" }
      );
      success_alert(result.data.description)
      hidePopup(innerPopup.setPopup)
      hidePopup(setPopup)
      setLoading(false)
    } catch (e) {
      if (e.response) {
        error_alert(JSON.stringify(e.response.data.description));
      } else {
        error_alert(network_tl(intl))
      }
      setLoading(false)
    }
  }

  return (
    <div>
      <p className="text-base font-bold mb-8">
        <FormattedMessage id="select_col_export" defaultMessage={"Please select what columns you'd like to export"} />:
      </p>

      <div className='grid grid-cols-2 gap-5 mb-8 '>
        {
          options.map((item, index) => (
            selected.find(dat => dat.value === item.value) ?
              <button onClick={() => removeItem(item)} key={index} className='pr-2 rounded-lg border-2 border-primary flex items-center space-x-3'>
                <div className='border-r-2 border-primary w-9 sm:w-12 h-full aspect-square flex items-center justify-center'>
                  <div className='h-4 sm:h-5 w-4 sm:w-5 rounded-full ring-2 ring-primary flex items-center justify-center'>
                    <div className='w-2 h-2 sm:h-3 sm:w-3 rounded-full bg-primary ' />
                  </div>
                </div>

                <p className='text-left text-[10px] sm:text-sm font-semibold'>
                  {item.label}
                </p>
              </button>
              :
              <button onClick={() => appendItem(item)} key={index} className='pr-2 rounded-lg border-2 border-gray-200 flex items-center space-x-3'>
                <div className='border-r-2 border-gray-200 w-9 sm:w-12 h-full aspect-square flex items-center justify-center'>
                  <div className='h-4 sm:h-5 w-4 sm:w-5 rounded-full ring-2 ring-gray-200'>
                  </div>
                </div>

                <p className='text-left text-[10px] sm:text-sm font-semibold'>
                  {item.label}
                </p>
              </button>

          ))
        }
      </div>

      <button disabled={loading} onClick={handleExport} className='btn btn-primary w-full'>
        <FormattedMessage id="export" defaultMessage={"Export"} />
      </button>
    </div>
  )
}

export default Container