import React, { useContext, useState } from 'react'
import {BasePopupContext} from "../BasePopup";
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {BsCheckCircleFill} from 'react-icons/bs'
import { BASIC_PLAN, COMPANY_USER, PRO_PLAN } from '../../constants';
import { InnerBasePopupContext } from '../InnerBasePopup';
import { hidePopup } from '../../utils';
import { Link, useNavigate } from 'react-router-dom';
import PremiumFeatures from '../PremiumFeatures';

const useSubscription = () => {
    const navigate=useNavigate(null)
    const user =  useSelector((state)=>state.user)
    const {popupState, setPopup} = useContext(BasePopupContext)
    const innerPopup = useContext(InnerBasePopupContext)
    const setInnerPopup = innerPopup.setPopup;

    const subscription = useSelector(state => state.profile.data?.subscription)
    const disallow = user.role === COMPANY_USER?false: subscription?.type === BASIC_PLAN
    // const disallow = true
    
    const handlePopup = (callback) => {
        if(!disallow){
            callback()
            return
        }
        // //popup
        // const popup = {
        //     hideDefaultClose: true,
        //     component: <PopupContent  />,
        //     visible: true,
        //     heading: <FormattedMessage id='upgrade_to_pro' defaultMessage='Upgrade to Pro' />,
        //     contained: true,
        //     custom_close:<div className='px-4 py-1 rounded-md bg-gray-100 cursor-pointer text-primary text-xs font-semibold tracking-wider'>Skip</div>
        // }
        // if(popupState.visible){
        //     setInnerPopup(popup)
        // } else{
        //     setPopup(popup)
        // }
        //popup

        navigate('/subscription');
    }
    return (
        [disallow, handlePopup]
    )
}

const PopupContent = () => {
    const {popupState, setPopup} = useContext(BasePopupContext)
    const innerPopup = useContext(InnerBasePopupContext)
    const setInnerPopup = innerPopup.setPopup;
    const [selected,setSelected]=useState(false)


    const handleLearnMore=(e,text)=>{
        
        e.stopPropagation();
        const popup = {
            fullPage: true,
            hideDefaultClose: true,
            component: <PremiumFeatures/>,
            visible: true,
            heading: <><FormattedMessage id='pro_plan' defaultMessage='Pro Plan' />😎</>,
            contained: true
        }
        
        // setPopup(popup)
        setInnerPopup(popup)

        if(text === PRO_PLAN){
            console.log("pro")
        }else{
            console.log("basic")
        }

    }

    return(
        <>
        {/* <h1 className='font-bold text-primary text-3xl text-center mb-0'>
            <span className='text-primary-gradient'>Upgrade to Pro Plan!</span> 🚀
        </h1>
        <h3 className='font-bold text-primary text-lg text-center mb-5'>
            <span className='text-primary-gradient'>And unlock the true potential of Pro-ID!</span>
        </h3>
        <p className='font-semibold text-sm mb-2'>
            You are currently using the Basic Plan. In order to unlock more options, please subscribe to Pro Plan that includes everything in basic but also:
        </p>
        <ul className='list-disc pl-4 mb-5'>
            <li className='text-sm font-semibold'>
                    Unlimited devices ✨
            </li>
            <li className='text-sm font-semibold'>
                    Detailed Stats Insights 👀
            </li>
            <li className='text-sm font-semibold'>
                    Unlimited Groups 🔥
            </li>
            <li className='text-sm font-semibold'>
                    Unlimited Profiles 🤩
            </li>
            <li className='text-sm font-semibold'>
                    Export To CRM 🏨
            </li>
            <li className='text-sm font-semibold'>
                    + more benefits and options 🔥
            </li>
        </ul>
        <Link to="/subscription" onClick={() => {
            hidePopup(setPopup)
            hidePopup(setInnerPopup)
        }} className='text-center block mb-2 w-full btn bg-primary-gradient'>
            Subscribe to Pro Plan
        </Link>
        <button onClick={() => {
            hidePopup(setPopup)
            hidePopup(setInnerPopup)
        }} className='w-full btn'>
            No thanks!
        </button> */}

        <div className='space-y-5 mb-28 min-h-[700px]'>
                <div className={`bg-white cursor-pointer  shadow-xl p-7 rounded-xl`}>
                    <div className='mb-5 flex items-center justify-between'>
                        <div className='w-full'>
                            <div className='flex justify-between items-center '>
                                <div className='inline-flex space-x-3 items-center'>
                                    <img src="/images/logo-horizontal.svg" alt="pro id logo" className='w-[120px] sm:w-[140px]' />
                                    <img src='images/premium_icon.png' className=' flex-shrink-0 w-[28px] h-[28px] cursor-pointer'/>
                                </div>
                                <div className='inline-flex space-x-3 items-center'>
                                    <div className='px-2 py-1 rounded-md bg-[#2594CF] text-white text-xs font-semibold'>Suggested</div>
                                    {
                                        selected?
                                        <BsCheckCircleFill className=" text-[20px] flex-shrink-0 text-primary cursor-pointer " onClick={()=>setSelected(!selected)}/>
                                        :
                                        <div className='w-6 rounded-full h-6 flex-shrink-0 cursor-pointer border-2' onClick={()=>setSelected(!selected)}></div>
                                    }
                                </div>


                            </div>

                            <h2 className="pt-4 text-2xl text-primary font-bold">
                                € 14.99<span className='text-xs font-semibold'>/<FormattedMessage id='month' defaultMessage={"month"}/></span>
                            </h2>
                            <div className='pt-4 space-y-2'>
                                <div className=' flex text-sm space-x-4 text-primary font-semibold'>
                                    <div className='text-primary text-[11px]'>PRO</div>
                                    <div>Everything in the PRO-ID Basic</div>
                                </div>
                                <div className=' flex text-sm space-x-5 text-primary font-semibold items-center'>
                                    <img className='w-5 ' src='/images/icons/pro/unlimited_cards.png' />
                                    <div>Create Unlimited Business Crads</div>
                                </div>
                                    <div className='flex text-sm space-x-5 text-primary font-semibold items-center'>
                                            <img className='w-5' src='/images/icons/pro/creative.png' />
                                            <div>Use PRO-ID AI</div>
                                    </div>

                                <div className='flex text-sm space-x-6 text-primary font-semibold items-center'>
                                    <img className='w-4' src='/images/icons/pro/custom_leads.png' />
                                    <div>Custom Lead Capture Forms</div>
                                </div>
                                    <div className='flex text-sm space-x-6 text-primary font-semibold items-center'>
                                        <img className='w-4' src='/images/icons/pro/add-user.png' />
                                        <div>One Tap Add to Contacts</div>
                                    </div>
                                    <div className='flex text-sm space-x-5 text-primary font-semibold items-center'>
                                        <img className='w-5' src='/images/icons/pro/custom_widget.png' />
                                        <div>Custom Widgets</div>
                                    </div>
                                    <div className='flex text-sm space-x-6 text-primary font-semibold items-center'>
                                        <img className='w-4' src='/images/icons/pro/stats.png' />
                                        <div>Lifetime Analytics</div>
                                    </div>
                                    <div className='flex text-sm space-x-6 text-primary font-semibold items-center'>
                                        <img className='w-4' src='/images/icons/pro/accessories.png' />
                                        <div>Unlimited Device Management</div>
                                    </div>
                                    <div className='pt-5 text-sm text-[#2594CF] font-semibold'
                                    onClick={(e)=>handleLearnMore(e,PRO_PLAN)}
                                    >
                                        {"Learn More >"}
                                    </div>

                            </div>    
                        </div>
                    </div>
                </div>

                <div className={`shadow-xl cursor-pointer bg-white p-7 rounded-xl`}>
                    <div className='flex items-center justify-between'>
                            <div className='w-full'>
                                <div className='flex justify-between items-center '>
                                    <div className='inline-flex space-x-3 items-center'>
                                        <img src="/images/logo-horizontal.svg" alt="pro id logo" className='w-[120px] sm:w-[140px]' />
                                        <div className='text-lg text-gray-400 font-semibold'>Basic</div>
                                    </div>
                                    <div className='inline-flex space-x-3 items-center'>
                                        {
                                            selected?
                                            <BsCheckCircleFill className=" text-[20px] flex-shrink-0 text-primary cursor-pointer " onClick={()=>setSelected(!selected)}/>
                                            :
                                            <div className='w-6 rounded-full h-6 flex-shrink-0 cursor-pointer border-2' onClick={()=>setSelected(!selected)}></div>
                                        }
                                    </div>


                                </div>

                                <h2 className="pt-4 text-2xl text-primary font-bold">
                                    € 7.99<span className='text-xs font-semibold'>/<FormattedMessage id='month' defaultMessage={"month"}/></span>
                                </h2>
                                <div className='pt-4 space-y-2'>
                                    <div className=' flex text-sm space-x-5 text-primary font-semibold items-center'>
                                        <img className='w-4' src='/images/icons/pro/1pro-id card.png' />
                                        <div>Create Upto 1 Business Card</div>
                                    </div>
                                        <div className='flex text-sm space-x-5 text-primary font-semibold items-center'>
                                            <img className='w-4' src='/images/icons/pro/add-user.png' />
                                            <div>Capture Other's Info as Contacts</div>
                                        </div>
                                        
                                        <div className='flex text-sm space-x-6 text-primary font-semibold items-center'>
                                            <img className='w-4' src='/images/icons/pro/scanner.png' />
                                            <div>PRO-ID QR Code Scanner</div>
                                        </div>

                                        <div className='flex text-sm space-x-6 text-primary font-semibold items-center'>
                                            <img className='w-4' src='/images/icons/pro/stats.png' />
                                            <div>Basic Insights</div>
                                        </div>

                                        <div className='flex pt-5 text-sm space-x-6 text-[#2594CF] font-semibold items-center'
                                        onClick={(e)=>handleLearnMore(e,BASIC_PLAN)}
                                        >
                                            {"Learn More >"}
                                        </div>

                                </div>    
                            </div>
                    </div>
                </div>
        </div>

        <div className={`fixed bottom-0 left-0 p-8 right-0 bg-white -translate-y-[12%]  rounded-t-[28px] shadow-inner   `}>
            <div className='space-y-2 flex flex-col justify-center items-center'>
                <button className='btn btn-primary'>Upgrade to Pro</button>
                <p className='text-xs'>2 week free trial, with auto-renew. Cancel anytime</p>
            </div>
        </div>

        </>

    )
}

export default useSubscription