import React from 'react'
import * as yup from "yup";
import { Formik, Form } from "formik";
import { error_alert, hidePopup, stringNotEmpty, success_alert } from '../../../../utils';
import TextField from "../../../FormikFields/TextField";
import TextareaField from '../../../FormikFields/TextareaField';
import {BiLoaderAlt} from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import { Axios } from '../../../../api';
import { BasePopupContext } from '../../../BasePopup';
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { alphabet_only_tl, max_150_tl, network_tl, required_tl, valid_num_tl } from '../../../../translation';
import { ALPHABETS_REGEX, BASE_URL, NUMBER_REGEX, PRO_ID_ECOMMERCE_APP_URL, TELPHONE_MAXIMUM_LENGTH, TELPHONE_MINIMUM_LENGTH, TERMS_OF_SERVICES_URL } from '../../../../constants';
import { set_connect_data } from '../../../../redux/slices/connectSlice';
const ConnectForm = () => {
    const branding_detail = useSelector(state => state.branding_details)
    const {show_connect,qr, nfc, name,connected,} = useSelector(state => state.connect);
    const dispatch = useDispatch()
    const {setPopup} = useContext(BasePopupContext);
    const intl=useIntl()

    const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
        bio: "",
        consent: ""
    }
    yup.addMethod(yup.string, 'stringNotEmpty', stringNotEmpty)
    const validationSchema = yup.object({
        consent: yup.string().stringNotEmpty(),
        first_name: yup.string().stringNotEmpty(),
        last_name: yup.string().stringNotEmpty(),
        // first_name: yup.string().matches(ALPHABETS_REGEX, alphabet_only_tl(intl)).required(required_tl(intl)),    
        // last_name: yup.string().matches(ALPHABETS_REGEX, alphabet_only_tl(intl)).required(required_tl(intl)),
        email: yup.string().email().required(required_tl(intl)),
        phone: yup.string().matches(NUMBER_REGEX, valid_num_tl(intl)).min(TELPHONE_MINIMUM_LENGTH, valid_num_tl(intl)).max(TELPHONE_MAXIMUM_LENGTH, valid_num_tl(intl)),
        bio: yup.string().test({
            name: 'lessthan150',
            exclusive: false,
            message: max_150_tl(intl),
            test: function (value) {
                if (value) {
                    if(value.length <= 150){
                        return true
                    } else{
                        return false
                    }
                } else{
                    return true
                }
            },
        })
    })

    const handleSubmit = async (values, formikBag) => {
        try{
            //construct url
            // let url = ""
            // if(qr){
            //     url = `api/info/visitor/connect/qr/${qr}`
            // } else if (nfc){
            //     url = `api/info/visitor/connect/nfc/${nfc}`
            // }
            let url = `api/info/visitor/connect/profile-qr/${qr}`
            //construct url
            const result = await Axios.post(url, values, {requestId: "connect-post"});
            success_alert(result.data.description);
            if(show_connect){
                dispatch(set_connect_data({show_connect: false}))
            }else{
                dispatch(set_connect_data({show_connect: true}))
            }
            hidePopup(setPopup);
        } catch(e){
            if(e.response){
                error_alert(JSON.stringify(e.response.data.description))
            } else{
                error_alert(network_tl(intl))
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {
                form => (
                    <Form className=''
                    style={{color:branding_detail&&branding_detail.primary_color}}>
                        <>
                            <h2 className="mb-8 relative text-2xl font-extrabold inline-block break-all">
                                <FormattedMessage id='connect_with' defaultMessage='Connect with'/> {name}
                                <div className='absolute -bottom-2 left-0 h-2 w-2/6 bg-primary rounded-full'
                                 style={{backgroundColor:branding_detail&&branding_detail.primary_color}}
                                 />
                            </h2>

                            <div className="grid grid-cols-2 gap-4">
                                <TextField
                                    field="first_name"
                                    label_text={<><FormattedMessage id='first_name' defaultMessage="First Name"/>*</>}
                                    form={form}
                                />
                                <TextField
                                    field="last_name"
                                    label_text={<><FormattedMessage id='last_name' defaultMessage="Last Name"/>*</>}
                                    form={form}
                                />
                                <TextField
                                    field="email"
                                    label_text={<><FormattedMessage id='email' defaultMessage="Email"/>*</>}
                                    form={form}
                                />
                                <TextField
                                    field="phone"
                                    label_text={<><FormattedMessage id='phone' defaultMessage="Phone"/></>}
                                    form={form}
                                />
                                <div className="col-span-2">
                                    <TextareaField
                                        field="bio"
                                        label_text={<><FormattedMessage id='note' defaultMessage="Note"/></>}
                                        form={form}
                                    />
                                </div>

                                <div className="col-span-2">
                                    <div className="items-center space-x-2  flex">
                                        <input
                                            className='transform scale-150'
                                            type="checkbox"
                                            checked={form.values.consent ? true : false}
                                            onClick={() => form.setFieldValue('consent', form.values.consent ? "" : "1")}
                                        />
                                        <p className="text-sm">                                            
                                            <FormattedMessage id='i_have_read_privacy_policy'  defaultMessage="I have read the {privicay_policy} and {terms_of_use}"
                                            values={{
                                            privicay_policy:<a href={`${PRO_ID_ECOMMERCE_APP_URL}/privacy-policy`} target="_blank" className='text-primary font-bold underline'
                                            style={{color:branding_detail&&branding_detail.primary_color}}
                                            ><FormattedMessage id='privacy_policy' defaultMessage={"Privacy Policy"} /></a>,
                                            terms_of_use:<a href={`${PRO_ID_ECOMMERCE_APP_URL}/terms-of-service`} target="_blank" className='text-primary font-bold underline'
                                            style={{color:branding_detail&&branding_detail.primary_color}}
                                            ><FormattedMessage id='terms_of_use' defaultMessage={"Terms Of Use"} /></a>
                                            }}/>
                                        </p>
                                    </div>
                                    <p className="text-xs text-gray-600"
                                     style={{color:branding_detail&&branding_detail.primary_color}}
                                    >
                                    <FormattedMessage id='by_agreeing_to_these' defaultMessage="By agreeing to these terms, you acknowledge that you have read and understood the Privacy Policy and the Terms of Use, and that you give your consent to the collection and use of the information provided in accordance with the policy."/>
                                    </p>
                                    {
                                        form.errors.consent &&
                                        <p className='text-red-500 text-xs '>
                                            {form.errors.consent}
                                        </p>
                                    }
                                </div>

                                <div className="flex items-center">
                                    <button type="submit" disabled={form.isSubmitting} className='btn btn-primary'
                                     style={{backgroundColor:branding_detail&&branding_detail.primary_color}}>
                                        {
                                            form.isSubmitting ? 
                                            <BiLoaderAlt className="animate-spin"/>
                                            : <FormattedMessage id='submit' defaultMessage={"Submit"} />
                                        }
                                    </button>
                                </div>
                            </div>
                        </>
                    </Form>
                )
            }
        </Formik>
    )
}

export default ConnectForm