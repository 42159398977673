import React from 'react'
import {useSelector } from 'react-redux';
import {MdEmail} from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

const EmailLink = () => {
    const user = useSelector(state => state.user);
    return (
        <>
            <div className="transition-all text-primary border-b-[2px] border-gray-300 py-4 px-4 flex items-center ">
                <div className="w-5 mr-3">
                <MdEmail className='text-base group-hover:text-white  text-primary'/>
                </div>
                <div>
                <p className="text-sm font-bold">
                    <FormattedMessage id='email' defaultMessage='Email' />
                </p>
                <p className="text-sm group-hover:text-white text-gray-600">
                    {user.email}
                </p>
                </div>

            </div>
        </>
    )
}


export default EmailLink