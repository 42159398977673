import { BsDot } from 'react-icons/bs'
import { useDispatch } from 'react-redux';
import { set_edit } from '../../redux/slices/profileSlice';
import { set_onboard_tour } from '../../redux/slices/onBoardTourSlice';
import { set_add_mode } from '../../redux/slices/addLinksSlice';
import { useTour } from '@reactour/tour';

export function ContentComponent(props) {
    const dispatch = useDispatch();
    const {setCurrentStep} = useTour()
    const isLastStep = props.currentStep === props.steps.length - 1
    const content = props.steps[props.currentStep].content


    const handleNext = () => {

        if (isLastStep) {
            dispatch(set_edit(false))
            props.setIsOpen(false)
            dispatch(set_onboard_tour(false))
            setCurrentStep(0)

        }else {
            props.setCurrentStep((s) => s+1)
        }
        
        
    }


    const handleNavigate=(index)=>{
        console.log("index",index)
        if(props.currentStep<=index){
            return
        }

        if(index===0){
            dispatch(set_edit(false))  
            dispatch(set_add_mode(false))
        }
        if(index === 1){
            dispatch(set_add_mode(false))
            dispatch(set_edit(false))  
        }
        if(index===2){
            dispatch(set_add_mode(false))
            dispatch(set_edit(true))
        }
        props.setCurrentStep(index)
    }




    return (
        <div className="">
            {/* <div class="triangle-up absolute -top-3 left-4"></div> */}
            {/* Check if the step.content is a function or a string */}

            {
                typeof content === 'function' ?
                    content({
                        ...props,
                        someOtherStuff: 'Custom Text'
                    })
                    :
                    <>
                        <div className="px-5 pt-5 !max-w-[400px] space-y-3   ">
                            <h1 className="text-primary font-bold ">{
                                props.currentStep ===0 ?
                                " Update Profile 🚀 "
                                :
                                props.currentStep ===1 ?
                                "Add Bio 📝"
                                :
                                props.currentStep ===2?
                                "Add Channels🔥"
                                :
                                props.currentStep ===3?
                                "Custom Link🔗"
                                :
                                props.currentStep ===4?
                                "Update contact info ℹ"
                                :props.currentStep ===5?                                
                                "Changes ✅"
                                :""
                            }</h1>
                            <div className=" text-justify ">
                                {content}
                            </div>
                        </div>

                        <div className="mt-5 -mb-1 py-3 px-5 bg-primary-gradient flex justify-between items-center"
                            style={{
                                borderBottomLeftRadius: "14px",
                                borderBottomRightRadius: "14px",
                            }}>
                            <div className="text-white text-sm">
                                Step 0{props.currentStep + 1}
                            </div>

                            <div className='inline-flex  flex-shrink-0'>
                                {console.log("props.steps",props.steps)}
                                {
                                    props.steps.map((item, index) => {
                                        if (props.currentStep === index) {
                                            return <div className='border-[1px] rounded-xl '>
                                                <BsDot className="text-xl flex-shrink-0" />
                                            </div>
                                        } 
                                            if(props.currentStep != index){
                                                return <BsDot className={`text-xl flex-shrink-0 cursor-pointer ${props.currentStep<=index   ? "" : "text-gray-400 "}`} 
                                                    onClick={()=>handleNavigate(index)}
                                                />
                                            }
                                        }
                                    )
                                }
                            </div>
                            <div onClick={handleNext} className='text-lg cursor-pointer font-semibold text-white'>
                                {
                                    props.currentStep==props.steps.length-1?
                                    "FINISH"
                                    :
                                    "NEXT"
                                }
                            </div>
                        </div>
                    </>
            }


            {/* <button
                onClick={() => {
                    if (isLastStep) {
                        props.setIsOpen(false)
                    } else {
                        props.setCurrentStep((s) => s + 1)
                    }
                }}
            >
                {isLastStep ? 'x' : '>'}
            </button> */}



        </div >
    )
}