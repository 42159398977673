import React, {useContext} from 'react'
import { BasePopupContext } from '../BasePopup';

import { useDispatch } from 'react-redux';
import { set_sidebar } from '../../redux/slices/uiSlice';
import { AiOutlineDelete } from 'react-icons/ai';
import DeleteAccount from '../DeleteAccount';
import { FormattedMessage } from 'react-intl';

const Delete = () => {
    const { setPopup } = useContext(BasePopupContext)
    const dispatch = useDispatch();

    const handlePopup = () => {
        //popup
        const popup = {
            hideDefaultClose: true,
            component: <DeleteAccount />,
            visible: true,
            heading: <FormattedMessage id='delete_account' defaultMessage='Delete Account' />,
            contained: true
        }
        setPopup(popup)
        //popup
    }
    return (
        <>
            <div onClick={() =>{
                dispatch(set_sidebar(false))
                handlePopup()
            }} className="group cursor-pointer transition-all hover:bg-red-500 hover:text-white text-red-500 border-b-[2px] border-gray-300 py-4 px-4 flex items-center ">
                <div className="w-5 mr-3">
                <AiOutlineDelete className='text-base group-hover:text-white  text-red-500'/>
                </div>
                <div>
                <p className="text-sm font-bold">
                   <FormattedMessage id='delete_account' defaultMessage='Delete Account' />
                </p>
                </div>
            </div>
        </>
    )
}

export default Delete