import React, { useContext } from 'react'
import { AiOutlineExport } from 'react-icons/ai'
import { BasePopupContext } from '../../../../components/BasePopup'
import { FormattedMessage } from 'react-intl';
import View from './View';
import { EXPORT_ALL_CONNECTIONS, MEMBERS } from '../../../../constants';
import { useSelector } from 'react-redux';

const ExportContacts = () => {
    const currentTab = useSelector(state => state.connections.currentTab);
    const {setPopup} = useContext(BasePopupContext);

    
    const handlePopup = () => {
        if(currentTab != MEMBERS){
            return 
        }
        //popup
        const popup = {
            hideDefaultClose: true,
            component: <View export_type={EXPORT_ALL_CONNECTIONS} export_id={false} />,
            visible: true,
            heading: <FormattedMessage id='export_connections' defaultMessage='Export Connections' />,
            contained: true
        }
        setPopup(popup)
        //popup
    }

    return (
        <button onClick={handlePopup} className="">

            {
                currentTab === MEMBERS?
                    <img src="/images/icons/export.png" alt="" className={`select-none transition-all w-6 h-6  transform active:scale-[90%]`} draggable={false} />
                :
                    <img src="/images/icons/export_2.png" alt="" className={`cursor-not-allowed select-none transition-all w-[27px] h-[27px]  transform active:scale-[90%]`} draggable={false} />
            }
        </button>
    )
}

export default ExportContacts