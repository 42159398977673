import React, { useContext,useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Edit from './Edit';
import Qr from "../RightContainer/Qr"
import ProfileChange from '../ProfileChange';
import HideAddLinks from "../LeftContainer/HideAddLinks"
import Menu from "../LeftContainer/Menu"
import { useTour } from '@reactour/tour'


const Header = () => {
    const { setIsOpen } = useTour()
    const location = useLocation();
    const add_mode = useSelector(state => state.add_links.add_mode);
    const user = useSelector(state => state.user);


    return (
        <>
            <div className='flex items-center'>
                {
                    user ?
                    add_mode ?
                    <HideAddLinks />
                    :
                    <Menu />
                :
                    <Menu />
                }
                {
                    user ?
                    <ProfileChange />
                    :
                    null
                }
            </div>

            <div className="flex items-center space-x-2">
                {
                    user &&
                        <Edit />
                }
                <Qr />
            </div>
        </>
    )
}

export default Header