import { createSlice } from "@reduxjs/toolkit";
import { ALL_PROFILES, BOTH, MEMBERS } from "../../constants";

const initialState ={
    currentTab: MEMBERS,
    showFilters: false,
    connections: {
        startDate: '',
        endDate: '',
        search: '',
        filterCount: 0,
        profileFilter: ALL_PROFILES,
        reload: 0,
        filterType: BOTH
    },
    groups: {
        startDate: '',
        endDate: '',
        search: '',
        filterCount: 0,
        profileFilter: ALL_PROFILES,
        reload: 0,
        filterType: BOTH,
    },
    group_detail: {
        data: null,
        reload: 0
    }
}

const connectionsSlice = createSlice({
    initialState,
    name:"connections",
    reducers: {
        set_connections_tab: (state, action) => {
            state.currentTab = action.payload;
            state.connections = initialState.connections;
            state.groups = initialState.groups;
            return state
        },
        set_connections_show_filters: (state, action) => {
            state.showFilters = action.payload;
            return state
        },

        set_connections_filters: (state, action) => {
            state.connections = {
                ...state.connections,
                ...action.payload
            }
            return state
        },
        set_groups_filters: (state, action) => {
            state.groups = {
                ...state.connections,
                ...action.payload
            }
            return state
        },

        set_group_detail: (state, action) => {
            state.group_detail.data = action.payload;
        },

        set_group_detail_reload: (state, action) => {
            state.group_detail.reload = action.payload;
        },

        reset_connections_filters: (state, action) => {
            return initialState
        }
    }
})

export default connectionsSlice.reducer;

export const {
    set_connections_tab,
    set_connections_show_filters,
    set_connections_filters,
    set_groups_filters,
    reset_connections_filters,
    set_group_detail,
    set_group_detail_reload
} = connectionsSlice.actions;