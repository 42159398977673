import React, {useContext} from 'react'
import { BasePopupContext } from '../BasePopup';
import TextField from '../FormikFields/TextField';
import { Axios } from '../../api';
import { success_alert, error_alert, stringNotEmpty, hidePopup, getToken, setToken } from '../../utils';
import * as yup from "yup";
import {Formik, Form} from "formik";
import {BiLoaderAlt} from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import {FaLock} from "react-icons/fa";
import { set_sidebar } from '../../redux/slices/uiSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import { network_tl, password_not_match_tl, password_number_tl, password_short_tl, password_smallcase_tl, password_special_tl, password_uppercase_tl, required_tl } from '../../translation';

const ChangePassword = () => {
    const { setPopup } = useContext(BasePopupContext)
    const dispatch = useDispatch();
    

    const handlePopup = () => {
        //popup
        const popup = {
            hideDefaultClose: true,
            component: <ChangePasswordForm />,
            visible: true,
            heading: <FormattedMessage id='change_password' defaultMessage={"Change Password"}/>,
            contained: true
        }
        setPopup(popup)
        //popup
    }
    return (
        <>
            <div onClick={() =>{
                dispatch(set_sidebar(false))
                handlePopup()
            }} className="cursor-pointer transition-all hover:bg-primary group hover:text-white text-primary border-b-[2px] border-gray-300 py-4 px-4 flex items-center ">
                <div className="w-5 mr-3">
                <FaLock className='text-base group-hover:text-white  text-primary'/>
                </div>
                <div>
                <p className="text-sm font-bold">
                    <FormattedMessage id='change_password' defaultMessage={"Change Password"}/>
                </p>
                </div>
            </div>
        </>
    )
}

export const ChangePasswordForm = () => {
    const { setPopup } = useContext(BasePopupContext);
    const intl=useIntl()

    const initialValues = {
        old_password: "",
        password: "",
        confirm_password: "",
    }

    yup.addMethod(yup.string, 'stringNotEmpty', stringNotEmpty)
    const validationSchema = yup.object({
        old_password: yup.string()
        .stringNotEmpty(),
        new_password: yup.string().required(required_tl(intl))
        .min(8, password_short_tl(intl))
        .matches(/[a-z]/, password_smallcase_tl(intl))
        .matches(/[A-Z]/, password_uppercase_tl(intl))
        .matches(/\d/, password_number_tl(intl))
        .matches(/\W|_/, password_special_tl(intl)),
        // .matches(/[~`!@#$%\^&*()\-_=+[\]{};:\x27.,\x22\\|/?><]/, "Please include a special character in your password."),
        confirm_password: yup.string()
        .oneOf([yup.ref('new_password'), null], password_not_match_tl(intl)).required(required_tl(intl)),

    })


    const handleSubmit = async (values, formikBag) => {
        try {
            //construct url
            const result = await Axios.put(`api/user/update-password`, values, { requestId: "put-account-name",});
            success_alert(result.data.description);
            hidePopup(setPopup);
        } catch (e) {
            if (e.response) {
                error_alert(JSON.stringify(e.response.data.description))
            } else {
                error_alert(network_tl(intl))
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {
                form => (
                    <Form>
                        <>
                            <div className="grid grid-cols-1 gap-4">
                                <TextField
                                password
                                    larger
                                    field="old_password"
                                    label_text={<><FormattedMessage id='old_password' defaultMessage="Old Password"/>*</>}
                                    form={form}
                                />
                                <TextField
                                password
                                    larger
                                    field="new_password"
                                    label_text={<><FormattedMessage id='new_password' defaultMessage="New Password"/>*</>}
                                    form={form}
                                />
                                <TextField
                                password
                                    larger
                                    field="confirm_password"
                                    label_text={<><FormattedMessage id='confirm_password' defaultMessage="Confirm Password"/>*</>}
                                    form={form}
                                />

                                <div className="flex items-center">
                                    <button type="submit" disabled={form.isSubmitting} className='w-full btn btn-primary'>
                                        {
                                            form.isSubmitting ?
                                                <BiLoaderAlt className="animate-spin mx-auto text-3xl" />
                                                : <FormattedMessage id='submit' defaultMessage='Submit' />
                                        }
                                    </button>
                                </div>
                            </div>
                        </>
                    </Form>
                )
            }
        </Formik>
    )
}

export default ChangePassword