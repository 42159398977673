import React, {Suspense} from 'react';
import { Route, Routes, Navigate } from 'react-router';

import {LogoLoading} from "../../components/Loading";
import useSubscription from '../../components/useSubscription';
import { COMPANY_USER } from '../../constants';
import { useSelector } from 'react-redux';

// page imports
const Visitor = React.lazy(()=>import('../../pages/Visitor'));
const Home = React.lazy(()=>import('../../pages/Home'));
const Profiles = React.lazy(()=>import('../../pages/Profiles'));
const Tags = React.lazy(()=>import('../../pages/Tags'));
const Account = React.lazy(()=>import('../../pages/Account'));
const Stats = React.lazy(()=>import('../../pages/Stats'));
const Connections = React.lazy(()=>import('../../pages/Connections'));
const Notifications = React.lazy(()=>import('../../pages/Notifications'));
const AttachNewCard = React.lazy(()=>import('../../pages/AttachNewCard'));
const VisitorHistory = React.lazy(() => import('../../pages/VisitorHistory'));
const BusinessCardDetection = React.lazy(() => import('../../pages/BusinessCardDetection'));
const VerifyHubspot = React.lazy(() => import('../../pages/VerifyHubspot'));
const VerifySalesForce = React.lazy(() => import('../../pages/VerifySalesForce'));
const VerifyOutlook = React.lazy(() => import('../../pages/VerifyOutlook'));
const Subscription = React.lazy(() => import('../../pages/Subscription'));
const ManageCRM = React.lazy(() => import('../../pages/ManageCRM'));
const ManageCards = React.lazy(() => import('../../pages/ManageCards'));
const PremiumPage = React.lazy(() => import('../../pages/PremiumPage'));
const Branding = React.lazy(() => import('../../pages/Branding'));
const LogoutWeb = React.lazy(() => import('../../pages/LogoutWeb'));

// commenting privacy and terms because we will use these with social login
// const PrivacyPolicy = React.lazy(() => import('../../pages/PrivacyPolicy'));
// const TermsOfUse = React.lazy(() => import('../../pages/TermsOfUse'));

// const NotFound = React.lazy(()=>import('../../pages/NotFound'));
// page imports


function AdminRoutes(props) {
    const [disallow, subscriptionPopup] = useSubscription();
    const user = useSelector(state => state.user);
    return (
        <>
        <Suspense fallback={<LogoLoading/>}>
          <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/verify-hubspot" element={<VerifyHubspot />}/>
            <Route path="/pro-features" element={<PremiumPage />}/>
            <Route path="/verify-salesforce" element={<VerifySalesForce />}/>
            <Route path="/verify-outlook" element={<VerifyOutlook />}/>
            <Route path="/attach-card" element={<AttachNewCard />}/>
            <Route path="/profiles" element={<Profiles />}/>
            <Route path="/tags" element={<Tags />}/>
            <Route path="/account" element={<Account />}/>
            <Route path="/statistics" element={<Stats />}/>
            <Route path="/connections" element={<Connections />}/>
            <Route path='/notifications' element={<Notifications />} />

            <Route path='/tag/:id' element={<Visitor code={"tag"}/>}/>
            <Route path='/qr/:id' element={<Visitor code={"qr"} />}/>
            <Route path='/profile/:id' element={<Visitor code={"profile"} />} />
            <Route path='/profile-constant/:id' element={<Visitor code={"profile-constant"} />} />
            <Route path='/history' element={<VisitorHistory />} />
            <Route path='/manage-crm' element={<ManageCRM/>} />
            <Route path='/card-scan' element={<BusinessCardDetection/>}/>

            <Route path='/logout/:type' element={<LogoutWeb />} />

            {
              !(user.role === COMPANY_USER)&& // not allow to go on subscription page
              <>
                <Route path='/subscription' element={<Subscription/>} />
                <Route path='/manage-cards' element={<ManageCards/>} />
              </>
            }

            <Route path='/branding' element={<Branding/>}/>

            {/* <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-of-use' element={<TermsOfUse />} /> */}

            
           
            <Route path='*' element={<Navigate to="/" /> }/>

            {/* <Route path='*' element={<Navigate to="/" /> }/> */}
          </Routes>
        </Suspense>
        </>
    )
}

export default AdminRoutes
