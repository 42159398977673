import React from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { BasePopupContext } from '../BasePopup';
import { InnerBasePopupContext } from '../InnerBasePopup';
import { error_alert, hidePopup } from '../../utils';
import { set_profile_count } from '../../redux/slices/profileSlice';

const SubscriptionExpire = () => {
    const {count} = useSelector(state => state.expire_subscription);
    const navigate = useNavigate();
    const {setPopup} = useContext(BasePopupContext)
    const setInnerPopup = useContext(InnerBasePopupContext).setPopup 
    const dispatch = useDispatch();

    useEffect(() => {
        if(count > 0){
            hidePopup(setPopup)
            hidePopup(setInnerPopup)
            dispatch(set_profile_count())
            navigate("/subscription?expired=true")
        }
    }, [count])

  return (
    <>
    </>
  )
}

export default SubscriptionExpire