import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    edit: false, //shows whether we are in edit mode or not.
    loading: true,
    error: false,
    data: false,
    count: 0, //used to recall api
    prev_count: 0
}

const profileSlice = createSlice({
    initialState,
    name: "profile",
    reducers: {
        reset_profile: (state, action) => {
            return initialState
        },
        set_profile: (state, action) => {
            state.data = {...state.data, ...action.payload}
            return state
        },
        set_is_private: (state, action) => {
            state.data.is_private = !state.data.is_private;
            return state
        },
        set_profile_icons: (state, action) => {
            state.data.profile_data = action.payload
            return state
        },
        set_edit: (state, action) => {
            state.edit = action.payload
            return state
        },
        set_profile_loading: (state, action) => {
            state.loading = action.payload;
            return state
        },
        set_profile_error: (state, action) => {
            state.error = action.payload;
            return state
        },
        set_profile_count: (state, action) => {
            state.prev_count = state.count;
            state.count += 1;
            return state
        },
        set_profile_prev_count: (state, action) => {
            state.prev_count = state.count;
            return state
        },
        update_partial_profile: (state, action) => {
            state.data = {...state.data, ...action.payload}
            return state
        },
    }
})

export default profileSlice.reducer;

export const {
    set_is_private,
    set_profile,
    update_partial_profile,
    set_edit,
    set_profile_loading,
    set_profile_error,
    set_profile_count,
    set_profile_icons,
    set_profile_prev_count,
    reset_profile
} = profileSlice.actions;
