import React, { useEffect, useState } from 'react'
import { LISTING_LIMIT } from '../../../../constants';
import NoData from '../../../NoData';
import RetryApi from '../../../RetryApi';
import Loading from '../../../Loading';
import { Axios } from '../../../../api';
import axios from 'axios';
import { FormattedMessage, useIntl } from 'react-intl';
import { network_tl } from '../../../../translation';
import Container from './Container';
import { useDispatch, useSelector } from 'react-redux';
import { set_group_detail_reload, set_groups_filters } from '../../../../redux/slices/connectionsSlice';

const AddMembers = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(true);
    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selected, setSelected] = useState([]);

    const dispatch = useDispatch()

    const group_listing_reload = useSelector(state => state.connections.groups.reload)
    const refreshGroupListing = () => dispatch(set_groups_filters({reload: group_listing_reload+1}))
    const group_detail_reload = useSelector(state => state.connections.group_detail.reload)
    const refresh = () => dispatch(set_group_detail_reload({reload: group_detail_reload+1}))

    const group = useSelector(state => state.connections.group_detail.data);

    
    const intl = useIntl()

    const getData = async () => {
        setLoading(true);
        setError(false);
        let offset = 0;
        if (count) {
            offset = (currentPage * LISTING_LIMIT) - LISTING_LIMIT;
        } else {
            offset = 0;
        }
        try {
            const result = await Axios.get(`api/customer/members?limit=${LISTING_LIMIT}&off-set=${offset}&exclude_group_id=${group.id}`, { requestId: "tag-listing-get" });
            let arr = [...data, ...result.data.payload]
            setData(arr);
            setCount(result.data.count);
            setLoading(false);
        } catch (e) {
            if (e.response) {
                setError(JSON.stringify(e.response.data.description));
                setLoading(false)
            } else if (axios.isCancel(e)) {

            }
            else {
                setError(network_tl(intl))
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        getData()
    }, [currentPage])

    return (
        <>
            {
                loading && !error ?
                    <Loading />
                    : !loading && error ?
                        <RetryApi error={error} retry={getData} />
                        : !loading && !error ?
                            <div className="space-y-4 pt-5 px-2">
                                <Container refreshGroupListing={refreshGroupListing} refresh={refresh} group={group} setSelected={setSelected} selected={selected} data={data}/>
                                {
                                    data.length === 0 ?

                                        <NoData text={<FormattedMessage id='no_addition_group' defaultMessage={"You don't have any more connections to add to this group."} />} />

                                        :
                                        count <= LISTING_LIMIT || count === data.length ?
                                            null
                                        :
                                            <div className='flex items-center pt-5'>
                                                <button onClick={() => setCurrentPage(old => old+1)} className='w-full btn-sm btn-primary-outline'>
                                                    <FormattedMessage id="load_more" defaultMessage={"Load More"} />
                                                </button>
                                            </div>

                                }
                            </div>
                            :
                            null
            }
        </>
    )
}

export default AddMembers