import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import {FaUser} from "react-icons/fa";
import {BiChevronDown, BiLoaderAlt} from "react-icons/bi";
import { BasePopupContext } from '../../BasePopup';
import FormContainer from "./FormContainer";
import { FormattedMessage } from 'react-intl';
import useSubscription from '../../useSubscription';
import { PRO_PLAN } from '../../../constants';
import PremiumIcon from '../LeftContainer/PremiumIcon';

const ProfileChange = ({smaller}) => {
    const profile = useSelector(state => state.profile.data)
    const {error, loading} = useSelector(state => state.profile)
    const {setPopup} = useContext(BasePopupContext)

    const [disallow, subscriptionPopup] = useSubscription();

    const handleChangeProfile = () =>{
        if(loading || error) return
        //popup
        const popup = {
        hideDefaultClose: true,
        component: <FormContainer/>,
        visible: true,
        heading: <FormattedMessage id='change_profile' defaultMessage='Change Profile' />,
        contained: true
        }
        setPopup(popup)
        //popup
    }



    return (
        <div className='space-x-2 inline-flex items-center'>
            <button onClick={() => subscriptionPopup(handleChangeProfile)} className='select-none flex items-center space-x-2 '>
                <div className='h-8 w-8 rounded-full bg-no-repeat bg-cover bg-gray-300 flex items-center justify-center' style={{
                    backgroundImage: `url(${profile.image_file})`
                }}>
                    {
                        loading ?
                        <BiLoaderAlt className='text-lg text-white animate-spin'/>
                        : !profile.image_file ?
                        <FaUser className=' text-lg text-white'/>
                        :null
                    }
                </div>
                {
                    !smaller &&  
                    <div className='flex items-center'>
                        <p className='font-bold text-base text-primary'>
                            {profile.profile_name&&profile.profile_name.length>8?profile.profile_name.substring(0,8) +"..":profile.profile_name}
                        </p>
                        <BiChevronDown className="text-base"/>
                    </div>
                }
            </button>
            {
                !smaller&&
                <PremiumIcon/>
            }
           
        </div>
    )
}

export default ProfileChange