import React, { useContext, useState } from 'react'
import { useEffect } from 'react';
import { hidePopup } from '../../utils';
import { BasePopupContext } from '../BasePopup'
import { FormattedMessage, useIntl } from 'react-intl';

const Proceed = ({ setScreen }) => {
    const {setPopup} = useContext(BasePopupContext);
    const [count, setCount] = useState(10);
    const intl=useIntl()
    
    useEffect(() => {
        console.log(count)
        if(count !==0){
            setTimeout(() => {
                setCount(count-1);
            }, 1000)
        }
    }, [count])

    const handleProceed = () => {
        setScreen(1);
    }

    return (
        <>
            <h2 className="text-2xl font-bold text-center mb-4">
                <FormattedMessage id='attention' defaultMessage='Attention!' />
            </h2>
            <div className="space-y-2">
            <p className="text-sm">
                <FormattedMessage id='by_clicking_proceed' 
                values={{proceed: `"${intl.formatMessage({id:"proceed" , defaultMessage:"Proceed" })}"` }}
                defaultMessage='By clicking the button {proceed}, all your data will be deleted. By deleting your account, you are deleting all of your profiles, connections, and statistical data!' />

            </p>
            <p className="text-sm font-bold text-red-500">
                <FormattedMessage id='action_irreverable' defaultMessage={"This action is irreversible!"} />
            </p>
            <p className="text-sm">
                <FormattedMessage id='if_you_have_question' defaultMessage='If you have any questions, please contact us at email at {mail}' 
                values={{mail:<a href="mailto:info@pro-id.co" className='text-blue-500 underline font-medium'>info@pro-id.co</a>}}
                
                />
            </p>
            </div>

            <div className="flex items-center justify-center space-x-5 mt-5">
                <button onClick={() => hidePopup(setPopup)} type="button" className='btn btn-primary'>Cancel</button>
                <button type="button" onClick={handleProceed} className='btn btn-danger' disabled={count !== 0}>

                    {
                        count !== 0 ?
                        `Proceed (${count})`
                        :
                        "Proceed"
                    }    
                
                </button>
            </div>
        </>
    )
}

export default Proceed