import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {MdMenu} from "react-icons/md";
import { set_sidebar } from '../../../redux/slices/uiSlice';
import { THEME_DARK } from '../../../constants';

const Menu = ({branding_detail}) => {
  const dispatch = useDispatch();
  const handleMenu = () => {
      dispatch(set_sidebar(true))
  }

  const {unread_notifications, unread_connections} = useSelector(state => state.profile.data)

  return (
        <button 
          type="button"
          onClick={handleMenu}
          className="mr-3 relative focus:outline-none"
        >
          {
            unread_connections || unread_notifications ?
            <span className="flex h-3 w-3 absolute top-[0px] right-[-3px]">
              <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-500 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
            </span>
            : null
          }
          {
            branding_detail&&branding_detail.mode === THEME_DARK?
            <MdMenu className='text-3xl'  style={{ color: branding_detail.primary_color }}/>
            :
            <MdMenu className='text-3xl text-primary'/>


          }
        </button>
  )
}

export default Menu