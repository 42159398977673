import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { set_preview_mode } from '../../../../../redux/slices/initialpreviewBranding'
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import { DARK_THEME_COLOR } from '../../../../../constants';


const BrandingPreviewHeader = () => {
    const dispatch = useDispatch()
    const intialPreview = useSelector(state => state.initial_preview)

    return (
        <div className='w-full h-full mx-auto flex items-center justify-between'>
            <div className='flex items-center space-x-3'>
                <MdOutlineArrowBackIosNew className="text-primary text-2xl cursor-pointer"
                style={{ color: intialPreview.branding_color }}
                onClick={() => dispatch(set_preview_mode(false))} />
                <Link to="/">
                    {
                        intialPreview&&intialPreview.dark_theme?
                        <img src="/images/logo-horizontal.png" alt="pro id logo" className='w-[120px] sm:w-[140px]' />
                        :
                        <img src="/images/logo-horizontal.svg" alt="pro id logo" className='w-[120px] sm:w-[140px]' />

                    }
                </Link>
            </div>
            <div className="py-2 text-sm font-bold" style={{ color: intialPreview.branding_color }}>
                Disconnect
            </div>
        </div>

    )
}

export default BrandingPreviewHeader