import React, { useContext, useEffect, useState } from 'react'
import { BasePopupContext } from '../../../BasePopup'
import { hidePopup } from "../../../../utils";
import { STAT_DATE_FILTERS } from "../../../../constants";
import FilterDateIcon from "../../../Filter/FilterDateIcon";
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { set_flag, set_stats_filter } from '../../../../redux/slices/statsSlice';
import { DateTime } from 'luxon';

const View = () => {

    const { setPopup } = useContext(BasePopupContext);
    const dispatch = useDispatch();
    const {startDate, endDate, date} = useSelector(state => state.stats);

    const [localStart, setLocalStart] = useState(startDate);
    const [localEnd, setLocalEnd] = useState(endDate)
    const [localDate, setLocalDate] = useState(date)
    
    
    useEffect(() => {
        if(localStart || localEnd ){
            setLocalDate(false)
        }
    }, [localStart, localEnd])

    useEffect(() => {
        if(localDate){
            setLocalStart("")
            setLocalEnd("")
        }
    }, [localDate])

    const handleApply = () => {
        if(!localDate && (!localStart && !localEnd )){
            dispatch(set_stats_filter({
                date: STAT_DATE_FILTERS[0],
                startDate: localStart,
                endDate: localEnd
            }))
        }else{
            dispatch(set_stats_filter({
                date: localDate,
                startDate: localStart,
                endDate: localEnd
            }))
        }
        hidePopup(setPopup)
    }

    useEffect(()=>{
        if(!localStart){
            setLocalEnd("")
        }
    },[localStart])

    return (
        <>
            <div className=''>
                <h2 className='font-bold text-xl sm:text-2xl mb-2'>
                    Select date
                </h2>
                <div className="mb-0 grid grid-cols-3 gap-5">
                    {
                        STAT_DATE_FILTERS.map((item, index) => (
                            <button onClick={() =>setLocalDate(item)} className={`flex items-center justify-center h-[40px] rounded-lg btn-shadow ${item.label === localDate.label ? "bg-primary text-white" : "text-primary bg-white"} text-xs font-semibold`}>
                                {item.label}
                            </button>
                        ))

                    }
                </div>
                <div className='h-[1px] my-5 w-full bg-gray-200'>

                </div>
                <h2 className='font-bold text-xl sm:text-2xl mb-3'>
                    Custom date
                </h2>

                <div className='mb-8 flex justify-center items-center gap-5'>
                    <FilterDateIcon
                        state={localStart}
                        setState={setLocalStart}
                        label_text={<FormattedMessage id='filter_start_date' defaultMessage={'Start Date'} />}
                        maxDate={localEnd ? localEnd : DateTime.now()}
                    />
                    <FilterDateIcon
                        left
                        state={localEnd}
                        setState={setLocalEnd}
                        label_text={<FormattedMessage id='filter_end_date' defaultMessage={'End Date'} />}
                        minDate={localStart ? localStart : null}
                        isDisabled={!localStart}
                    />
                </div>

                <div className='flex items-center gap-3'>
                    <button onClick={()=>setLocalDate(STAT_DATE_FILTERS[0])}  className='w-full btn-sm btn-primary-outline'>
                        Reset Filters
                    </button>
                    <button onClick={() => hidePopup(setPopup)} className='w-full btn-sm btn-primary-outline'>
                        Cancel
                    </button>
                    <button onClick={handleApply}  className='w-full btn-sm btn-primary'>
                        Apply Filters
                    </button>
                </div>

                {/* {
                    STAT_DATE_FILTERS.map((item, index) => (
                        <div key={index} className="py-2 block transform transition-all select-none cursor-pointer bg-white border-b-[1px]">
                            <div className="flex items-center">
                                <div className="px-4 flex-grow">
                                    <div className='flex space-x-2 items-center'>
                                        <h2 className="text-primary font-semibold leading-snug">
                                            {item.label}
                                        </h2>
                                    </div>

                                </div>
                                <div className="flex items-center flex-shrink-0">
                                    <input onClick={() => handleSelection(item)} value={index} name={"date-radio"} type="radio" className='scale-[2]' defaultChecked={date.value === item.value ? true : false} />
                                </div>
                            </div>
                        </div>
                    ))
                } */}


            </div>
        </>

    )
}

export default View