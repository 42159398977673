import React, { useContext, useState } from 'react'
import _ from "lodash";
import { Axios } from '../../../../api';
import { error_alert, hidePopup, success_alert } from '../../../../utils';
import { FormattedMessage, useIntl } from 'react-intl';
import { network_tl } from '../../../../translation';
import { BasePopupContext } from '../../../BasePopup';
import { BiLoaderAlt } from 'react-icons/bi';

const Container = ({ refreshGroupListing, refresh, group, data, selected, setSelected }) => {
    const [loading, setLoading] = useState(false);
    const intl = useIntl();
    const {setPopup} = useContext(BasePopupContext);

    const handleAdd = (item) => {
        setSelected(old => {
            const copy = _.cloneDeep(old);
            const find = copy.findIndex(dat => dat.pre_compile_id === item.pre_compile_id);
            if(find !== -1){
                copy.splice(find, 1)
                return copy
            } else{
                return [...copy, item]
            }
        })
    }

    const handleSelectAll = () => {

        if(selected&&selected.length>0){
            setSelected([])
        }else{
            setSelected(data)
        }
    }

    const handleSubmit = async () => {
        try{
            setLoading(true);
            const formData = new FormData()
            formData.append("id", JSON.stringify(selected.map(item => item.pre_compile_id)))
            const result = await Axios.post(`api/groups/${group.id}/add-connection`, formData, {headers: { 'content-type': 'multipart/form-data' }})
            success_alert(result.data.description)
            refresh()
            refreshGroupListing()
            setLoading(false)
            hidePopup(setPopup);
        } catch(e){
            if(e.response){
                error_alert(JSON.stringify(e.response.data.description))
            } else{
                error_alert(network_tl(intl))
            }
            setLoading(false)
        }
    }

    return (
        <>
                {
                    data.length > 0 &&
                    <>
                        <div className='flex justify-end -mt-5'>
                            <button disabled={loading || selected&&selected.length<=0} onClick={handleSubmit} className='btn-sm btn-primary !text-sm font-bold'>
                                {
                                    loading ?
                                    <BiLoaderAlt className='animate-spin'/>
                                    :
                                    <FormattedMessage id="save" defaultMessage={"Save"}/>

                                }
                            </button>
                        </div>
                        <div className='flex justify-end  text-sm'>
                           <p className='cursor-pointer' onClick={()=>handleSelectAll()} >Select All</p>
                        </div>
                    </>
                }
                
            {
                data.map((item, index) => (
                    <>
                        <div key={index} className="py-4 block transform transition-all select-none cursor-pointer bg-white border-b-[1px]">
                            <div className="flex items-center">
                                <div className="flex-grow">
                                    <div className='flex space-x-2 items-center'>
                                        <h2 className="text-primary font-semibold leading-snug">
                                            {item.first_name + " " + item.last_name}
                                        </h2>
                                    </div>
                                    <div className='flex space-x-1'>
                                        <p className="text-xs text-primary">
                                            {item.email}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex items-center flex-shrink-0 mr-1">
                                    <input checked={selected.find(dat => dat.pre_compile_id === item.pre_compile_id) ? true : false} onClick={() => handleAdd(item)} className='scale-[2]' type='checkbox' />
                                </div>
                            </div>
                        </div>
                    </>
                ))
            }
        </>
    )
}

export default Container