import { FormattedMessage } from 'react-intl';
import en from './languages/en.json';
import it from './languages/it.json';
import ar from './languages/ar.json';
import { DateTime } from 'luxon';


export const BASE_URL = process.env.NODE_ENV === "production" ? process.env.REACT_APP_PRODUCTION_URL : process.env.REACT_APP_DEVELOPMENT_URL;
// export const BASE_URL = "https://pro-id-stagging-api.astutesoftwares00.com/" // Staging
// export const BASE_URL = "https://pro-id-dev-api.astutesoftwares00.com/" // Development
// export const BASE_URL = "http://192.168.18.47:8000/" // Local


export const FACEBOOK_LOGIN_APP_ID = process.env.REACT_APP_FACEBOOK_LOGIN_APP_ID
export const GOOGLE_LOGIN_CLIENT_ID = process.env.REACT_APP_GOOGLE_LOGIN_CLIENT_ID
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY //for cloud vision api

// export const PRO_ID_ECOMMERCE_APP_URL="https://pro-id.co/"
// export const PRO_ID_ECOMMERCE_APP_CONTACT_US_URL="https://pro-id.co/contact-us"
// export const TERMS_OF_SERVICES_URL = "https://pro-id.co/terms-of-service"

// export const PRO_ID_ECOMMERCE_APP_URL = "https://proid-nextjs.netlify.app/"

export const PRO_ID_ECOMMERCE_APP_URL = process.env.NODE_ENV === "production" ? process.env.PRO_ID_ECOMMERCE_APP_PRODUCTION_URL : process.env.PRO_ID_ECOMMERCE_APP_DEVELOPMENT_URL
export const PRO_ID_ECOMMERCE_APP_CONTACT_US_URL = PRO_ID_ECOMMERCE_APP_URL + "contact-us"
export const TERMS_OF_SERVICES_URL = PRO_ID_ECOMMERCE_APP_URL + "terms-of-service"


export const IMAGE_URl = (val) => BASE_URL.substr(0, process.env.REACT_APP_PRODUCTION_URL.length - 1) + val;
export const DATE_FORMAT_SERVER = "yyyy-MM-dd";
export const DATE_FORMAT_DISPLAY = "dd/MM/yyyy";
export const ACCESS_TOKEN = "pro_id_visitor_access";
export const PROMOTION_TOKEN = "pro_id_app";
export const HISTORY_TOKEN = "pro_id_visitor_history";
export const PRO_ID_LANGUAGE_ACCESS = "pro_id_lang"

export const POPUP_SMALL = "small";
export const POPUP_MEDIUM = "medium";
export const POPUP_LARGE = "large";

export const CUSTOM_TYPE = "custom-link"
export const SAVE_CONTACT = "save-contact";



export const SAVE_CONTACT_INITIAL_DATA = {
    name: "Save Contact",
    value: "",
    type: SAVE_CONTACT,
    icon_url: "https://s3.eu-central-1.amazonaws.com/pro-id/media/icons/telefon.png",
    platform: "save-contact",
    code: "save-contact",
    output_field: "link"
}

export const FIELD_TEXT = "text";
export const FIELD_FILE = "file";
export const FIELD_NUMBER = "number";
export const FIELD_LINK = "link";
export const FIELD_EMAIL = "email";
export const NUMBER_LINK = "number_link";


export const LISTING_LIMIT = 30;

export const initialPopupState = {
    component: null,
    visible: false,
    size: "small",
    heading: "",
    contained: false,
    hideDefaultClose: false,
    second_component: false
}


export const CARD_TYPE_APPLE_PAY = "apple_pay"
export const CARD_TYPE_CARD = "card"
export const CARD_TYPE_PAYPAL = "paypal"
export const CARD_TYPE_GOOGLE_PAY = "g_pay"


//social and card type
export const NFC = "nfc";
export const QR = "qr";

export const FACEBOOK = {
    code: "facebook",
    url: "https://www.facebook.com/"
};
export const INSTAGRAM = {
    code: "instagram",
    url: "https://www.instagram.com/"
};
export const SNAPCHAT = {
    code: "snapchat",
    url: "https://www.snapchat.com/add/"
};
export const TWITTER = {
    code: "twitter",
    url: "https://www.twitter.com/"
};

export const SOCIAL_URLS = [
    FACEBOOK,
    INSTAGRAM,
    SNAPCHAT,
    TWITTER
]

export const MAX_CHARS_BIO = 100;
export const SUPPORTED_IMAGES = ["image/png", "image/jpeg"]



export const LANGUAGE_ARRAY = [
    {
        title: <FormattedMessage id='english' defaultMessage={"English"} />,
        locale: "en",
        message: en,
        flag: "/images/lang-flags/uk.png"
    }
    ,
    {
        title: <FormattedMessage id='italian' defaultMessage={"Italian"} />,
        locale: "it",
        message: it,
        flag: "/images/lang-flags/italian.png"
    },
    {
        title: <FormattedMessage id='arabic' defaultMessage={"Arabic"} />,
        locale: "ar",
        message: ar,
        flag: "/images/lang-flags/arabic.png"
    }
]

// i use these constants to check members or guests or both on connection listing
export const MEMBERS = "members";
export const GUESTS = "guests";
export const BUSINESS_MEMBER = "business-card"
export const COMPANY_USER = "company-user" //for users which are under any business admin
export const BOTH = "both"
// i use these constants to check members or guests or both on connection listing
// this one below is used to check groups
export const GROUPS = "groups";
// this one above is used to check groups


export const ALL_PROFILES = {
    value: "",
    label: <FormattedMessage id="all_profile" defaultMessage={"All Profiles"} />
};

export const GOOGLE_CONST = "google"
export const FACEBOOK_CONST = "facebook"



export const STAT_DATE_FILTERS = [
    {
        "label": <FormattedMessage id="7_days" defaultMessage={"7 Days"} />,
        "value": {
            endDate: DateTime.now().toFormat(DATE_FORMAT_SERVER),
            startDate: DateTime.now().minus({ days: 6 }).toFormat(DATE_FORMAT_SERVER)
        }
    },
    {
        "label": <FormattedMessage id="15_days" defaultMessage={"15 Days"} />,
        "value": {
            endDate: DateTime.now().toFormat(DATE_FORMAT_SERVER),
            startDate: DateTime.now().minus({ days: 14 }).toFormat(DATE_FORMAT_SERVER)
        }
    },
    {
        "label": <FormattedMessage id="30_days" defaultMessage={"30 Days"} />,
        "value": {
            endDate: DateTime.now().toFormat(DATE_FORMAT_SERVER),
            startDate: DateTime.now().minus({ days: 29 }).toFormat(DATE_FORMAT_SERVER)
        }
    },
    {
        "label": <FormattedMessage id="60_days" defaultMessage={"60 Days"} />,
        "value": {
            endDate: DateTime.now().toFormat(DATE_FORMAT_SERVER),
            startDate: DateTime.now().minus({ days: 59 }).toFormat(DATE_FORMAT_SERVER)
        }
    },
    {
        "label": <FormattedMessage id="90_days" defaultMessage={"90 Days"} />,
        "value": {
            endDate: DateTime.now().toFormat(DATE_FORMAT_SERVER),
            startDate: DateTime.now().minus({ days: 89 }).toFormat(DATE_FORMAT_SERVER)
        }
    },
    {
        "label": <FormattedMessage id="120_days" defaultMessage={"120 Days"} />,
        "value": {
            endDate: DateTime.now().toFormat(DATE_FORMAT_SERVER),
            startDate: DateTime.now().minus({ days: 119 }).toFormat(DATE_FORMAT_SERVER)
        }
    }
]

export const HUBSPOT_CONNECT_URL = "https://app.hubspot.com/oauth/authorize?client_id=46e1ec31-730e-415b-bd28-7672ff808fc7&redirect_uri=https://pro-id-webapp.netlify.app/verify-hubspot&scope=tickets%20e-commerce%20crm.lists.read%20crm.objects.contacts.read%20crm.objects.contacts.write%20crm.objects.companies.write%20crm.schemas.contacts.read%20crm.lists.write%20crm.objects.companies.read%20crm.schemas.contacts.write"
export const PAYPAL_CLIENT_ID = process.env.NODE_ENV === "production" ? "AcSklcyrJvWLKwZxgVruIEnmxQrYruZBSP6fmpgc5tFwk3U9TVHHTo3MjHAQr8P2y-Ylf85FofiDR7J_" : "AVBRjnYHGFCqpVAJWpPrXVwMHJjNoIqW-MRmiJJCgshLQrH_8JAAKHZQsfgaIZ9Wfs0xxvj390hj95Q_"

export const BASIC_PLAN = 'basic';
export const TRIAL_PLAN = 'trial'
export const PRO_PLAN = 'premium'

export const SUBSCRIPTION_EXPIRE_ERROR_CODE = 406;

export const EXPORT_ALL_CONNECTIONS = "all-connections";
export const EXPORT_SINGLE_CONNECTION = "single-connection";
export const EXPORT_SINGLE_GROUP = "single-group"


export const THEME_DARK = 'dark'
export const THEME_LIGHT = 'light'
export const DARK_THEME_COLOR = "#262626"

export const EMAIL_REGX = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
export const ALPHABETS_REGEX = /^[a-z]+$/i
export const NUMBER_REGEX = /^[+]?\d+(-\d+)*$/ // for Phone number-> allow + sign in front and - any where between in phone numbers
// export const NUMBER_REGEX = /^[0-9]+$/

export const TELPHONE_MINIMUM_LENGTH = 10
export const TELPHONE_MAXIMUM_LENGTH = 15

export const BASIC_USER = "customer";

export const CRM_HUBSPOT = "hubspot";
export const CRM_SALESFORCE = "salesforce";
export const CRM_OUTLOOK = "outlook";

