import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode:false,
    branding_color:"",
    dark_theme:""
};


const Initialpreview = createSlice({
    name:"initial-preview-Branding",
    initialState,
    reducers:{
        set_preview_mode:(state,action)=>{
            state.mode = action.payload
            return state
        },
        set_branding_color:(state,action) => {
            state.branding_color = action.payload
            return state
        },
        set_dark_theme_color:(state,action) => {
            state.dark_theme = action.payload
            return state
        }
    }
})

export const {
    set_preview_mode,
    set_branding_color,
    set_dark_theme_color
} = Initialpreview.actions

export default Initialpreview.reducer;