import React, {useContext} from 'react'
import { useSelector } from 'react-redux';
import { BasePopupContext } from '../../../BasePopup';
import Form from "./Form";
import { FormattedMessage } from 'react-intl';
import useSubscription from '../../../useSubscription';

const NewProfile = () => {
    const {loading} = useSelector(state => state.profile_listing);
    const [disallow,subscriptionPopup] = useSubscription()

    const { setPopup } = useContext(BasePopupContext)

    const handlePopup = () => {
        //popup
        const popup = {
            hideDefaultClose: true,
            component: <Form />,
            visible: true,
            heading: <FormattedMessage id='add_profile' defaultMessage='Add Profile' />,
            contained: true
        }
        setPopup(popup)
        //popup
    }

    return (
        <>
            {
                !loading &&
                <button
                    onClick={() => {
                        subscriptionPopup(handlePopup)
                    }}
                    className="btn-sm btn-primary-outline flex-shrink-0 text-sm font-medium mr-2">
                    <FormattedMessage id='add_profile' defaultMessage='Add Profile' />
                </button>
            }
        </>
    )
}
export default NewProfile;