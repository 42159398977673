import React from 'react'
import { useDispatch } from 'react-redux';
import { set_sidebar } from '../../redux/slices/uiSlice';
import { PRO_ID_LANGUAGE_ACCESS } from '../../constants';
import { set_language } from '../../redux/slices/languageSlice';

const LinkContainer = ({to, icon, title, pulse, nested, item}) => {
    const dispatch = useDispatch()

    const handleLanguage=()=>{
        localStorage.setItem(PRO_ID_LANGUAGE_ACCESS, item.locale )
        dispatch(set_language(item))
    }
    return (
    <>
    {
        to?
        <a onClick={() => dispatch(set_sidebar(false))} target="_blank" href={to} className="cursor-pointer transition-all hover:bg-primary hover:text-white text-primary odd:border-b-[2px]  border-gray-300 py-4 px-4 flex items-center ">
            <div className="w-5 mr-3 relative">
                {icon}
                
            </div>
            <p className={nested ? "text-xs font-bold" : "text-sm font-bold"}>
                {title}
            </p>
            {
                    pulse ?
                    <span className="ml-2 flex h-3 w-3 ">
                        <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-500 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                    </span>
                    : null

                }
        </a>
        :
        <div onClick={handleLanguage} className="cursor-pointer transition-all hover:bg-primary hover:text-white text-primary odd:border-b-[2px]  border-gray-300 py-4 px-4 flex items-center ">
            <div className="w-5 mr-3 relative">
                {icon}
                
            </div>
            <p className={nested ? "text-xs font-bold" : "text-sm font-bold"}>
                {title}
            </p>
            {
                    pulse ?
                    <span className="ml-2 flex h-3 w-3 ">
                        <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-red-500 opacity-75"></span>
                        <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                    </span>
                    : null

                }
        </div>
    }
    </>
  )
}

export default LinkContainer