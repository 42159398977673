import React from 'react'

const UserInfo = () => {
    return (
        <>
                <img src="/images/logo-horizontal.svg" alt="pro id logo" className='ml-4 w-[120px] sm:w-[160px]' />
        </>
    )
}

export default UserInfo