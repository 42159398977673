import React from 'react'
import { FormattedMessage } from "react-intl";

// This component expects an api method as props which will be called on the button being pressed.
const RetryApi = ({ retry, error }) => {
  return (
    <div className="grid items-center p-4">
      <div className='flex flex-col items-center order-2 m-2 space-y-3'>
        <h3 className="text-2xl font-bold mr-2">
          <FormattedMessage id="error.occured" defaultMessage={"Something went wrong."} />
        </h3>
        <p className="font-medium">{error}</p>
        <button className='px-4 hover:bg-secondary focus:bg-white focus:border-2 focus:border-primary focus:text-primary transition-all btn-shadow focus:outline-none rounded-md bg-primary text-white py-4 text-sm font-medium' onClick={retry}><FormattedMessage id='try_again' defaultMessage='Try Again' /></button>
      </div>
      <div className="order-1 flex justify-center">
        {/* <img src="/images/error.svg" alt="error" className='max-w-[300px]' /> */}
        <div>
          <svg className="w-[250px] h-[317px]" width="240" height="269" viewBox="0 0 240 269" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="error 1">
<g id="Group">
<path id="Vector" d="M135.937 207.048C193.292 207.048 239.787 160.699 239.787 103.524C239.787 46.3495 193.292 0 135.937 0C78.5819 0 32.0867 46.3495 32.0867 103.524C32.0867 160.699 78.5819 207.048 135.937 207.048Z" fill="#F2F2F2"/>
<path id="Vector_2" d="M203.801 46.2958H13.5621C13.1542 46.2868 12.8306 45.9502 12.8393 45.5438C12.8477 45.1493 13.1665 44.8314 13.5621 44.8232H203.801C204.209 44.8318 204.533 45.1685 204.524 45.5752C204.516 45.9698 204.197 46.2873 203.801 46.2958Z" fill="#CACACA"/>
<path className="animate-error-bar" id="pulse-bar" d="M138.968 71.1113H86.3786C82.4283 71.1113 79.2261 74.3035 79.2261 78.241C79.2261 82.1786 82.4283 85.3708 86.3786 85.3708H138.968C142.918 85.3708 146.12 82.1786 146.12 78.241C146.12 74.3035 142.918 71.1113 138.968 71.1113Z" fill="white"/>
<path className="animate-error-bar" id="pulse-bar_2" d="M52.4053 99.6299H172.941C176.891 99.6299 180.093 102.822 180.093 106.76C180.093 110.698 176.891 113.89 172.941 113.89H52.4053C48.4554 113.89 45.2532 110.698 45.2532 106.76C45.2532 102.822 48.4554 99.6299 52.4053 99.6299Z" fill="white"/>
<path className="animate-error-bar" id="pulse-bar_3" d="M52.4053 128.149H172.941C176.891 128.149 180.093 131.342 180.093 135.279C180.093 139.217 176.891 142.409 172.941 142.409H52.4053C48.4554 142.409 45.2532 139.217 45.2532 135.279C45.2532 131.342 48.4554 128.149 52.4053 128.149Z" fill="white"/>
<path id="Vector_3" d="M37.468 268.086L37.1843 268.059C27.5595 267.069 18.8587 261.909 13.3935 253.949C11.8965 251.731 10.6856 249.334 9.7894 246.815L9.70325 246.574L9.95829 246.554C13.003 246.309 16.131 245.795 17.5205 245.549L8.56045 242.523L8.50505 242.258C7.97382 239.77 9.01453 237.21 11.1334 235.793C13.258 234.308 16.0754 234.26 18.2492 235.673C19.2247 236.294 20.2451 236.91 21.2318 237.505C24.6225 239.549 28.1287 241.663 30.7575 244.556C34.7436 249.03 36.4909 255.059 35.5125 260.963L37.468 268.086Z" fill="#F2F2F2"/>
<path id="Vector_4" d="M70.0374 263.645L74.453 263.644L76.5542 246.664L70.0361 246.664L70.0374 263.645Z" fill="#A0616A"/>
<path id="Vector_5" d="M69.8816 268.237L83.4618 268.237V268.066C83.4614 265.156 81.0949 262.797 78.1761 262.797H78.1757L75.6951 260.921L71.0669 262.797H69.8816V268.237Z" fill="#2F2E41"/>
<path id="Vector_6" d="M34.6472 251.452L38.1793 254.094L50.0824 241.768L44.8686 237.869L34.6472 251.452Z" fill="#A0616A"/>
<path id="Vector_7" d="M31.7578 255.032L42.6206 263.156L42.7241 263.019C44.4756 260.692 44.003 257.389 41.668 255.642L41.6676 255.642L40.8128 252.658L35.9812 251.39L35.0329 250.681L31.7579 255.032H31.7578Z" fill="#2F2E41"/>
<path id="Vector_8" d="M49.3268 183.96C49.3268 183.96 49.5705 190.589 49.878 195.905C49.9283 196.777 47.8795 197.724 47.9322 198.673C47.9678 199.302 48.3529 199.914 48.3885 200.57C48.4261 201.263 47.8946 201.875 47.9322 202.591C47.9694 203.291 48.5762 204.094 48.6126 204.81C49.0063 212.517 50.434 221.743 49.0047 224.322C48.3002 225.593 39.3206 243.203 39.3206 243.203C39.3206 243.203 44.3236 250.684 45.753 248.19C47.6411 244.896 62.1916 229.488 62.1916 226.545C62.1916 223.606 65.5581 201.58 65.5581 201.58L67.9054 214.615L68.9815 217.197L68.6434 218.713L69.3388 220.76L69.3764 222.785L70.0535 226.545C70.0535 226.545 68.0166 255.842 69.1638 256.658C70.3144 257.478 75.8859 258.949 76.5433 257.478C77.1975 256.006 81.4541 226.901 81.4541 226.901C81.4541 226.901 82.1402 213.564 82.8835 201.166C82.9264 200.447 83.4338 199.578 83.4731 198.869C83.5197 198.046 83.1979 196.977 83.2408 196.179C83.2875 195.264 83.6947 194.619 83.7339 193.742C84.0377 186.97 82.3328 178.724 81.7894 177.911C80.1491 175.461 78.6731 173.501 78.6731 173.501C78.6731 173.501 56.5804 166.265 50.6765 173.618L49.3268 183.96Z" fill="#40E0D0"/>
<path id="Vector_9" d="M71.3613 115.794L62.6031 114.048L58.5162 119.577C50.8897 127.052 50.7748 133.65 52.1363 144.225V159.067L51.151 169.017C51.151 169.017 47.6626 176.29 51.2606 177.983C54.8581 179.677 78.4897 179.533 80.7462 178.856C83.0023 178.181 81.0467 177.35 80.4543 174.2C79.3414 168.282 80.0855 171.137 80.1624 169.253C81.2602 142.225 78.7023 131.129 78.4427 128.213L73.9886 120.159L71.3613 115.794Z" fill="#3F3D56"/>
<path id="left-hand" d="M117.522 137.586C116.609 139.31 114.467 139.97 112.738 139.06C112.554 138.963 112.378 138.85 112.213 138.722L93.2996 150.694L93.3539 144.176L111.728 133.547C113.036 132.084 115.287 131.956 116.754 133.261C117.982 134.353 118.299 136.141 117.522 137.586Z" fill="#A0616A"/>
<path id="left-arm" d="M64.4463 123.342L62.2929 123.114C60.3034 122.909 58.3511 123.758 57.1498 125.352C56.6895 125.957 56.3591 126.649 56.1788 127.387L56.1776 127.393C55.6375 129.612 56.4846 131.938 58.3273 133.295L65.7645 138.761C70.9836 145.791 80.7929 150.455 92.2986 154.245L110.183 143.172L103.857 135.535L91.5904 142.089L73.3562 128.018L73.3455 128.009L66.4056 123.552L64.4463 123.342Z" fill="#3F3D56"/>
<path id="Vector_10" d="M68.6379 111.509C68.5557 111.511 68.4731 111.513 68.3905 111.515C68.3554 111.607 68.3174 111.697 68.2732 111.786L68.6379 111.509Z" fill="#2F2E41"/>
<path id="Vector_11" d="M71.4407 101.607C71.457 101.708 71.4816 101.806 71.5143 101.903C71.5008 101.802 71.4758 101.703 71.4407 101.607Z" fill="#2F2E41"/>
<g id="head">
<path id="Vector_12" d="M68.402 111.507C74.0426 111.507 78.6153 106.949 78.6153 101.326C78.6153 95.7029 74.0426 91.1445 68.402 91.1445C62.7614 91.1445 58.1887 95.7029 58.1887 101.326C58.1887 106.949 62.7614 111.507 68.402 111.507Z" fill="#A0616A"/>
<path id="Vector_13" d="M78.7456 91.5799C78.3249 92.8813 77.95 91.2306 76.5668 91.5273C74.9032 91.8847 72.9677 91.762 71.6049 90.7456C69.5754 89.2608 66.9448 88.8609 64.5632 89.6757C62.2322 90.4977 58.3268 91.0773 57.7193 93.4665C57.5083 94.2972 57.4245 95.1987 56.9126 95.8871C56.4653 96.4887 55.7588 96.8319 55.1684 97.2953C53.1744 98.8592 54.6982 103.303 55.6423 105.651C56.5859 107.999 58.7508 109.709 61.1312 110.585C63.4343 111.433 65.9337 111.582 68.3905 111.516C68.8177 110.411 68.6333 109.135 68.2805 107.99C67.899 106.75 67.3336 105.553 67.1741 104.266C67.0147 102.979 67.3651 101.517 68.4477 100.798C69.4429 100.137 71.0624 100.539 71.441 101.608C71.2198 100.272 72.5776 98.9786 73.9779 98.7512C75.4809 98.507 76.9843 99.0483 78.4832 99.3173C79.9816 99.5867 79.4497 93.2962 78.7456 91.5799Z" fill="#2F2E41"/>
</g>
<path id="cross" d="M135.865 118.167C136.818 106.7 128.264 96.6337 116.76 95.6845C105.256 94.7352 95.1582 103.263 94.2064 114.73C93.2542 126.198 101.808 136.264 113.312 137.213C124.816 138.162 134.914 129.635 135.865 118.167Z" fill="#40E0D0"/>
<path id="Vector_14" d="M120.526 107.767L115.036 113.24L109.545 107.767C108.657 106.881 107.216 106.881 106.327 107.767C105.438 108.653 105.438 110.089 106.327 110.975L111.817 116.448L106.327 121.921C105.439 122.808 105.44 124.245 106.33 125.13C107.219 126.013 108.657 126.013 109.545 125.13L115.036 119.656L120.526 125.13C121.416 126.015 122.857 126.013 123.745 125.127C124.631 124.241 124.631 122.807 123.745 121.921L118.254 116.448L123.745 110.975C124.633 110.089 124.633 108.653 123.745 107.767C122.856 106.881 121.415 106.881 120.526 107.767Z" fill="white"/>
<path id="right-hand" d="M106.8 98.936C107.416 100.786 106.41 102.784 104.555 103.398C104.357 103.464 104.154 103.512 103.947 103.542L99.5188 125.435L94.8381 120.884L99.8642 100.308C99.7113 98.354 101.177 96.6467 103.137 96.4942C104.777 96.3667 106.291 97.3762 106.8 98.936Z" fill="#A0616A"/>
<path id="right-arm" d="M59.7444 127.256L58.0889 128.647C56.5627 129.936 55.8263 131.928 56.1485 133.896C56.2675 134.646 56.5402 135.363 56.949 136.003L56.9523 136.008C58.185 137.933 60.455 138.934 62.7131 138.548L71.8184 136.982C80.521 138.095 90.688 134.268 101.396 128.614L105.763 108.084L95.8552 107.347L92.1068 120.708L69.2974 124.083L69.2839 124.085L61.2527 125.992L59.7444 127.256Z" fill="#3F3D56"/>
<path id="Vector_15" d="M111.593 268.589L0.48525 268.714C0.216387 268.713 -0.00086826 268.495 2.60904e-06 268.227C0.0008653 267.961 0.217609 267.745 0.48525 267.744L111.593 267.618C111.861 267.619 112.079 267.837 112.078 268.105C112.077 268.372 111.86 268.588 111.593 268.589Z" fill="#CACACA"/>
<path id="ball" d="M23.2137 36.9689C25.1232 36.9689 26.6712 35.3914 26.6712 33.4454C26.6712 31.4994 25.1232 29.9219 23.2137 29.9219C21.3041 29.9219 19.7561 31.4994 19.7561 33.4454C19.7561 35.3914 21.3041 36.9689 23.2137 36.9689Z" fill="#3F3D56"/>
<path id="ball_2" d="M35.158 36.9689C37.0676 36.9689 38.6156 35.3914 38.6156 33.4454C38.6156 31.4994 37.0676 29.9219 35.158 29.9219C33.2485 29.9219 31.7004 31.4994 31.7004 33.4454C31.7004 35.3914 33.2485 36.9689 35.158 36.9689Z" fill="#3F3D56"/>
<path id="ball_3" d="M47.1021 36.9689C49.0119 36.9689 50.5598 35.3914 50.5598 33.4454C50.5598 31.4994 49.0119 29.9219 47.1021 29.9219C45.1927 29.9219 43.6448 31.4994 43.6448 33.4454C43.6448 35.3914 45.1927 36.9689 47.1021 36.9689Z" fill="#3F3D56"/>
<path id="Vector_16" d="M60.7221 36.2297C60.6158 36.2297 60.5099 36.1887 60.4298 36.1071L58.0968 33.7297C57.941 33.5713 57.941 33.3182 58.0968 33.1597L60.4298 30.7826C60.588 30.6217 60.8472 30.6192 61.0079 30.7762C61.1694 30.9336 61.1722 31.1917 61.0144 31.3525L58.9611 33.4447L61.0144 35.5371C61.1722 35.6979 61.1694 35.956 61.0079 36.1135C60.9286 36.1911 60.8251 36.2297 60.7221 36.2297Z" fill="#3F3D56"/>
<path id="Vector_17" d="M64.6422 36.23C64.5392 36.23 64.4358 36.1914 64.3564 36.1138C64.1949 35.9564 64.1921 35.6984 64.3499 35.5374L66.4032 33.445L64.3499 31.3528C64.1921 31.192 64.1949 30.9339 64.3564 30.7765C64.5175 30.6189 64.7767 30.6218 64.9346 30.7828L67.2671 33.16C67.4229 33.3184 67.4229 33.5716 67.2671 33.73L64.9346 36.1074C64.8544 36.189 64.7485 36.23 64.6422 36.23Z" fill="#3F3D56"/>
<path className=""id="ball_4" d="M186.691 30.3291H182.353C181.858 30.3291 181.457 30.7285 181.457 31.2217V35.5501C181.457 36.0433 181.858 36.4427 182.353 36.4427H186.691C187.185 36.4427 187.59 36.0433 187.59 35.5501V31.2217C187.59 30.7285 187.185 30.3291 186.691 30.3291Z" fill="#3F3D56"/>
<path id="ball_5" d="M176.06 30.3291H171.722C171.228 30.3291 170.827 30.7285 170.827 31.2217V35.5501C170.827 36.0433 171.228 36.4427 171.722 36.4427H176.06C176.555 36.4427 176.96 36.0433 176.96 35.5501V31.2217C176.96 30.7285 176.555 30.3291 176.06 30.3291Z" fill="#3F3D56"/>
<path id="ball_6" d="M196.708 30.5332H192.37C191.875 30.5332 191.474 30.9326 191.474 31.4258V35.7542C191.474 36.2474 191.875 36.6468 192.37 36.6468H196.708C197.202 36.6468 197.607 36.2474 197.607 35.7542V31.4258C197.607 30.9326 197.202 30.5332 196.708 30.5332Z" fill="#3F3D56"/>
<path id="line" d="M131.322 32.1797H96.6468C96.0417 32.1797 95.5552 32.6688 95.5552 33.2679C95.5552 33.8671 96.0417 34.3561 96.6468 34.3561H131.322C131.923 34.3561 132.414 33.8671 132.414 33.2679C132.414 32.6688 131.923 32.1797 131.322 32.1797Z" fill="#3F3D56"/>
</g>
</g>
</svg>


        </div>
      </div>
    </div>
  )
}

export default RetryApi