import React from 'react'
import { useContext } from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { BasePopupContext } from '../../../BasePopup'
import QrShareRename from '../../../QrShareRename';
import { FormattedMessage } from 'react-intl';

const Qr = () => {
    const { setPopup } = useContext(BasePopupContext);
    const {loading} = useSelector(state => state.profile.loading);
    const user = useSelector(state => state.user);
    const location = useLocation();
    const error = useSelector(state => state.profile.error);


    const handlePopup = () => {
        //popup
        const popup = {
            hideDefaultClose: true,
            component: <QrShareRename/>,
            visible: true,
            heading: <FormattedMessage id='rename_qr' defaultMessage='Rename QR Code' />,
            contained: true,
            isbranded:true
        }
        setPopup(popup)
        //popup
    }
    return (
        <>
        {
            user && (!loading && !error) ?
            !location.pathname.startsWith("/profile/") && !location.pathname.startsWith("/tag/") && !location.pathname.startsWith("/qr/") && !location.pathname.startsWith("/profile-constant/") &&
                <>
                <img onClick={handlePopup} src="/images/icons/qr.svg" alt="" className='select-none transition-all w-6 h-6 cursor-pointer transform active:scale-[90%]' draggable={false} />
                </>
            : null

        }
        </>
    )
}

export default Qr