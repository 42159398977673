import React, { useState } from 'react'
import GenericLink from './GenericLink';
import ExternalLink from './ExternalLink';
import {FaHistory, FaUserFriends, FaUsers, FaIdCard} from "react-icons/fa";
import {AiFillBell, AiFillHome, AiFillQuestionCircle} from "react-icons/ai";
import {MdLanguage, MdPrivacyTip} from "react-icons/md";
import {BiSupport} from "react-icons/bi";
import {MdOutlineManageAccounts} from "react-icons/md";
import {RiSecurePaymentFill} from "react-icons/ri";
import {IoCard, IoCart} from "react-icons/io5";

import {IoMdStats} from "react-icons/io";
import UpdateAccountName from './UpdateAccountName';
import EmailLink from './EmailLink';
import ChangePassword from './ChangePassword';
import Logout from './Logout';
import Delete from './Delete';
import { useSelector } from 'react-redux';
import Dropdown from "./Dropdown";
import PrivatePublic from './PrivatePublic';
import { FormattedMessage } from 'react-intl';
import { COMPANY_USER, PRO_ID_ECOMMERCE_APP_URL, TERMS_OF_SERVICES_URL } from '../../constants';
import { BsGearFill } from "react-icons/bs";
import useSubscription from '../useSubscription';
import RequestVisitorData from './RequestVisitorData';
import { HiColorSwatch } from "react-icons/hi";

const LinksContainer = () => {
  const user = useSelector((state)=>state.user)
  const {unread_notifications, unread_connections} = useSelector(state => state.profile.data)
  const [disallow, subscriptionPopup] = useSubscription();
 

  return (
    <div className="my-8">
        <GenericLink
          to={"/"}
          title={<FormattedMessage id='home' defaultMessage='Home' />}
          icon={<AiFillHome className='text-lg font-bold'  />}
        />
        <GenericLink
          to={"/profiles"}
          title={<FormattedMessage id='my_profiles' defaultMessage="My Profiles" />}
          icon={<FaUserFriends className='text-lg font-bold'  />}
        />
        <GenericLink
          to={"/tags"}
          title={<FormattedMessage id='my_tags' defaultMessage="My Tags" />}
          icon={<IoCard className='text-lg font-bold'  />}
        />
        <GenericLink
          to={"/attach-card"}
          title={<FormattedMessage id='attach_new_card' defaultMessage="Attach New Card" />}
          icon={<IoCard className='text-lg font-bold'  />}
        />
        <ExternalLink
          to={`${PRO_ID_ECOMMERCE_APP_URL}shop`}
          title={<FormattedMessage id='buy_new_tag' defaultMessage="Buy New Tag" />}
          icon={<IoCart className='text-lg font-bold'  />}
        />
        <GenericLink
          to={"/connections"}
          title={<FormattedMessage id='my_connection' defaultMessage="My Connections" />}
          icon={<FaUsers className='text-lg font-bold'  />}
          pulse={unread_connections ? true : false}
        />
        <GenericLink
          to={"/notifications"}
          title={<FormattedMessage id='notifications' defaultMessage="Notifications" />}
          icon={<AiFillBell className='text-lg font-bold'  />}
          pulse={unread_notifications ? true : false}

        />
        <GenericLink
          to={"/history"}
          title={<FormattedMessage id='history' defaultMessage="History" />}
          icon={<FaHistory className='text-base font-bold'  />}
        />
        <GenericLink
          to={"/statistics"}
          title={<FormattedMessage id='statistics' defaultMessage="Statistics" />}
          icon={<IoMdStats className='text-lg font-bold'  />}
        />
         <GenericLink
          to={!disallow? "/card-scan" :"/subscription" }
          title={<FormattedMessage id='card_scan' defaultMessage="Card Scan" />}
          icon={<FaIdCard className='text-lg font-bold'
          />}
        />
        {
          user.role != COMPANY_USER?
          <>
            <GenericLink
              to={"/subscription"}
              title={<FormattedMessage id='subscription' defaultMessage="Subscription" />}
              icon={<img src='/images/icons/subscription.png'/>}
              iconWhite={<img src='/images/icons/subscription-white.png'/>}
            />
          <GenericLink
            to={ !disallow? "/manage-cards" :"/subscription"}
            title={<FormattedMessage id='manage_cards' defaultMessage="Manage Cards" />}
            icon={<img src='/images/icons/card_management.png'/>}
            iconWhite={<img src='/images/icons/card_management-white.png'/>}
          />
          </>
          :""
        }
        <GenericLink
          to={"/manage-crm"}
          title={<FormattedMessage id='manage_crm' defaultMessage="Manage CRM" />}
          icon={<MdOutlineManageAccounts className='text-xl font-bold'  />}
        />
         

        <div className="font-bold text-sm text-primary py-3 px-2 bg-[#dddddd]">
          <FormattedMessage id='account_information' defaultMessage="Account Information" />
        </div>
        <UpdateAccountName />
        <EmailLink />
        <ChangePassword />
        {/* <div className="font-bold text-sm text-primary py-3 px-2 bg-[#dddddd] w-full">
          Privacy
        </div>
        <PrivatePublic/> */}
        <div className="font-bold text-sm text-primary py-3 px-2 bg-[#dddddd] w-full">
          <FormattedMessage id='settings' defaultMessage='Settings' />
        </div>
       {/* <RequestVisitorData/> */}
       {
       <GenericLink
          to={!disallow?"/branding":"/subscription"}
          title={<FormattedMessage id='branding' defaultMessage="Branding" />}
          icon={<HiColorSwatch className='text-lg font-bold' />}
        />
       }
        <ExternalLink
          to={`${PRO_ID_ECOMMERCE_APP_URL}/terms-of-service`}
          title={<FormattedMessage id='terms_of_use' defaultMessage='Terms Of Use' />}
          icon={<AiFillQuestionCircle className='text-lg font-bold'  />}
        />
        <ExternalLink
          to={`${PRO_ID_ECOMMERCE_APP_URL}/privacy-policy`}
          title={<FormattedMessage id='privacy_policy' defaultMessage='Privacy Policy' />}
          icon={<MdPrivacyTip className='text-lg font-bold'  />}
        />
        
        <Dropdown
          title={<FormattedMessage id='faqs' defaultMessage='FAQs' />}
          icon={<BiSupport className='text-lg font-bold'  />}
        />
         {/* <Dropdown
          title={<FormattedMessage id='language' defaultMessage="Language" />}
          icon={<MdLanguage className='text-lg font-bold'  />}
          language={true}
        /> */}
        <Logout />
        <Delete />
    </div>
  )
}

export default LinksContainer