import React, {useContext, useState} from 'react'
import { BasePopupContext } from '../BasePopup';
import { useDispatch } from 'react-redux';
import {BiExit, BiLoaderAlt} from "react-icons/bi";
import { set_sidebar } from '../../redux/slices/uiSlice';
import { logout } from '../../redux/slices/userSlice';
import { error_alert, removeToken, success_alert } from '../../utils';
import { reset_profile } from '../../redux/slices/profileSlice';
import { Axios } from '../../api';
import {  FormattedMessage, useIntl } from 'react-intl';
import { network_tl } from '../../translation';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const { setPopup } = useContext(BasePopupContext)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const intl=useIntl()
    const navigate=useNavigate()

    const handleLogout = async () => {
        setLoading(true)
        try{
            const result = await Axios.get('api/user/logout/admin');
            removeToken();
            dispatch(set_sidebar(false))
            dispatch(reset_profile())
            dispatch(logout(false))
            navigate('/')
            success_alert(intl.formatMessage({id:"user_logout", defaultMessage:"User logged out successfully!" }))
        } catch(e){
            if(e){
                error_alert(JSON.stringify(e.response.data.description));
            } else{
                error_alert(network_tl(intl));
            }
            setLoading(false)
        }
    }

    return (
        <>
            <div onClick={() => {
                if(!loading){
                    handleLogout();
                }
            }} className="cursor-pointer transition-all hover:bg-primary group hover:text-white text-primary border-b-[2px] border-gray-300 py-4 px-4 flex items-center ">
                <div className="w-5 mr-3">
                    {
                        loading ? 
                        <BiLoaderAlt className='text-base group-hover:text-white  text-primary animate-spin'/>
                        :
                        <BiExit className='text-base group-hover:text-white  text-primary'/>
                    }
                </div>
                <div>
                <p className="text-sm font-bold">
                    <FormattedMessage id='logout' defaultMessage='Logout' />
                </p>
                </div>
            </div>
        </>
    )
}


export default Login