import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl';

const Share = ({value}) => {
  const [copy, setCopy] = useState(false)
  const handleCopy=async()=>{
    navigator.clipboard.writeText(value);
    setCopy(true)
}

useEffect(()=>{
    if(copy){
        setTimeout(()=>{
            setCopy(false)
        },2000)
    }
},[copy])
    return (
    <button onClick={handleCopy} className='btn-sm btn-primary-outline w-2/4'>
        {
            copy ? 
            <FormattedMessage id='copied' defaultMessage='Copied!' />
            :
            <FormattedMessage id='share' defaultMessage="Share" />
        }
    </button>
  )
}

export default Share