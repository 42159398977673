import React, { useContext } from 'react'
import * as yup from "yup";
import { Formik, Form } from "formik";
import { error_alert, stringNotEmpty, hidePopup, removeToken, success_alert } from '../../utils';
import TextField from "../../components/FormikFields/TextField";
import {BiLoaderAlt} from "react-icons/bi";
import { Axios } from '../../api';
import { BasePopupContext } from '../BasePopup';
import { useDispatch } from 'react-redux';
import { reset_profile } from '../../redux/slices/profileSlice';
import { logout } from '../../redux/slices/userSlice';
import { FormattedMessage, useIntl } from 'react-intl';
import { network_tl } from '../../translation';

const OtpForm = ({setScreen}) => {
    const dispatch = useDispatch()
    const {setPopup} = useContext(BasePopupContext)
    const intl=useIntl()
    const initialValues = {
        code: "",
    }
    yup.addMethod(yup.string, 'stringNotEmpty', stringNotEmpty)
    const validationSchema = yup.object({
        code: yup.string().stringNotEmpty(),
    })

    const handleSubmit = async (values, formikBag) => {
        const payload = {
            otp:values.code
        }
        try{
            const result = await Axios.post("api/user/delete", payload, {requestId: "otp-reset-password"});
            removeToken();
            dispatch(reset_profile())
            dispatch(logout(false))
            success_alert(intl.formatMessage({id:"account_deleted", defaultMessage:"Account deleted successfully!"}))
            hidePopup(setPopup)
        } catch(e){
            if(e.response){
                error_alert(JSON.stringify(e.response.data.description))
            } else{
                error_alert(network_tl(intl))
            }
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {
                form => (
                    <Form className=''>
                        <>

                            <div className="grid grid-cols-1 gap-4">
                                <TextField
                                    field="code"
                                    label_text={<><FormattedMessage id='otp_code' defaultMessage="OTP Code"/>*</>}
                                    form={form}
                                />
                                <div className="flex items-center justify-center space-x-5 mt-5">
                                    <button type="button" onClick={() => hidePopup(setPopup)} disabled={form.isSubmitting} className='btn btn-primary'>
                                        {
                                            form.isSubmitting ? 
                                            <BiLoaderAlt className="animate-spin text-3xl mx-auto"/>
                                            : <FormattedMessage id='cancel' defaultMessage="Cancel"/>
                                        }
                                    </button>
                                    <button type="submit" disabled={form.isSubmitting} className='btn btn-danger'>
                                        {
                                            form.isSubmitting ? 
                                            <BiLoaderAlt className="animate-spin text-3xl mx-auto"/>
                                            : 
                                            <FormattedMessage id='delete_account' defaultMessage="Delete Account"/>
                                        }
                                    </button>
                                </div>

                                {/* <hr className='mt-5'/>
                                <p className="text-sm text-center mt-2">
                                    Have trouble logging in? Reset your password <Link className='text-primary underline font-medium' to="/forgot-password">here</Link>.
                                </p> */}
                            </div>
                        </>
                    </Form>
                )
            }
        </Formik>
    )
}

export default OtpForm