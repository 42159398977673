import React, { useContext, useState } from 'react'
import Item from './Item'
import { useIntl } from 'react-intl'
import {BasePopupContext} from "../../components/BasePopup";
import {hidePopup} from "../../utils";
import { Link } from 'react-router-dom';
import QrShareRename from '../QrShareRename';
import { useDispatch } from 'react-redux';
import { set_edit } from '../../redux/slices/profileSlice';
import { set_add_mode } from '../../redux/slices/addLinksSlice';

const PremiumFeatures = () => {
    const intl = useIntl();
    const {setPopup} = useContext(BasePopupContext)
    const [showQr, setShowQr] = useState(false);
    const dispatch = useDispatch();


    const options = [
        {
            subtitle: intl.formatMessage({
                id:"pro_feataure",
                defaultMessage: "Pro Feature"
            }),
            title: intl.formatMessage({
                id:"create_up_3",
                defaultMessage: "Create up to 3 Digital Cards"
            }),
            text: intl.formatMessage({
                id:"create_up_3_des",
                defaultMessage: "Create unique digital business cards for any occasion and instantly switch between them at any time"
            }),
            image: "/images/premium-features/create-card.png",
            btn: (
                <Link
                to="/profiles" 
                    onClick={() => {
                        hidePopup(setPopup)
                    }}
                    className='py-4 px-6 rounded-lg bg-primary font-semibold text-xs text-white'
                >
                        Create Card
                </Link>
            )
        },
        {
            subtitle: intl.formatMessage({
                id:"pro_feataure",
                defaultMessage: "Pro Feature"
            }),
            title: intl.formatMessage({
                id:"create_up_3",
                defaultMessage: "Create up to 3 Digital Cards"
            }),
            text: intl.formatMessage({
                id:"crm_feature_des",
                defaultMessage: "Lead export, contact syncing, marketing flows, live chat, Zapier integrations and more Setup Integration"
            }),
            image: "/images/premium-features/export-crm.png",
            btn: (
                <Link
                    to="/manage-crm" 
                    onClick={() => {
                        hidePopup(setPopup)
                    }}
                    className='py-4 px-6 rounded-lg bg-primary font-semibold text-xs text-white'
                >
                        Setup Integration
                </Link>
            )
        },
        {
            subtitle: intl.formatMessage({
                id:"pro_feataure",
                defaultMessage: "Pro Feature"
            }),
            title: intl.formatMessage({
                id:"create_custom_qr",
                defaultMessage: "Create Custom QR Codes"
            }),
            text: intl.formatMessage({
                id:"create_custom_qr_des",
                defaultMessage: "Share your Pro-ID digital business card with a printable QR code designed by you"
            }),
            image: "/images/premium-features/custom-qr.png",
            btn: (
                <button 
                    onClick={() => {
                        setShowQr(true)
                    }}
                    className='py-4 px-6 rounded-lg bg-primary font-semibold text-xs text-white'
                >
                        Customize Now
                </button>
            )
        },
        {
            subtitle: intl.formatMessage({
                id:"pro_feataure",
                defaultMessage: "Pro Feature"
            }),
            title: intl.formatMessage({
                id:"capture_leads_and",
                defaultMessage: "Capture Leads and Build your Network"
            }),
            text: intl.formatMessage({
                id:"capture_leads_and_des",
                defaultMessage: "Capture leads using Pro-ID and favorite, group, sort and manage them all from here. Start building your connections now"
            }),
            image: "/images/premium-features/build-your-profile.png",
            btn: (
                <Link 
                    to="/connections"
                    onClick={() => {
                        hidePopup(setPopup)
                    }}
                    className='py-4 px-6 rounded-lg bg-primary font-semibold text-xs text-white'
                >
                        Start Building
                </Link>
            )
        },
        {
            subtitle: intl.formatMessage({
                id:"pro_feataure",
                defaultMessage: "Pro Feature"
            }),
            title: intl.formatMessage({
                id:"access_lifetime_ana",
                defaultMessage: "Access Lifetime Analytics"
            }),
            text: intl.formatMessage({
                id:"access_lifetime_ana_des",
                defaultMessage: "Access lifetime analytics so you can track your business growth and networking improvements since you first started"
            }),
            image: "/images/premium-features/insights.png",
            btn: (
                <Link 
                    to="/statistics"
                    onClick={() => {
                        hidePopup(setPopup)
                    }}
                    className='py-4 px-6 rounded-lg bg-primary font-semibold text-xs text-white'
                >
                        See Insights
                </Link>
            )
        },
        {
            subtitle: intl.formatMessage({
                id:"pro_feataure",
                defaultMessage: "Pro Feature"
            }),
            title: intl.formatMessage({
                id:"profile_themes",
                defaultMessage: "Profile Themes"
            }),
            text: intl.formatMessage({
                id:"profile_themes_des",
                defaultMessage: "Truly make your profile yours, with custom themes + styles"
            }),
            image: "/images/premium-features/profile-theme.png",
            btn: (
                <Link 
                    to="/"
                    onClick={() => {
                        hidePopup(setPopup)
                    }}
                    className='py-4 px-6 rounded-lg bg-primary font-semibold text-xs text-white'
                >
                        Customize Now
                </Link>
            )
        },
        {
            subtitle: intl.formatMessage({
                id:"pro_feataure",
                defaultMessage: "Pro Feature"
            }),
            title: intl.formatMessage({
                id:"unlimited_pro_links",
                defaultMessage: "Unlimited Pro Links"
            }),
            text: intl.formatMessage({
                id:"unlimited_pro_links_des",
                defaultMessage: "Add unlimited professional links that accelerate the growth of your business and networking"
            }),
            image: "/images/premium-features/icon.png",
            btn: (
                <Link 
                    to="/"
                    onClick={() => {
                        hidePopup(setPopup)
                        dispatch(set_edit(true))
                        dispatch(set_add_mode(true))
                    }}
                    className='py-4 px-6 rounded-lg bg-primary font-semibold text-xs text-white'
                >
                        Add Pro Links
                </Link>
            )
        },
        {
            subtitle: intl.formatMessage({
                id:"pro_feataure",
                defaultMessage: "Pro Feature"
            }),
            title: intl.formatMessage({
                id:"custom_icons_title",
                defaultMessage: "Custom Icons + Titles"
            }),
            text: intl.formatMessage({
                id:"custom_icons_title_des",
                defaultMessage: "Set custom icons and titles for each of your links! Setting better icons and titles can be great for engagement and tap through rate"
            }),
            image: "/images/premium-features/custom-icon.png",
            btn: (
                <Link 
                    to="/"
                    onClick={() => {
                        hidePopup(setPopup)
                        dispatch(set_edit(true))
                        dispatch(set_add_mode(true))
                    }}
                    className='py-4 px-6 rounded-lg bg-primary font-semibold text-xs text-white'
                >
                        Customize Now
                </Link>
            )
        },
    ]
  return (
    showQr ?
        <QrShareRename />
    :
    <>
    <div>
    {/* TOP */}
    <div className='mb-12'>
        <h2 className='mb-2 text-xl font-bold text-primary'>
            Your Pro Features
        </h2>
        <p className='mb-3 font-medium text-primary text-sm'>
            Scroll down to see some of the incredible
            features you get with Pro-ID Pro
        </p>
    </div>

    {/* TOP */}
    <div className='space-y-24'>
        {
            options.map((item, index) => (
                <Item key={index} {...item}/>
            ))
        }
    </div>
    
</div>
    </>
  )
}

export default PremiumFeatures