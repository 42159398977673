import { store } from "react-notifications-component";
import * as yup from "yup";
import { FormattedMessage } from "react-intl";
import { ACCESS_TOKEN, HISTORY_TOKEN, initialPopupState } from "./constants";


export const getAuthUser = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        return JSON.parse(token).user;
    } else {
        return false;
    }
}

export const getToken = () => {
    let token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        return JSON.parse(token).access_token;
    } else {
        return false;
    }
}

export const setToken = (access_token) => {
    localStorage.setItem(ACCESS_TOKEN, JSON.stringify(access_token));
}

export const removeToken = () => {
    localStorage.removeItem(ACCESS_TOKEN);
}

export const success_alert = (message, title) => {
    let new_msg = "";
    if ((message[0] === '"' || message[0] === "'") && (message[message.length - 1] === '"' || message[message.length - 1] === "'")) {
        new_msg = message.substr(1, message.length - 2);
    } else {
        new_msg = message
    }
    store.addNotification({
        title: title ? title : "",
        message: new_msg,
        className: "noti",
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeInUp"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const error_alert = (message, title) => {
    let new_msg = "";
    if ((message[0] === '"' || message[0] === "'") && (message[message.length - 1] === '"' || message[message.length - 1] === "'")) {
        new_msg = message.substr(1, message.length - 2);
    } else {
        new_msg = message
    }
    store.addNotification({
        title: title ? title : "",
        message: new_msg,
        className: "noti",
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__slideInRight"],
        animationOut: ["animate__animated", "animate__slideOutBottom"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const warning_alert = (message, title) => {
    store.addNotification({
        title: title ? title : "",
        message: message,
        type: "warning",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}


export function stringNotEmpty() {
    return yup.mixed().test({
        name: 'stringNotEmpty',
        exclusive: false,
        message: <FormattedMessage id="required" defaultMessage="Required" />,
        test: function (value) {
            if (value !== undefined && value !== false) {
                return value.trim() !== "";
            } else if (value === undefined) {
                return false
            }
        },
    });
}

export const selectStyles = error => (
    {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? "#fff" : "#fff",
            borderRadius: "0.375rem",
            border: state.isDisabled ? "1px solid #22c55e" : state.isFocused ? "1px solid #093953" :  state.isSelected ? "1px solid #093953" : state.hasValue ? "1px solid #22c55e": error ? "1px solid #22c55e": "1px solid #cbd5e1",
            // boxShadow: state.isFocused ? "0px 0px 6px #22c55e" : "none",
            outline: "0",
            "&:focus": {
                outline: "0",
            },
            "&:hover": {
                border: "1px solid #093953",
                outline: "0 !important",
            },
            fontSize: "13px",
            "@media (max-width:1024px)": {
                fontSize: "0.875rem"
            }
            
        }),
        option: (provided, state) => {
            return ({
                ...provided,
                background: state.isSelected ? "#093953" : "white",
                color: state.isSelected ? "#fff" : "#000",
                "&:hover": {
                    "background": "#093953",
                    color: "#fff"
                },
                fontSize: "1rem",
                "@media (max-width:1024px)": {
                    fontSize: "0.875rem"
                }
            })
        },
        
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
    
            return { ...provided, opacity, transition };
        }
    }
)
export const selectStylesFilter = error => (
    {
        
        control: (provided, state) => ({
            ...provided,
            height: "12px",
            fontSize: "12px",
            backgroundColor: state.isDisabled ? "#fff" : "#fff",
            borderRadius: "0.375rem",
            border: state.isDisabled ? "2px solid #e4e4e4" : state.isFocused ? "2px solid #40e0d0" :  state.isSelected ? "2px solid #ef4444" : state.hasValue ? "2px solid #40e0d0": error ? "2px solid #ef4444": "2px solid #cbd5e1",
            boxShadow: state.isFocused ? "0px 0px 6px #40e0d0" : "none",
            "&:hover": {
                border: "2px solid #40e0d0",
                boxShadow: "0px 0px 6px #40e0d0"
            },
            
        }),
        
        option: (provided, state) => {
            return ({
                ...provided,
                background: state.isSelected ? "#40e0d0" : "white",
                color: state.isSelected ? "#000" : "#500",
                fontSize: "12px",
                "&:hover": {
                    "background": "#40e0d0",
                    color: "#500"
                },
            })
        },
        
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';
    
            return { ...provided, opacity, transition, fontSize: "12px" };
        }
    }
)


export const hidePopup = (setState) => {
    setState(initialPopupState)
}

export const phoneRegExp = /^((\\+[0-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// export const isUrl = (string) => {
//     let url;
    
//     try {
//       url = new URL(string);
//     } catch (_) {
//       return false;  
//     }
  
//     return url.protocol === "http:" || url.protocol === "https:";
//   }
export const isUrl = (string) => {
    // if(string){
    //     return string.includes("/")
    // } else{
    //     return false
    // }
    // old regex below
    // const urlPattern = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?(?:[\w-]+\.)+[a-z]{2,}(?::\d+)?(?:\/[\w#!:.?+=&%@!-]*)?$/i;
    // return urlPattern.test(string);
    // old regex above
    const urlPattern = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\/[\w#!:.?+=&%@!\-]*)*$/;
    return urlPattern.test(string);
  

  }

export const generateProfileQrLink = (value) => {
    return document.location.origin+"/profile/" + value
}

export const normalizeUrl = (str) => {
    if (str.startsWith("https://")) {
      return str;
    } else {
      return "https://" + str;
    }
  }
  

export const getHistory = () => {
    let token = localStorage.getItem(HISTORY_TOKEN);
    if (token) {
        return JSON.parse(token);
    } else {
        return [];
    }
}

export const setHistory = (arr) => {
    localStorage.setItem(HISTORY_TOKEN, JSON.stringify(arr));
}
export const removeHistory = () => {
    localStorage.removeItem(HISTORY_TOKEN);
}


export const extractInformation=(text)=> {
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
    const nameRegex = /[A-Z][a-z]*(?: [A-Z][a-z]*){1,2}/g;
    const addressRegex = /\d+ .*?(?=(?:\d{5}(?:-\d{4})?$))/g;
  
    const emails = text.match(emailRegex)?text.match(emailRegex):"";
    const names = text.match(nameRegex)?text.match(nameRegex):"";
    const addresses = text.match(addressRegex) ?text.match(addressRegex):"" ;
    
    return { emails, names, addresses };
}


export const extractEmails=(text)=>{
    const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b/g;
    const matches = text.match(emailRegex);

    return matches
}


export const extractPhonNumber = (text) => {
    // const pattern = /\b\d{3}-\d{3}-\d{4}\b/g;
    // const pattern = /\b\(?(\d{3})\)?[- ]?\d{3}[- ]?\d{4}\b/g;

    // const matches = text.match(pattern);
    // console.log("phone",matches)

    const phoneRegex = /\b\d{3}[-.]?\d{3}[-.]?\d{4}\b/g;
    // const phoneRegex = /\b(?:\+\d{1,3}\s?)?(?:\(\d{1,3}\)\s?)?\d{3}[-.]?\d{3}[-.]?\d{4}\b/g;
  
    console.log("text",text)
    const matches = text.match(phoneRegex);
    console.log("matches",matches)
    return matches ? matches : [];


}



export const getBlocksByPage=(text)=>{
    let blocks=[];
    let paraghraph = []
    let words = [];
    let symbols = [];
    
    paraghraph= text.blocks.map((b)=>{
        return b.paragraphs
    })

    words = paraghraph.map((p)=>{
       return  p.map((w)=>{
            return w.words
        })
    })

    console.log("paraghraph",paraghraph)
    console.log("words",words)
}


export const b64toBlob=(dataURI)=> {
    
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
}


export const LanguageForDropIn =(language)=> { //this is for payment text language.

    if(language.locale === "it"){
        return "it_IT"

    }else if(language.locale === "it"){
        return "ar_EG"
    }else{
        return "en_US"
    }
}


export const LANGUAGE_TEXTS = {
    // chooseAWayToPay: intl.formatMessage({id:"pick_a_payment_route", defaultMessage:"Pick a payment route" }),
    // chooseAnotherWayToPay : intl.formatMessage({id:"other_ways_to_pay", defaultMessage:"Choose an alternative method of payment"}),
    // payWithCard: intl.formatMessage({id:"other_ways_to_pay", defaultMessage:"Make a card payment"}),

    // edit: intl.formatMessage({id:"other_ways_to_pay", defaultMessage:"Edit"}),
    // doneEditing: intl.formatMessage({id:"other_ways_to_pay", defaultMessage:"Done"}),
    // editPaymentMethods: intl.formatMessage({id:"other_ways_to_pay", defaultMessage:'Edit payment methods'}),
    // genericDeleteConfirmationMessage: intl.formatMessage({id:"other_ways_to_pay", defaultMessage:'Are you sure you want to delete this payment method?'}) ,
    // deleteCancelButton: intl.formatMessage({id:"cancel", defaultMessage:'Cancel'}) ,
    // deleteConfirmationButton: intl.formatMessage({id:"delete", defaultMessage:'Delete'}) ,
    //errors
    // fieldEmptyForCvv:  intl.formatMessage({id:"delete", defaultMessage:'Please fill out a CVV.'}) ,
    // fieldEmptyForExpirationDate: intl.formatMessage({id:"delete", defaultMessage:'Please fill out an expiration date.'})  ,
    // fieldEmptyForCardholderName: intl.formatMessage({id:"delete", defaultMessage:'Please fill out a cardholder name.'}) ,
    // fieldEmptyForNumber: intl.formatMessage({id:"delete", defaultMessage:'Please fill out a card number.'}),
    // fieldEmptyForPostalCode: intl.formatMessage({id:"delete", defaultMessage:'Please fill out a postal code.'}),
    // fieldInvalidForCardholderName: intl.formatMessage({id:"delete", defaultMessage:'This cardholder name is not valid.'}),
    // fieldInvalidForCvv: intl.formatMessage({id:"delete", defaultMessage:'This security code is not valid.'}),
    // fieldInvalidForExpirationDate: intl.formatMessage({id:"delete", defaultMessage:'This expiration date is not valid.'}),
    // fieldInvalidForNumber: intl.formatMessage({id:"delete", defaultMessage:'This card number is not valid.'}),
    // fieldInvalidForPostalCode: intl.formatMessage({id:"delete", defaultMessage:'This postal code is not valid.'}),
    // fieldTooLongForCardholderName: intl.formatMessage({id:"delete", defaultMessage:'Cardholder name must be less than 256 characters.'}),
    // noticeOfCollection : intl.formatMessage({id:"delete", defaultMessage:'By paying with my card, I agree to the PayPal Privacy Statement.'}),
    // genericError: intl.formatMessage({id:"delete", defaultMessage:'Something went wrong on our end.'}),
    // hostedFieldsTokenizationFailOnDuplicateError: intl.formatMessage({id:"delete", defaultMessage:'This credit card already exists as a saved payment method.'}),
    // hostedFieldsFailedTokenizationError: intl.formatMessage({id:"delete", defaultMessage:'Please check your information and try again.'}),
    // hostedFieldsTokenizationCvvVerificationFailedError: 'Credit card verification failed. Please check your information and try again.',
    // hostedFieldsTokenizationNetworkErrorError: intl.formatMessage({id:"delete", defaultMessage:'Network error. Please try again.'}) ,
    // hostedFieldsFieldsInvalidError: intl.formatMessage({id:"delete", defaultMessage:'Please check your information and try again.'}),
    // paypalButtonMustBeUsed: intl.formatMessage({id:"delete", defaultMessage:'Use the PayPal button to continue with your payment.'}),
    // paypalAccountTokenizationFailedError: intl.formatMessage({id:"delete", defaultMessage:'Something went wrong adding the PayPal account. Please try again.'}),
    // paypalFlowFailedError: intl.formatMessage({id:"delete", defaultMessage:'Something went wrong connecting to PayPal. Please try again.'}) ,
    // paypalTokenizationRequestActiveError: intl.formatMessage({id:"delete", defaultMessage:'PayPal payment authorization is already in progress.'}) ,
    // applePayTokenizationError: intl.formatMessage({id:"delete", defaultMessage:'A network error occurred while processing the Apple Pay payment. Please try again.'}),
    // applePayActiveCardError: intl.formatMessage({id:"delete", defaultMessage:'Add a supported card to your Apple Pay wallet.'}) ,
    // vaultManagerPaymentMethodDeletionError: intl.formatMessage({id:"delete", defaultMessage:'Unable to delete payment method, try again.'}),
    // unsupportedCardTypeError: intl.formatMessage({id:"delete", defaultMessage:'This card type is not supported. Please try another card.'}),
}

export const TOUR_STEPS =[
    {
      selector: '.tour_first_step',
      content: 'Click here to modify or enhance your profile information. Keep your details fresh and relevant',
      position: 'left',
      stepInteraction:false
    },
    {
      selector: '.tour_second_step',
      content: `Tell your connections more about yourself. Let others know your passions and experiences, or anything you would like to share`,
      position: 'bottom',
      stepInteraction:false
      
    },
    {
        selector: '.tour_third_step',
        content: ' Click here to showcase multiple ways to reach you. Expand your network and make connecting easier across various platforms.',
        position: 'bottom',
        stepInteraction:false
        
    },
    {
        selector: '.tour_four_step',
        content: `Customize Your Link! Click here to add a URL of your choice. Whether it's a portfolio, social profile, or any link you want to share, make it uniquely yours`,
        position: 'top',
        stepInteraction:false
        
    },
    {
        selector: '.tour_five_step',
        content: `Click here to add your contact number and other personal details. Make it easier for people to reach out to you and stay connected`,
        position: 'bottom',
        stepInteraction:false
        
    },
    {
      selector: '.tour_last_step',
      content: `Don't forget to save! Click here to keep your updates. Ensure all your new information is preserved and ready for your connections to see`,
      position: 'left',
      stepInteraction:false
      
    },
]

export const TOUR_STYLES={
    popover: (base) => ({
      ...base,
      '--reactour-accent': '#093953',
      borderRadius: 10,
    }),
    maskArea: (base) => ({ ...base, rx: 10 }),
    maskWrapper: (base) => ({ ...base, 
        color: '#4F7284',
 }),
    badge: (base) => ({ ...base, left: 'auto', right: '-0.8125em' }),
}


export const isValidURL=(str)=>{
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}
export const dataUrlToFile = async (dataUrl, filename)=>{
        var arr = dataUrl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}


export const hexToRgba=(hex,opacity)=>{
    // Convert each hex character pair into an integer
    let red = parseInt(hex.substring(1, 3), 16);
    let green = parseInt(hex.substring(3, 5), 16);
    let blue = parseInt(hex.substring(5, 7), 16);
    
    // Concatenate these codes into proper RGBA format
    let rgba  = ` rgba(${red}, ${green}, ${blue}, ${opacity})`
    return rgba
}

export const randomString=()=>{
    let length=5;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}