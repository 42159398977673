import React from 'react'

const NoData = ({text}) => {
  return (
    <div className='mt-5 px-5 pb-8'>
        <img src="/images/not_found.png" className='w-9-/12 mx-auto' alt="" />
        <p className="text-base text-center font-medium">
            {text}
        </p>
    </div>
  )
}

export default NoData