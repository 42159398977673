import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sidebar: false,
    new_card_redirection: false 
}

const uiSlice = createSlice({
    initialState,
    name: "ui",
    reducers: {
        set_sidebar: (state, action) => {
            state.sidebar = action.payload;
            return state
        },
        set_new_card_redirection: (state, action) => {
            state.new_card_redirection = action.payload;
            return state
        },
    }
})

export default uiSlice.reducer;

export const {
    set_sidebar,
    set_new_card_redirection
} = uiSlice.actions;
