import React from 'react'
import { FaSearch } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { set_connections_filters, set_groups_filters } from '../../../redux/slices/connectionsSlice';
import { MEMBERS } from '../../../constants';

const Search = () => {
  const dispatch = useDispatch();
  const search_connection = useSelector(state => state.connections.connections.search);
  const search_group = useSelector(state => state.connections.groups.search);
  const currentTab = useSelector(state => state.connections.currentTab);

  const handleChange = (e) => {
    if(currentTab === MEMBERS){
      dispatch(set_connections_filters({
        search: e.target.value
      }))
    } else{
      dispatch(set_groups_filters({
        search: e.target.value
      }))
    }
  }

  return (
    <div className='flex-grow mx-3 relative'>
        <input value={currentTab === MEMBERS ? search_connection : search_group} onChange={handleChange} placeholder="Search" id="connection-search" type="text" className='w-full border-2 rounded-lg py-[7px] pl-9 pr-4 bg-white border-gray-300 text-sm' />
        <label htmlFor='connection-search' className='focus:border-primary focus:outline-none text-base text-gray-300 absolute left-4 top-1/2 -translate-y-1/2'>
            <FaSearch className=''/>
        </label>
    </div>
  )
}

export default Search