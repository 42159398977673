import React, { useRef, useEffect, useState } from 'react';
import QRCodeStyling from "qr-code-styling";
import { useSelector } from 'react-redux';

const QrGenerator = ({ value }) => {
    const branding_detail = useSelector(state => state.branding_details)
    
    const options = {
        width: 600,
        height: 600,
        image: `${branding_detail&&branding_detail.brand_image? branding_detail.brand_image:"/favicon1.png"}`,
        imageOptions: {
            imageSize: 0.4
        },
        dotsOptions: {
            color: `${branding_detail&&branding_detail.primary_color? branding_detail.primary_color:"#093953"}`,
            type: "rounded"
        },
        cornersSquareOptions: {
            color: `${branding_detail&&branding_detail.primary_color? branding_detail.primary_color:"#093953"}`,
            type: "extra-rounded"
        },
        cornersDotOptionsHelper: {
            color: `${branding_detail&&branding_detail.primary_color? branding_detail.primary_color:"#093953"}`,
            type: "extra-rounded"
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 10,
        }
    }
    const qrCode = new QRCodeStyling(options);

    const ref = useRef(null);

    // useEffect(() => {
    //     qrCode.append(ref.current);
    // }, []);

    useEffect(() => {
        qrCode.append(ref.current);
        qrCode.update({
            data: value
        });
    }, [value]);

    return (
        <>
            <div id="saram-test-qr" ref={ref} className="qr-container w-8/12 mx-auto" />
        </>
    )
}

export default QrGenerator