import React from 'react'
import { BasePopupContext } from '../../BasePopup'
import PremiumFeatures from '../../PremiumFeatures';
import { useContext } from 'react';
import { PRO_PLAN } from '../../../constants';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';


const PremiumIcon = ({smaller}) => {
    const profile = useSelector(state => state.profile.data)
    const {setPopup} = useContext(BasePopupContext)
    
    const handlProPlan=()=>{
        //popup
        const popup = {
            fullPage: true,
            hideDefaultClose: true,
            component: <PremiumFeatures/>,
            visible: true,
            heading: <><FormattedMessage id='pro_plan' defaultMessage='Pro Plan' />😎</>,
            contained: true
        }
        setPopup(popup)
    }

    return (
        <>
            {
                !smaller ?
                    (profile.subscription && profile.subscription.type === PRO_PLAN) &&
                    <img src='images/premium_icon.png' className=' flex-shrink-0 w-[28px] h-[28px] cursor-pointer' onClick={() => handlProPlan()} />
                    :
                    null
            }
        </>
    )
}

export default PremiumIcon